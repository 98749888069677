import React from 'react';
import { getIntakeqForm } from 'api/provider';
import { IntakeqForm } from 'types/common';
import { getAdminIntakeqForm } from 'api/operation';

interface IProps {
    isOps?: boolean;
}

let globalData:IntakeqForm[] = [];

const useIntakeqForm = ({
    isOps,
}: IProps):[IntakeqForm[], boolean] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<IntakeqForm[]>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            let result;
            if (isOps) {
                result = await getAdminIntakeqForm();
            } else {
                result = await getIntakeqForm();
            }
            if (!result?.error) {
                const data:IntakeqForm[] = result?.data?.data || [];
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || Object.keys(items).length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [items, loading];
};

export default useIntakeqForm;
