import React from 'react';
import { getSpecialityTemplateRecommend } from 'api/klarityService';
import { getOpsSpecialityTemplateRecommend } from 'api/operation';
import { KlarityService } from 'types/common';

type TData = {
    recommend: KlarityService[];
    other: KlarityService[];
};

let globalData:TData | undefined;

const useKlarityServiceTemplate = (isOps?:boolean, providerId?: number):[TData | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TData | undefined>(globalData);
    const fetchData = async () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            let result:any;
            if (isOps && providerId) {
                result = await getOpsSpecialityTemplateRecommend(providerId);
            } else {
                result = await getSpecialityTemplateRecommend();
            }
            if (!result.error) {
                const allData:KlarityService[] = result?.data?.data || [];
                allData?.forEach((item) => {
                    const allSupportServiceTypeIds:number[] = [];
                    item?.serversTypes?.forEach((i) => {
                        if (!allSupportServiceTypeIds.includes(i.serviceTypeId)) {
                            allSupportServiceTypeIds.push(i.serviceTypeId);
                        }
                    });
                    item.serversTypeNames = item.serversTypeNames?.filter((i) => {
                        return allSupportServiceTypeIds.includes(i.id);
                    });
                });

                const recommend = allData.filter((item) => !!item.recommended);
                const other = allData.filter((item) => !item.recommended);
                const data:TData = {
                    recommend,
                    other,
                };
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items) {
            fetchData();
        }
    }, []);

    return [items, loading, fetchData];
};

export default useKlarityServiceTemplate;
