import { useCallback, useEffect, useState } from 'react';
import { getSurveyForm } from 'api/survey';
import { ISurveyForm } from 'types/survey';

const useGetSurveyForm = ():[ISurveyForm[] | undefined, boolean | null, string, () => void] => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [data, setData] = useState<ISurveyForm[]>();
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getSurveyForm();

            if (res && !res.error) {
                const surveyFormData = res?.data?.data?.elements;

                setData(surveyFormData);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetSurveyForm;
