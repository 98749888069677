import { useCallback, useEffect, useState } from 'react';
import { EContactType, KlarityService } from 'types/common';
import { getKlaritySpecialtyList } from 'api/operation';
import { IKlaritySpecialtyForm } from 'types/serviceTemplate';

const transData = (data: KlarityService[]) => {
    return data.map((item) => {
        const { serversTypes, commonCondition } = item;

        return {
            ...item,
            commonCondition: commonCondition?.split(','),
            serversTypes: serversTypes ? serversTypes.map((type) => {
                const { teleHealth, inPerson } = type;
                // 处理contactType
                const contactType = [];
                if (teleHealth) {
                    contactType.push(EContactType.TELE_HEALTH);
                }
                if (inPerson) {
                    contactType.push(EContactType.IN_PERSON);
                }
                return ({
                    ...type,
                    patientGroupIds: type.patientGroupIds?.split(','),
                    limitationIds: type.limitationIds?.split(','),
                    contactType,
                });
            }) : [],
        };
    });
};

const useGetSpecialtyList = (): [IKlaritySpecialtyForm[], boolean, string | undefined, () => void] => {
    const [data, setData] = useState<IKlaritySpecialtyForm[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>('');

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getKlaritySpecialtyList();

            if (res && !res.error) {
                setData(transData(res.data.data));
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetSpecialtyList;
