import React from 'react';
import { Button, Steps } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { EStep } from '../../types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { HELP_LINK } from 'constants/common';

type Props = {
    step?: EStep
};

const Header = (props: Props) => {
    const { step = EStep.PROFILE } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();
    return (
        <div className={s.header}>
            <div className={s.headerInner}>
                <div className={s.title}>
                    <div className={s.left}>
                        <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" className={s.logo} />
                    </div>
                    <div className={s.right}>
                        <Button
                            onClick={() => {
                                window.open(HELP_LINK, '_blank');
                            }}
                            icon={<MailOutlined />}
                            className={`${s.btn} ${commonS.lightBtn}`}
                        >
                            Need help
                        </Button>
                    </div>
                </div>
                <div className={s.stepWrap}>
                    <Steps
                        current={step}
                        items={[
                            {
                                title: 'Profile',
                                description: 'Let us know more about you',
                            },
                            {
                                title: 'Qualification',
                                description: 'Input your practice license information, your certificates and collaborating physician',
                            },
                            {
                                title: 'Review',
                                description: 'Check all information before you submit',
                            },
                            {
                                title: 'Finish',
                                description: 'Let\'s move to the next step',
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
