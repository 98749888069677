import React from 'react';
import { getProviderEmailNotify } from 'api/provider';

let loading = false;

const useProviderNotifyEmails = ():[string[] | undefined, boolean, ()=>void] => {
    const [item, setItem] = React.useState<string[]>();
    const fetchData = async () => {
        try {
            loading = true;
            const result = await getProviderEmailNotify();
            if (!result.error) {
                const { data } = result.data;
                setItem(data ? data.split(',') : []);
            }
            loading = false;
        } catch (e) {
            console.error(e);
            loading = false;
        }
    };
    React.useEffect(() => {
        if (!loading) {
            fetchData();
        }
    }, []);

    return [item, loading, fetchData];
};

export default useProviderNotifyEmails;
