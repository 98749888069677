import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Form, Button, message } from 'antd';
import ProviderStore from 'store/Provider';
import { QualificationFormFieldEnum, FormMutipleQualificationFormFieldEnumInterface } from 'types/provider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import StateSelect from 'components/form/StateSelect';
import DeaLicense from 'components/onboarding/DeaLicense';
import CollaboratingPhysician from 'components/onboarding/CollaboratingPhysician';
import ControlledSubstanceCertification from 'components/onboarding/ControlledSubstanceCertification';
import ContinuingMedicalEducationCertificates from 'components/onboarding/ContinuingMedicalEducationCertificates';
import NurseAndPhysiciansAssistantLicense from 'components/onboarding/NurseAndPhysiciansAssistantLicense';

type Props = {
    form: FormInstance<FormMutipleQualificationFormFieldEnumInterface>
};

const Qualification = (props: Props) => {
    const { form } = props;
    const [getUser] = ProviderStore.useStore();
    const [selectedStates, setSelectedStates] = React.useState <Record<number, string>>({});

    const user = getUser('data');
    const uid = user?.id;
    const genId = (field:number) => `qualification_${field}`;
    const onNullStateHandle = (field:number) => {
        message.error('Please select state first');
        const id = genId(field);
        const target = document.getElementById(id);
        window.scroll({
            top: target ? target.offsetTop : 300,
            behavior: 'smooth',
        });
    };
    React.useEffect(() => {
        const value = form.getFieldsValue();
        if (value?.qualifications) {
            form.setFieldValue('qualifications', value.qualifications.filter(Boolean));
        }
    }, []);
    return (
        <div className={s.wrap}>
            <h2>Kiwi Health is built for healthcare providers. We&apos;d like to know your practice details to understand how to tailor
                the software for your needs.
            </h2>
            <h2>Please upload your license for your practice.</h2>
            <p className={s.subTitle}>Since the requirements of each state are different, please set the state of practice first.</p>
            <div className={s.contentInner}>
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.List name="qualifications" initialValue={[]}>
                        {(fields, { add, remove }) => {
                            const { length } = fields;

                            return (
                                <div>
                                    {fields.map((field) => {
                                        const state = selectedStates[field.name] ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.DEA_LICENSES, 'licenses', '0', 'state']) ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION, 'licenses', '0', 'state']) ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES, 'licenses', '0', 'state']) ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.COLLABORATING_PHYSICIAN, 'state']) ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES, 'nurse', 'state']) ||
                                        form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES, 'pa', 'state']);
                                        return (
                                            <Form.Item
                                                noStyle
                                                key={field.key}
                                            >
                                                <div className={s.modalWrap} id={genId(field.name)}>
                                                    <div className={s.row}>
                                                        <div className={s.rowItem}>
                                                            <Form.Item
                                                                style={{ width: '300px' }}
                                                                label="What is your practice state"
                                                                name={[field.name, QualificationFormFieldEnum.STATE]}
                                                                rules={[{ required: true, message: 'State is required' }]}
                                                            >
                                                                <StateSelect
                                                                    disableValues={Object.values(selectedStates)}
                                                                    onChange={(val:string) => {
                                                                        setSelectedStates({
                                                                            ...selectedStates,
                                                                            [field.name]: val,
                                                                        });
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                    <div className={s.module}>
                                                        <Form.Item
                                                            style={{ width: '100%', marginBottom: 0 }}
                                                            label=""
                                                            name={[field.name, QualificationFormFieldEnum.DEA_LICENSES]}
                                                            rules={[{ required: false, message: 'DEA license is required' }]}
                                                        >
                                                            <DeaLicense
                                                                state={state}
                                                                providerId={uid}
                                                                inited={!!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.DEA_LICENSES])}
                                                                initValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.DEA_LICENSES])}
                                                                onNullState={() => onNullStateHandle(field.name)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div className={s.module}>
                                                        <Form.Item
                                                            style={{ width: '100%' }}
                                                            label=""
                                                            name={[field.name, QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]}
                                                            rules={[{ required: false, message: 'Please select Collaborating Physician' }]}
                                                        >
                                                            <CollaboratingPhysician
                                                                providerId={uid}
                                                                state={state}
                                                                initValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.COLLABORATING_PHYSICIAN])}
                                                                editable={!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.COLLABORATING_PHYSICIAN])}
                                                                onNullState={() => onNullStateHandle(field.name)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className={s.module}>
                                                        <Form.Item
                                                            style={{ width: '100%' }}
                                                            label=""
                                                            name={[field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]}
                                                            rules={[{ required: false, message: 'You need either a NP or PA license' }]}
                                                        >
                                                            <NurseAndPhysiciansAssistantLicense
                                                                state={state}
                                                                providerId={uid}
                                                                paInitValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES])?.pa}
                                                                nurseInitValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES])?.nurse}
                                                                paInited={!!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES])?.pa}
                                                                nurseInited={!!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES])?.nurse}
                                                                onNullState={() => onNullStateHandle(field.name)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className={s.module} style={{ paddingBottom: 12 }}>
                                                        <Form.Item
                                                            style={{ width: '100%', marginBottom: 0 }}
                                                            label=""
                                                            name={[field.name, QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]}
                                                        >
                                                            <ControlledSubstanceCertification
                                                                state={state}
                                                                inited={!!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION])}
                                                                providerId={uid}
                                                                initValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION])}
                                                                onNullState={() => onNullStateHandle(field.name)}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className={s.module} style={{ paddingBottom: 12 }}>
                                                        <Form.Item
                                                            style={{ width: '100%', marginBottom: 0 }}
                                                            label=""
                                                            name={[field.name, QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]}
                                                        >
                                                            <ContinuingMedicalEducationCertificates
                                                                state={state}
                                                                initValue={form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES])}
                                                                inited={!!form.getFieldValue(['qualifications', field.name, QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES])}
                                                                providerId={uid}
                                                                onNullState={() => onNullStateHandle(field.name)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    {field.name !== 0 &&
                                                    <div className={s.removeWrap}>
                                                        <Button
                                                            onClick={() => {
                                                                remove(field.name);
                                                                const id = genId(field.name - 1);
                                                                const target = document.getElementById(id);
                                                                if (target) {
                                                                    setTimeout(() => {
                                                                        window.scroll({
                                                                            top: target.offsetTop,
                                                                            behavior: 'smooth',
                                                                        });
                                                                    }, 200);
                                                                }
                                                            }}
                                                            danger
                                                            size="small"
                                                        >Remove
                                                        </Button>
                                                    </div> }
                                                    {field.name === length - 1 &&
                                                    <div
                                                        onClick={() => {
                                                            add();
                                                            setTimeout(() => {
                                                                const id = genId(fields.length);
                                                                const target = document.getElementById(id);
                                                                window.scroll({
                                                                    top: target ? target.offsetTop + 200 : 300,
                                                                    behavior: 'smooth',
                                                                });
                                                            }, 200);
                                                        }}
                                                        className={s.add}
                                                    >
                                                        + Add another practice state
                                                    </div>
                                                    }
                                                </div>
                                            </Form.Item>
                                        );
                                    })}
                                </div>);
                        }}
                    </Form.List>

                </Form>
            </div>
        </div>
    );
};

export default Qualification;
