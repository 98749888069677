import { getRoleList } from 'api/access';
import { OPERATOR, OPS_ADMIN, Roles, SUPER_ADMIN } from 'constants/access';
import { useCallback, useEffect, useState } from 'react';
import { IRole } from 'types/access';
import { generateColor } from 'utils/common';

const useGetRoleList = (): [Array<IRole>, boolean, string, () => void] => {
    const [roleList, setRoleList] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const getNameAndColor = useCallback((roleName: string, index: number = 1) => {
        switch (roleName) {
            case Roles[SUPER_ADMIN]:
                return {
                    style: {
                        backgroundColor: '#FEE2E2',
                        color: '#991B1B',
                    },
                    text: SUPER_ADMIN,
                };
            case Roles[OPS_ADMIN]:
                return {
                    style: {
                        backgroundColor: '#FEF3C7',
                        color: '#92400E',
                    },
                    text: OPS_ADMIN,
                };
            case Roles[OPERATOR]:
                return {
                    style: {
                        backgroundColor: '#DBEAFE',
                        color: '#1E40AF',
                    },
                    text: OPERATOR,
                };
            default:
                return {
                    style: {
                        backgroundColor: generateColor('#DBEAFE', 4000 * index),
                        color: generateColor('#1E40AF'),
                    },
                    text: roleName,
                };
        }
    }, []);

    const handleGetRoleList = useCallback(async () => {
        setLoading(true);
        const res = await getRoleList();

        setLoading(false);

        if (!res?.error) {
            setRoleList(res.data?.data?.map((e: IRole, i: number) => {
                const { name } = e;
                const nameAndColor = getNameAndColor(name, i);

                return {
                    ...e,
                    name: nameAndColor.text,
                    style: nameAndColor.style,
                };
            }));
            setError('');
        } else {
            setError(res.error);
        }
    }, [getNameAndColor]);

    useEffect(() => {
        handleGetRoleList();
    }, [handleGetRoleList]);

    return [roleList, loading, error, handleGetRoleList];
};

export default useGetRoleList;
