// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__content--bOHTB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.s-module__content--bOHTB img {
  max-width: 90%;
}
.s-module__content--bOHTB .s-module__p1--hkiJ8 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}
.s-module__content--bOHTB .s-module__p2--LhNx3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}
.s-module__content--bOHTB .s-module__p3--SLgiX {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
}
.s-module__content--bOHTB .s-module__p4--xGmRD {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-color);
}
.s-module__content--bOHTB .s-module__p4--xGmRD span {
  margin: 0 2px;
}
.s-module__content--bOHTB .s-module__p4--xGmRD:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SavedDraftPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACF;AATA;EAUI,cAAA;AAEJ;AAZA;EAaI,eAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAjBA;EAkBI,eAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAtBA;EAuBI,eAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AA3BA;EA4BI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;AAEJ;AAlCA;EAkCM,aAAA;AAGN;AArCA;EAsCI,0BAAA;AAEJ","sourcesContent":[".content{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 100px);\n  max-width: 600px;\n  margin: 0 auto;\n  text-align: center;\n  img {\n    max-width: 90%;\n  }\n  .p1{\n    font-size: 20px;\n    font-weight: bold;\n    margin-bottom: 12px;\n  }\n  .p2{\n    font-size: 20px;\n    line-height: 24px;\n    margin-bottom: 8px;\n  }\n  .p3{\n    font-size: 14px;\n    line-height: 18px;\n    margin-bottom: 8px;\n  }\n  .p4{\n    font-size: 14px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    color: var(--primary-color);\n    span{\n      margin: 0 2px;\n    }\n  }\n  .p4:hover{\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `s-module__content--bOHTB`,
	"p1": `s-module__p1--hkiJ8`,
	"p2": `s-module__p2--LhNx3`,
	"p3": `s-module__p3--SLgiX`,
	"p4": `s-module__p4--xGmRD`
};
export default ___CSS_LOADER_EXPORT___;
