import TinyStore from 'lib/tinyStore';
import { Color } from 'types/calendar';

type Modal = {
    data: Color[];
    loading: boolean;
};

const Store = TinyStore.createStore<Modal>({
    nameSpace: 'klarity',
    key: 'color-hook',
    crossBundle: false,
    runTime: true,
    initValue: { data: [], loading: false },
});

export default Store;
