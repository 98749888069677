import { useCallback, useEffect, useState } from 'react';
import { IGetKlarityReviewListInput } from 'types/reputation';
import { getKlarityReviewList } from 'api/survey';
import { TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import ReviewStore from 'store/Review';

const useGetKlarityReviewList = (params?: IGetKlarityReviewListInput): [TReviewItem[] | undefined, number, boolean, string, (p: IGetKlarityReviewListInput) => void ] => {
    const [,setReviewStore] = ReviewStore.useStore();
    const [data, setData] = useState<TReviewItem[]>();
    const [totalData, setTotalData] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const fetch = useCallback(async (p: IGetKlarityReviewListInput) => {
        setLoading(true);
        try {
            const result = await getKlarityReviewList(p);
            if (result && !result.error) {
                const { list = [], totalRecords = 0 } = result.data?.data || {};
                setData(list);
                setTotalData(totalRecords);
                if (p.waitToReply) {
                    setReviewStore('klarityReviewWaitToReplyNumber', totalRecords || 0);
                }
            } else {
                setError(result?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (params) {
            fetch(params);
        }
    }, [fetch, params]);

    return [data, totalData, loading, error, fetch];
};

export default useGetKlarityReviewList;
