import React from 'react';
import { checkProviderKlarityService } from 'api/klarityService';
import { checkOpsProviderKlarityService } from 'api/operation';
import { TUniprofileFlag } from 'types/common';
import {
    IGetSalesContractPageDataInput,
} from 'types/contractLink';

let globalData:TUniprofileFlag;

const useProviderServiceFlag = (isOps?:boolean, providerId?: number): [TUniprofileFlag | undefined, boolean, (p?: IGetSalesContractPageDataInput)=>Promise<TUniprofileFlag | null | undefined>] => {
    const [loading, setLoading] = React.useState(false);
    const [result, setResult] = React.useState<TUniprofileFlag | undefined>(globalData);
    const fetchData = async () => {
        if (loading) return;
        let flagResult: TUniprofileFlag | null = null;
        try {
            setLoading(true);
            let res:any;
            if (isOps && providerId) {
                res = await checkOpsProviderKlarityService(providerId);
            } else {
                res = await checkProviderKlarityService();
            }
            const data = res?.data?.data;
            flagResult = data;
            setResult(data);
            // setResult({
            //     kiwiServiceFlag: true,
            //     klarityServiceFlag: false,
            // });
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

        return flagResult;
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [result, loading, fetchData];
};

export default useProviderServiceFlag;
