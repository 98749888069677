import { useState } from 'react';
import { deleteLicense as deleteLicenseAdmin } from 'api/operation';
import { deleteLicense } from 'api/channel';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';

interface IProps {
    form?: FormInstance;
    isOperation?: boolean;
    filedName?: string;
}

const useDeleteLicense = ({
    form,
    isOperation,
    filedName = 'licenses',
}: IProps):[(index: number, removeCallback: () => void) => void, (ids?: number[],successCallback?: () => void) => void, boolean] => {
    const [pendingRemove, setPendingRemove] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);
    const handleRemove = async (idsParamas?: number[], successCallback?: () => void) => {
        const ids = idsParamas || pendingRemove;
        let deleteApi = deleteLicense;
        if (isOperation) {
            deleteApi = deleteLicenseAdmin;
        }
        const requests = ids.map((id) => deleteApi(id));
        
        try {
            setLoading(true);
            const res = await Promise.all(requests);

            if (res.every((e) => !e.error)) {
                setPendingRemove([]);
                successCallback?.();
            } else {
                message.error('Some data was not deleted successfully. Please try again');
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const onRemove = async (index: number, removeCallback?: () => void) => {
        if (form) {
            const filed = form.getFieldValue(filedName)?.[index];
    
            if (filed && filed.id) {
                const newPendingRemove = Array.from(new Set([
                    ...pendingRemove,
                    filed.id,
                ]));
                setPendingRemove(newPendingRemove);
            }
    
            removeCallback?.();
        }
    };

    return [
        onRemove,
        handleRemove,
        loading,
    ];
};

export default useDeleteLicense;
