import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Alert, Checkbox, DatePicker, Form, Input, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { EBindType, ECurrentStep, IProvderAgreementSignInput } from 'types/salesProvider';
import { agreementSign } from 'api/salesProvider';
import { ISalesProvider } from 'types/provider';
import dayjs from 'dayjs';
// import FormValidationHelper from 'utils/validation';

interface IProps {
    formId: string;
    salesDetail?: ISalesProvider;
    setCheckoutLoading: (s: boolean) => void;
    setNextButtonDisabled: (s: boolean) => void;
    onNext: (n: ECurrentStep) => void;
    setSalesDetail: (detail: ISalesProvider) => void;
    showPaySuccessTip?: boolean;
    setShowPaySuccessTip: (s: boolean) => void;
}

const Agreement = ({
    formId,
    salesDetail,
    setCheckoutLoading,
    setNextButtonDisabled,
    onNext,
    setSalesDetail,
    showPaySuccessTip,
    setShowPaySuccessTip,
}: IProps) => {
    const [agreementForm] = Form.useForm<IProvderAgreementSignInput>();
    const [checked, setChecked] = useState(false);
    const [showCheckTip, setShowCheckTip] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [address, setAddress] = useState('');

    useEffect(() => {
        agreementForm.setFieldsValue({
            contractSignDate: dayjs(),
        });
    }, [agreementForm]);

    useEffect(() => {
        setCheckoutLoading(false);
    }, []);

    useEffect(() => {
        setNextButtonDisabled(false);
    }, [setNextButtonDisabled]);

    const handleCheckBoxChange = useCallback((e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);
    const handleDateChange = useCallback((e, dateString: string) => {
        setDate(dateString);
    }, []);
    const handleAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    }, []);

    const handleAgreementSubmit = useCallback(async (data: IProvderAgreementSignInput) => {
        setCheckoutLoading(true);
        try {
            const res = await agreementSign(data);

            if (res && !res.error) {
                const detail = res.data.data;
                setSalesDetail(detail);
                const step = detail?.currentStep as ECurrentStep || ECurrentStep.CS;

                onNext?.(step);
            }
        } catch (e) {
            console.error(e);
        }
        setCheckoutLoading(false);
    }, [onNext, setCheckoutLoading, setSalesDetail]);

    const handleFormFinish = useCallback(() => {
        if (!checked) {
            setShowCheckTip(true);
        } else {
            setShowCheckTip(false);
            agreementForm.validateFields().then((formData: IProvderAgreementSignInput) => {
                if (salesDetail?.linkUuid) {
                    handleAgreementSubmit({
                        ...formData,
                        contractSignDate: formData.contractSignDate ? dayjs(formData.contractSignDate).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY'),
                        linkUuid: salesDetail.linkUuid,
                    });
                }
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [agreementForm, checked, handleAgreementSubmit, salesDetail]);

    const { agreement } = salesDetail || {};
    const { contractId, contractUrl } = agreement || {};

    return (
        <div className={s.wrap}>
            {
                showPaySuccessTip &&
                <div className={s.tip}>
                    <div className={s.tipInner}>
                        <div className={s.tipText}>🎉  Your payment was processed successfully. Please review the agreement below to proceed.</div>
                        <div className={s.closeIcon} onClick={() => setShowPaySuccessTip(false)} />
                    </div>
                </div>
            }
            <div className={s.title}>Confirm your agreement: Secure Your Partnership with Kiwi</div>
            <div className={s.iframeWrap}>
                <iframe className={s.iframe} title="overview" src={contractUrl} />
                {/* <iframe className={s.iframe} title="overview" src={`https://docs.google.com/viewer?url=${contractUrl}&embedded=true`} /> */}
            </div>
            <div className={s.confirmBox}>
                <Checkbox checked={checked} className={s.checkbox} onChange={handleCheckBoxChange}>By checking this box, I agree to the terms outlined in the agreement.</Checkbox>
                { !checked && showCheckTip && <Alert className={s.alertMessage} message="Please accept the agreement to proceed to the next step." type="error" showIcon /> }
                <Form
                    form={agreementForm}
                    id={formId}
                    layout="vertical"
                    onFinish={handleFormFinish}
                >
                    <div className={s.foromItems}>
                        <div className={s.formItem}>
                            <Form.Item
                                label="Signature"
                                name="contractSignName"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                ]}
                            >
                                <Input style={{ width: '100%' }} type="text" onChange={handleNameChange} placeholder="Type your name" />
                            </Form.Item>
                            <div className={s.signatureText}>{name}</div>
                        </div>
                        <div className={`${s.formItem} ${s.fromItemDate}`}>
                            <Form.Item
                                label="Today’s date"
                                name="contractSignDate"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" disabledDate={(current) => current && current > dayjs().endOf('day')} onChange={handleDateChange} />
                            </Form.Item>
                            <div className={s.signatureText}>{date}</div>
                        </div>
                        <div className={`${s.formItem} ${s.formItemAddress}`}>
                            <Form.Item
                                label={
                                    <div className={s.formLabel}>Address
                                        <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>Enter your official address to receive legal documents and critical updates for your practice and contract obligations.</div>}>
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                name="contractSignAddress"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                    // FormValidationHelper.validateNonChinese('Please do not enter Chinese'),
                                ]}
                            >
                                <Input style={{ width: '100%' }} type="text" placeholder="Type your address" onChange={handleAddressChange} />
                            </Form.Item>
                            <div className={s.signatureText}>{address}</div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Agreement;
