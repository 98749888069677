import React from 'react';
import s from './s.module.less';
import { GOOGLE_CLIENT_ID } from 'constants/google';
import { Spin } from 'antd';
import { loginWithoutPwd } from 'api/public';

type Props = {
    onCallback: (res:any)=> void;
};

export default function GoogleLogin(props: Props) {
    const { onCallback } = props;
    const [loading, setLoading] = React.useState(false);

    (window as any).handleOpCredentialResponse = async (result: any) => {
        if (result && result.credential) {
            setLoading(true);
            const res = await loginWithoutPwd({
                credential: result.credential,
            });
            setLoading(false);
            onCallback(res);
        }
    };
    const width = window.screen.availWidth * 0.9 > 400 ? 400 : window.screen.availWidth * 0.9;
    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div
                    id="g_id_onload"
                    data-client_id={GOOGLE_CLIENT_ID}
                    data-context="signin"
                    data-ux_mode="popup"
                    data-callback="handleOpCredentialResponse"
                    data-nonce=""
                    data-auto_select="false"
                    data-itp_support="true"
                />
                <div
                    className="g_id_signin"
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-text="continue_with"
                    data-size="large"
                    data-logo_alignment="center"
                    data-width={width}
                />
            </Spin>
        </div>
    );
}
