import React from 'react';
import {
    getAdminCalendarColors,
    getCalendarColors,
} from 'api/common';
import { Color } from 'types/calendar';
import Store from './store';

let loading = false;

interface IProps {
    useAdminApi?: boolean,
}

const useCalendarColors = ({
    useAdminApi,
}: IProps):[Color[], boolean] => {
    const [getColorModal, setColorModal] = Store.useStore();
    const fetchData = async () => {
        try {
            loading = true;
            setColorModal('loading', true);
            let result;
            if (useAdminApi) {
                result = await getAdminCalendarColors();
            } else {
                result = await getCalendarColors();
            }

            if (!result.error) {
                const data = result?.data?.data || [];
                setColorModal('data', data);
            }
            loading = false;
            setColorModal('loading', false);
        } catch (e) {
            console.error(e);
            loading = false;
            setColorModal('loading', false);
        }
    };
    React.useEffect(() => {
        if (!getColorModal('data') || getColorModal('data')?.length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [getColorModal('data') || [], getColorModal('loading') || false];
};

export default useCalendarColors;
