import React, { useMemo } from 'react';
import { Button, Popover } from 'antd';
import { EStep } from '../../types';
import s from './s.module.less';
import { ButtonType, ButtonHTMLType } from 'antd/es/button';

type Props = {
    isAva?: boolean;
    hideBackButton?: boolean;
    step: EStep | string,
    onClickNext:(value: any)=>void,
    onClickBack:()=>void,
    onClickExit:()=>void,
    onClickSubmit:()=>void,
    loading?:boolean,
    formId: string
    disallowNext?: boolean;
    disallowNextTips?: string;
};

interface IButtonProps {
    type: ButtonType;
    htmlType?: ButtonHTMLType;
    form?: string;
    loading?: boolean;
    onClick?: () => void
}

const Footer = (props: Props) => {
    const { isAva, step = EStep.PROFILE, onClickNext, onClickBack, onClickExit, onClickSubmit, loading, formId, hideBackButton, disallowNext, disallowNextTips } = props;

    const renderNextButton = useMemo(() => {
        const buttonText = step === EStep.PREVIEW ? 'Submit for review' : 'Next';
        let buttonProps: IButtonProps = {
            type: 'primary',
            htmlType: 'submit',
            form: formId,
        };

        if (step === EStep.PREVIEW) {
            buttonProps = {
                loading,
                type: 'primary',
                onClick: onClickSubmit,
            };
        }

        // 内部有额外编辑表单的
        if (step === EStep.SERVICES || step === EStep.KLARITY_SERVICES || step === EStep.PRACTICE || step === EStep.ADDITIONAL_INFORMATION) {
            buttonProps = {
                type: 'primary',
                onClick: onClickNext as any,
            };
        }

        return <Button {...buttonProps} disabled={!!disallowNext}>{buttonText}</Button>;
    }, [formId, onClickNext, onClickSubmit, step, loading, disallowNext]);

    return (
        <div className={s.footer}>
            <div className={s.footerInner}>
                <div>
                    <Button onClick={onClickExit}>Exit</Button>
                    {(!isAva && (step === EStep.PROFILE || hideBackButton)) ? <span /> : <span className={s.back} onClick={onClickBack}>Back</span>}
                </div>
                {
                    disallowNextTips && disallowNext &&
                    <Popover content={disallowNextTips ? <div className={s.toolWrap}>{disallowNextTips}</div> : null} trigger="hover" placement="topLeft">
                        {renderNextButton}
                    </Popover>
                }
                {
                    (!disallowNext || !disallowNextTips) &&
                    renderNextButton
                }
            </div>
        </div>
    );
};

export default Footer;
