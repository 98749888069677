import { useCallback, useEffect, useState } from 'react';
import { getProviderFormDetail } from 'api/applicationForm';
import channelProviderStore from 'store/ChannelApplicationProvider';
import { transferProviderToFormData } from 'utils/channel';
import { UniprofileFormData } from 'types/provider';

const useFetchProvider = ():[UniprofileFormData | undefined, boolean, string, () => void] => {
    const [, setProvider] = channelProviderStore.useStore();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<UniprofileFormData>();
    const [error, setError] = useState<string>('');

    const fetchChannelProviderData = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getProviderFormDetail({
                allowAuth: false,
                step: 'REVIEW',
            });
            if (res && !res.error) {
                const providerData = transferProviderToFormData(res.data?.data)!;
                setProvider('data', providerData);
                setData(providerData);
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchChannelProviderData();
    }, [fetchChannelProviderData]);

    return [data, loading, error, fetchChannelProviderData];
};

export default useFetchProvider;
