import React from 'react';
import { getChannelHome } from 'api/provider';
import { ChannelHomeInfo } from 'types/channel';

let globalData:ChannelHomeInfo | undefined;

const useChannelHomeInfo = ():[ChannelHomeInfo | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<ChannelHomeInfo | undefined>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getChannelHome();
            if (!result.error) {
                const data:ChannelHomeInfo = result?.data?.data || undefined;
                globalData = data;
                setItems(globalData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading) {
            fetchData();
        }
    }, []);

    return [items, loading, fetchData];
};

export default useChannelHomeInfo;
