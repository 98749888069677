import { ECurrentStep } from 'types/salesProvider';

export enum EStep {
    CHECKOUT = 0,
    AGREEMENT = 1,
    // SCHEDULE = 2,
    // ACCOUNT = 2.5,
    ACCOUNT = 2,
    COMPLETE = 3,
}

export const stepNameToNumber: Record<ECurrentStep, number> = {
    [ECurrentStep.LS]: 0,
    [ECurrentStep.PR]: 1,
    // [ECurrentStep.OCS]: 2,
    [ECurrentStep.CS]: 2,
    [ECurrentStep.DONE]: 3,
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const kiwiDefaultMessage = `
<p style='font-size: 16px; margin-bottom: 8px'>Thank you for choosing to explore the possibilities with Kiwi. We're excited to welcome you aboard and guide you through the seamless process of activating your membership. Based on our recent conversation, we've tailored a plan just for you, aimed at enhancing your practice and connecting you with more patients.<p>
<p style='font-size: 16px; margin-bottom: 8px'>Right here, you'll finalize your membership, pay for your plan, agree to our collaborative terms, and set up your platform account.</p>
<p style='font-size: 16px; margin-bottom: 8px'>Let's get started on this journey together!</p>
`;

export const klarityDefaultMessage = `
<p style='font-size: 16px; margin-bottom: 8px'>Thank you for choosing to explore the possibilities with Klarity. We're excited to welcome you aboard and guide you through the seamless process of activating your membership. Based on our recent conversation, we've tailored a plan just for you, aimed at enhancing your practice and connecting you with more patients.</p>
<p style='font-size: 16px; margin-bottom: 8px'>Right here, you'll review the platform agreements and set up your platform account.</p>
<p style='font-size: 16px; margin-bottom: 8px'>Let's get started on this journey together!</p>
`;
