export const planDesc: Record<string, string[]> = {
    Growth: [
        'List your practice information on all core channels and gain access to discounts of premium channels.',
        'Centralize patient prospect contacts in one inbox system to simplify communication and save time.',
        'Gain recognition from your patients and further grow the visibility of your profiles.',
        'Have the option to build your customized practice website, including online contact forms, appointment request form/direct booking, and local search engine optimization.',
    ],
    Essential: [
        'List your practice information on up to 8 channels and gain access to discounts of premium channels.',
        'Centralize patient prospect contacts in one inbox system to simplify communication and save time.',
        'Gain recognition from your patients and further grow the visibility of your profiles.',
        'Have the option to build your customized practice website, including online contact forms, appointment request form/direct booking, and local search engine optimization.',
    ],
};
