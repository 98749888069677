import { useEffect, useState } from 'react';
import { EStep } from '../types';

const useEditButtonRendering = (step: EStep | string) => {
    const [showEditButtons, setShowEditButtons] = useState<boolean>(false);
    useEffect(() => {
        if (step === EStep.SERVICES || step === EStep.PREVIEW) {
            setShowEditButtons(true);
        } else {
            setShowEditButtons(false);
        }
    }, [step]);

    return [showEditButtons];
};

export default useEditButtonRendering;
