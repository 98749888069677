import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import s from './s.module.less';
import { isLocalhost, isOpPortal } from 'utils/common';

const { Content } = Layout;

const LayoutPage: React.FC = () => {
    if (!isOpPortal() && !isLocalhost()) {
        return null;
    }

    return (
        <Layout className={s.layout}>
            <Layout className={s.siteLayout}>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default LayoutPage;
