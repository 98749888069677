import React from 'react';
import {
    providerListedChannelFetch,
} from 'api/provider';
import Store from './store';
import { Channel } from 'types/common';
import { getUserId } from 'utils/localstore';

let loading = false;

const useMyListedChannels = ():[Channel[], boolean, ()=>void] => {
    const [getModal, setModal] = Store.useStore();
    const uid = getUserId();
    const fetchData = async () => {
        if (!uid)
        {
            return;
        }
        try {
            loading = true;
            setModal('loading', true);
            const result = await providerListedChannelFetch(uid!);

            if (!result.error) {
                const data = result?.data?.data || [];
                setModal('data', data);
            }
            loading = false;
            setModal('loading', false);
        } catch (e) {
            console.error(e);
            loading = false;
            setModal('loading', false);
        }
    };
    React.useEffect(() => {
        if (!getModal('data') || getModal('data')?.length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [getModal('data') || [], getModal('loading') || false, fetchData];
};

export default useMyListedChannels;
