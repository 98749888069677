import { StepValue as UniprofileStepValue } from 'pages/CreateUniProfilePage/types';

export enum EStep {
    RESUME_ENTRY = 1,
    // UPLOAD_UNIPROFILE = 1,
    ANALYSIS_PROCESS = 2,
    ANALYSIS_RESULT = 3,
    PROFILE = 4,
    HEALTHCARE_EXPERIENCE = 5,
    EDUCATION = 6,
    ADDITIONAL_INFORMATION = 7,
    PRACTICE = 8,
    LICENSE = 9,
    SERVICES = 10,
    INSURANCE = 11,
    BIOGRAPHIC = 12,
    PREVIEW = 13,
    FINISH = 14,
}

export enum ResumeStepName {
    PROFILE = 'PROFILE',
    HEALTHCARE_EXPERIENCE = 'HEALTHCARE_EXPERIENCE',
    EDUCATION = 'EDUCATION',
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
    PRACTICE = 'PRACTICE',
    HEADLINE = 'HEADLINE',
    TREATMENT_PHILOSOPHY = 'TREATMENT_PHILOSOPHY',
    TREATMENT_APPROACH = 'TREATMENT_APPROACH',
    PRACTICE_DESCRIPTION = 'PRACTICE_DESCRIPTION'
}

export enum ResumeStepValue {
    RESUME_ENTRY = 'resume_entry',
    UPLOAD_UNIPROFILE = 'upload_uniprofile',
    ANALYSIS_PROCESS = 'analysis_process',
    ANALYSIS_RESULT = 'analysis_result',
    PROFILE = 'profile',
    SERVICES = 'services',
    PRACTICE = 'practice',
    HEALTHCARE_EXPERIENCE = 'healthcare Experience',
    EDUCATION = 'education',
    ADDITIONAL_INFORMATION = 'recognition',
    INSURANCE = 'insurance',
    BIOGRAPHIC = 'Biographic',
    LICENSE = 'license',
    PREVIEW = 'review',
}

export const StepValue = {
    ...UniprofileStepValue,
    ...ResumeStepValue,
};

export type StepValueType = ResumeStepValue | UniprofileStepValue;
