import React from 'react';
import { ISurveyHistory } from 'types/survey';
import { getSurveyHistory } from 'api/survey';

const useSurveyHistory = (patientId?:number):[ISurveyHistory[] | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<ISurveyHistory[]>();
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getSurveyHistory(patientId!);
            if (!result.error) {
                const { data } = result.data;
                setItem(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading && patientId) {
            fetchData();
        }
    }, [patientId]);

    return [item, loading, fetchData];
};

export default useSurveyHistory;
