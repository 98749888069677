import React from 'react';
import { getPayPlanList } from 'api/operation';
import { TOpsSubscriptionItem } from 'types/subscription';

const useOpsSubscriptionPlan = ():[TOpsSubscriptionItem[], boolean | null, ()=>void] => {
    const [loading, setLoading] = React.useState<boolean | null>(null);
    const [items, setItems] = React.useState<TOpsSubscriptionItem[]>([]);
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getPayPlanList();
            if (!result.error) {
                const data:TOpsSubscriptionItem[] = result?.data?.data || [];
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading) {
            fetchData();
        }
    }, []);

    return [items, loading, fetchData];
};

export default useOpsSubscriptionPlan;
