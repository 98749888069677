import React, { useCallback, useEffect } from 'react';
import { IklarityProgramMedicationItem } from 'types/programs';
import { getKlarityMedicationList } from 'api/operation/programs';

const useGetMedicationList = ():[IklarityProgramMedicationItem[]|undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<IklarityProgramMedicationItem[]>([]);
    const fetchData = useCallback(async () => {
        if (loading) return;
        try {
            setLoading(true);
            const result = await getKlarityMedicationList();
            if (!result.error) {
                const resData = result?.data?.data || [];

                setItems(resData);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [loading]);

    useEffect(() => {
        fetchData();
    }, []);

    return [items, loading, fetchData];
};

export default useGetMedicationList;
