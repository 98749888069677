import { useCallback, useEffect, useState } from 'react';
import { getPFStatusData } from 'api/practiceFront';
import ProviderStore from 'store/Provider';

const useGetUniprofileStatus = (): [boolean, boolean, string] => {
    const [, setProvider] = ProviderStore.useStore();
    const [uniprofileFinished, setUniprofileFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchPFStatusData = useCallback(async () => {
        setLoading(true);
        const res = await getPFStatusData();

        if (!res?.error) {
            const status = !!res.data?.data?.uniprofile;
            setProvider('uniprofileStatus', status);
            setUniprofileFinished(status);
        } else {
            setError(res.error);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchPFStatusData();
    }, [fetchPFStatusData]);

    return [uniprofileFinished, loading, error];
};

export default useGetUniprofileStatus;
