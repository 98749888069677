import { useCallback, useEffect, useState } from 'react';
import { getStripePaymentInfo } from 'api/stripe';
import { IStripePaymentInfo } from 'types/common';

const useGetStripePaymentInfo = ():[IStripePaymentInfo[] | undefined, boolean, string] => {
    const [paymentInfo, setPaymentInfo] = useState<IStripePaymentInfo[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const removeDuplicates = (data: IStripePaymentInfo[]): IStripePaymentInfo[] => {
        const seen = new Set<string>();
        return data.filter((item) => {
            // 先过滤掉 card 为 null 或者没有paymentMethodId 的项
            if (item.card === null || !item.id) {
                return false;
            }
            const identifier = `${item.card.brand}:${item.card.last4}`;
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        });
    };

    const fetchPaymentInfo = useCallback(async () => {
        setLoading(true);

        try {
            const response = await getStripePaymentInfo();

            if (response && !response.error) {
                if (response.data?.data && response.data?.data.length > 0) {
                    setPaymentInfo(removeDuplicates(response.data?.data));
                } else {
                    setPaymentInfo([]);
                }
            } else {
                setError(response.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetchPaymentInfo();
    }, [fetchPaymentInfo]);

    return [paymentInfo, loading, error];
};

export default useGetStripePaymentInfo;
