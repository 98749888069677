import React from 'react';
import { providerOverrideList } from 'api/appointmentSetting';
import { IproviderOverrideList } from 'types/appointmentSetting';
import { getUserId } from 'utils/localstore';
import dayjs from 'dayjs';

let globalData:IproviderOverrideList[] = [];

// 需要对override日期排序 默认是降序
const sortDate = (val: IproviderOverrideList[]) => {
    val.sort((a, b) => dayjs(b.overrideDate).valueOf() - dayjs(a.overrideDate).valueOf());
};

const useOverride = ():[IproviderOverrideList[], boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<IproviderOverrideList[]>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const uid = getUserId();
            const result = await providerOverrideList(uid);
            if (!result.error) {
                const data:IproviderOverrideList[] = result?.data?.data || [];
                globalData = data;
                sortDate(globalData);
                setItems(globalData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || Object.keys(items).length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [items, loading, fetchData];
};

export default useOverride;
