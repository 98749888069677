import { useCallback } from 'react';
import { withoutBasicAPICallingPath } from 'constants/path';
import { getUserId } from 'utils/localstore';
import { isOpPortal } from 'utils/common';
import { providerFetch as adminProviderFetch, getConfigFeaturedItems as adminGetConfigFeaturedItems } from 'api/operation';
import { getAdminListSetting, getListSetting, getConfigFeaturedItems } from 'api/common';
import { getProviderPlan } from 'api/subscription';
import { providerFetch } from 'api/provider';
import ProviderStore from 'store/Provider';
import SubscriptionStore from 'store/Subscription';
import { EnumFields as SettingEnumFields, SettingEnum, SettingDataType, EnumFields } from 'types/enumerationData';
import SettingEnumDataStore from 'store/Enumeration';
import CommonStore from 'store/Common';
import { ServerMeta } from 'types/common';
import { EStripeStatus, TSubscriptionType } from 'types/subscription';
// import AccessStore from 'store/Access/permission';
// import { PermissionModuleKeys } from 'constants/access';
// import useSyncAccess from 'hooks/useSyncAccess';
// import AccessRoleStore from 'store/Access/role';
// import { redirecToLogout } from 'utils/redirecToLogout';

const useLoadBasicData = () => {
    const [, setUser] = ProviderStore.useStore();
    const [, setSubscription] = SubscriptionStore.useStore();
    const [, setSettingEnumData] = SettingEnumDataStore.useStore();
    const [, setCommon] = CommonStore.useStore();
    // const [getAccessStore] = AccessStore.useStore();
    // const access = getAccessStore('data') as Record<PermissionModuleKeys, boolean>;
    // const [saveAccess, saveRole] = useSyncAccess();
    // const [getRoleStore] = AccessRoleStore.useStore();

    const loadUserInfo = useCallback(async () => {
        const uid = getUserId();
        if (uid) {
            let result;
            if (isOpPortal()) {
                result = await adminProviderFetch(uid);
            } else {
                result = await providerFetch(uid);
            }

            if (!result.error) {
                const { data } = result.data;
                setUser('data', data);
            }
        }
    }, []);

    const loadUserSubscriptionInfo = async () => {
        if (!isOpPortal()) {
            setSubscription('loading', true);
            const result = await getProviderPlan();
            if (!result.error) {
                const { data } = result.data;
                let type = data.type;

                if (![EStripeStatus.ACTIVE, EStripeStatus.TRIALING, EStripeStatus.PAUSED].includes(data.originStatus!)) {
                    if (
                        type === TSubscriptionType.KLARITY
                        || type === TSubscriptionType.KLARITY_STARTER
                        || type === TSubscriptionType.KLARITY_PLUS
                        || type === TSubscriptionType.KLARITY_PRO
                    ) {
                        type = TSubscriptionType.KLARITY_STARTER;
                    } else {
                        type = TSubscriptionType.FREE;
                    }
                }
                const plan = {
                    ...data,
                    type,
                }
                setSubscription('currentPlan', plan);
            }
            setSubscription('loading', false);
        }
    };

    // const handleDirectToLoginPage = useCallback((response?: any) => {
    //     // 一些请求写在了最外层的app.tsx里，导致login 页面也会发请求，这就意味着如果不加 !== '/', 这里会进入401死循环
    //     redirecToLogout();
    //     if (response) {
    //         const errorText = response.data?.message ?? response.data?.error;
    //         throw new Error(errorText ?? `${response.data.code} ${response.statusText}`);
    //     }
    // }, []);

    const loadListSetting = useCallback(async () => {
        let result;
        if (isOpPortal()) {
            result = await getAdminListSetting();
        } else {
            result = await getListSetting();
        }
        if (!result?.error) {
            const { data } = result.data;
            const settingEnum:SettingEnumFields[] = [];
            const initData: Partial<SettingDataType> = {};

            // eslint-disable-next-line guard-for-in
            for (const key in SettingEnumFields) {
                settingEnum.push(key as SettingEnumFields);
                initData[key as EnumFields] = [];
            }

            const enumData = settingEnum.reduce((res: SettingDataType, key) => {
                res[key] = data?.filter((item: SettingEnum) => item.type === key);

                return res;
            }, initData as SettingDataType);

            setSettingEnumData('data', enumData);
        }
    }, []);

    const loadFeaturedItemSetting = useCallback(async () => {
        let result;
        if (isOpPortal()) {
            result = await adminGetConfigFeaturedItems();
        } else {
            result = await getConfigFeaturedItems();
        }
        if (!result?.error) {
            const allData:ServerMeta[] = result.data.data;
            const featuredState:ServerMeta[] = allData?.filter((item) => item.type === 'STATE')?.sort((a, b) => (a.seq || 0) - (b.seq || 0));
            const featuredCondition:ServerMeta[] = allData?.filter((item) => item.type === 'CONDITION').sort((a, b) => (a.seq || 0) - (b.seq || 0));

            setCommon('featuredState', featuredState || []);
            setCommon('featuredCondition', featuredCondition || []);
        }
    }, []);

    // const loadOpsUserAccess = useCallback(async () => {
    //     if (isOpPortal()) {
    //         const res = await getOpsUserPermissions();

    //         if (!res.error) {
    //             const privList = res?.data?.data?.privList || [];
    //             const role = res?.data?.data?.roles || [];

    //             saveAccess(privList);
    //             saveRole(role);
    //         } else if (res?.errorCode >= 4000) {
    //             handleDirectToLoginPage(res);
    //         }
    //     }
    // }, [handleDirectToLoginPage]);

    const basicAPICalling = useCallback(() => {
        const { pathname } = window.location;

        if (!withoutBasicAPICallingPath.includes(pathname)) {
            console.info('load ba');
            loadUserInfo();
            loadUserSubscriptionInfo();
            loadListSetting();
            loadFeaturedItemSetting();
        }
    }, [loadListSetting, loadUserInfo, loadFeaturedItemSetting]);

    return [basicAPICalling];
};

export default useLoadBasicData;
