import React from 'react';
import { ServerMeta } from 'types/common';
import useGetSettingData from './useGetSettingData';
import { EnumFields } from 'types/enumerationData';

let globalData:Record<string, ServerMeta[]> = {}; //dickey : ServerMeta[]
const globalDataConditionDicToSpecialtyDic:Record<string, string> = {}; //condition dickey : specialty dickey

const useKlaritySpecialtyConditionMap = ():[Record<string, ServerMeta[]>, Record<string, string>] => {
    const [specialty] = useGetSettingData(EnumFields.HELLO_KLARITY_SPECIALTY);
    const [condition] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const [items, setItems] = React.useState<Record<string, ServerMeta[]>>(globalData);
    const [item2, setItem2] = React.useState<Record<string, string>>(globalDataConditionDicToSpecialtyDic);

    React.useEffect(() => {
        if (specialty && condition && specialty.length > 0 && condition.length > 0) {
            const _data:Record<string, ServerMeta[]> = {};
            (specialty as ServerMeta[]).forEach((s:ServerMeta) => {
                const key = s.dictKey;
                const filterCondition = (condition as ServerMeta[]).filter((c:ServerMeta) => c.description === key);
                _data[key] = filterCondition;
            });
            globalData = _data;
            setItems(_data);
            const _data2:Record<string, string> = {};
            (condition as ServerMeta[]).forEach((c:ServerMeta) => {
                _data2[c.dictKey!] = c.description!;
            });
            setItem2(_data2);
        }
    }, [specialty, condition]);

    return [items, item2];
};

export default useKlaritySpecialtyConditionMap;
