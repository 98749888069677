import React from 'react';
import { Service } from 'types/common';
import { serviceTemplateList } from 'api/operation';
import { providerServiceTemplateList } from 'api/provider';
import Store from './store';

let loading = false;

const useServiceTemplateData = (isOps?:boolean):[Service[], boolean] => {
    const [getServiceTemplateModal, setServiceTemplateModal] = Store.useStore();
    const fetchData = async () => {
        try {
            loading = true;
            setServiceTemplateModal('loading', true);
            const result = isOps ? await serviceTemplateList() : await providerServiceTemplateList();
            if (!result.error) {
                const data:Service[] = result?.data?.data || [];
                setServiceTemplateModal('data', data);
            }
            loading = false;
            setServiceTemplateModal('loading', false);
        } catch (e) {
            console.error(e);
            loading = false;
            setServiceTemplateModal('loading', false);
        }
    };
    React.useEffect(() => {
        if (!getServiceTemplateModal('data') || getServiceTemplateModal('data')?.length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [getServiceTemplateModal('data') || [], loading];
};

export default useServiceTemplateData;
