import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import ResumeEntry from 'components/UniprofileResume/ResumeEntry';
import UploadUniprofilePage from 'components/UniprofileResume/UploadUniprofilePage';
import AnalysisProcess from 'components/UniprofileResume/AnalysisProcess';
import AnalysisResult from 'components/UniprofileResume/AnalysisResult';
import SideStep from 'components/SideStep';
import { StepData } from 'pages/CreateUniProfilePage/data';
import Footer from './components/Footer';
import { EStep } from './types';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { Button, Modal, Spin } from 'antd';
import { generateUrlSearch, getUrlParam } from 'utils/common';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, PRACTICE_FRONT_FROM, UNIPROFILE_CREATION_ALISA } from 'constants/common';
import useGetUniprofileResumeInfo from 'hooks/useGetUniprofileResumeInfo';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const UniprofileResume = () => {
    const [homeInfo, loadingHomeInfo] = useProviderHomeInfo();
    const [resumeData,resumeInfoLoading,,fetchResumeData] = useGetUniprofileResumeInfo();
    const [showResumeInfoTip, setShowResumeInfoTip] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const navigate = useNavigate();
    const uniprofileSubmitted = !!(homeInfo?.uniprofileSubmitted);
    const backFrom = getUrlParam('backFrom');
    const from = getUrlParam('from');
    const type = getUrlParam('type');
    const [currentStep, setCurrentStep] = useState(backFrom === UNIPROFILE_CREATION_ALISA ? EStep.ANALYSIS_RESULT : EStep.RESUME_ENTRY);
    // const [currentStep, setCurrentStep] = useState(EStep.ANALYSIS_PROCESS);
    const [showSteps, setShowSteps] = useState(false);
    const [showFooter, setShowFooter] = useState(true);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
    const [showTopRequiredTip, setShowTopRequiredTip] = useState(true);
    // const [showFinishedTooltip, setShowFinishedTooltip] = useState(false);
    const hideTopRequiredTipSteps = useRef([EStep.RESUME_ENTRY, EStep.UPLOAD_UNIPROFILE, EStep.ANALYSIS_PROCESS, EStep.ANALYSIS_RESULT, EStep.BIOGRAPHIC]);
    // const hideFinishedTooltipSteps = useRef([EStep.RESUME_ENTRY, EStep.UPLOAD_UNIPROFILE, EStep.ANALYSIS_PROCESS, EStep.ANALYSIS_RESULT]);
    const [createType, setCreateType] = useState<number>(1);
    const [documentId, setDocumentId] = useState<string | true>();

    useEffect(() => {
        fetchResumeData();
    }, []);

    useEffect(() => {
        if (!uniprofileSubmitted && resumeData && resumeData.documentId) {
            setShowResumeInfoTip(true);
        }
    }, [resumeData]);

    useEffect(() => {
        if (currentStep === EStep.RESUME_ENTRY && !documentId) {
            setNextButtonEnabled(false);
        } else {
            setNextButtonEnabled(true);
        }
    }, [currentStep, documentId]);

    // 是否显示左侧步骤
    useEffect(() => {
        setShowSteps(currentStep > EStep.ANALYSIS_RESULT);
    }, [currentStep]);

    // 是否显示footer
    useEffect(() => {
        setShowFooter(currentStep !== EStep.ANALYSIS_PROCESS);
    }, [currentStep]);

    // 隐藏顶部的Indicates required fields文案
    useEffect(() => {
        setShowTopRequiredTip(!hideTopRequiredTipSteps.current.includes(currentStep));
        // setShowFinishedTooltip(!hideFinishedTooltipSteps.current.includes(currentStep));
    }, [currentStep]);

    const handleSelectCreateUniprofileType = useCallback((v: number) => {
        setCreateType(v);
    }, []);

    const handleNextClick = useCallback(() => {
        if (currentStep === EStep.RESUME_ENTRY && createType === 2) {
            const urlParams = {
                from,
                type,
            };
            const urlSearch = generateUrlSearch(urlParams);
            navigate(`${PATH.CREATE_UNI_PROFILE}${urlSearch ? `?${urlSearch}` : ''}`);
        } else {
            const step = currentStep + 1;

            if (step > EStep.ANALYSIS_RESULT) {
                navigate(`${PATH.CREATE_UNI_PROFILE}?isAva=true&from=${from}&type=${type}`);
            } else {
                setCurrentStep(step);
            }
        }
    }, [createType, currentStep, from, navigate, type]);

    const onClickBack = useCallback(() => {
        const backStep = currentStep === EStep.ANALYSIS_RESULT ? 2 : 1;
        const nextStep = currentStep - backStep;
        const currentStepVal = nextStep < EStep.RESUME_ENTRY ? EStep.RESUME_ENTRY : nextStep;
        // setFormId(STEPS[currentStepVal]);
        setCurrentStep(currentStepVal);
        // setGloalEditStatus('globalEditStatus', false);
    }, [currentStep]);

    const handleStepClick = useCallback(() => {
        modal.warning({
            title: 'Unfinished section detected',
            content: 'We\'ve noticed some essential fields haven\'t been completed in this section. Please fill out all required fields before moving to another section. This ensures your profile is comprehensive and ready for use.',
            width: 512,
            okText: 'Return to edit',
        });
    }, [modal]);

    const handleUploadSuccess = useCallback((id?: string | true) => {
        setDocumentId(id);
    }, []);

    const handleAnalysisFinish = useCallback(() => {
        // if (resumeData) {
        //     setUniprofileResumeData(resumeData);
        // }
    }, []);

    const onExit = useCallback(() => {
        sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);
        if (from === PRACTICE_FRONT_FROM) {
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
        } else {
            navigate(`${PATH.DASHBOARD}/${PATH.HOME}`, { replace: true });
        }
        // setGloalEditStatus('globalEditStatus', false);
    }, [from, navigate]);

    const contentRender = useMemo(() => {
        let content = null;

        switch (currentStep) {
            case EStep.RESUME_ENTRY:
                content = <ResumeEntry onUploadSuccess={handleUploadSuccess} onClick={handleSelectCreateUniprofileType} type={createType} />;
                break;
            // case EStep.UPLOAD_UNIPROFILE:
            //     content = <UploadUniprofilePage onUploadSuccess={handleUploadSuccess} />;
            //     break;
            case EStep.ANALYSIS_PROCESS:
                content = <AnalysisProcess onNext={() => setCurrentStep(currentStep + 1)} onFinish={handleAnalysisFinish} />;
                break;
            case EStep.ANALYSIS_RESULT:
                content = <AnalysisResult />;
                break;
            default: content = null;
        }

        return content;
    }, [currentStep, handleSelectCreateUniprofileType, createType, handleUploadSuccess, handleAnalysisFinish]);

    const fixedContentHeight = useMemo(() => currentStep > EStep.ANALYSIS_RESULT, [currentStep]);

    if (resumeInfoLoading || loadingHomeInfo) {
        return <Spin></Spin>
    }

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.header}>
                <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" className={s.logo} />
            </div>
            <Modal
                width={780}
                closable={false}
                open={showResumeInfoTip}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => {
                            setShowResumeInfoTip(false);
                        }}>Upload new resume</Button>
                        <Button type="primary" onClick={() => {
                            navigate(`${PATH.CREATE_UNI_PROFILE}?isAva=true`);
                        }}>Continue creating UniProfile</Button>
                    </div>
                }
            >
                <div className={s.modalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Resume found: continue or start over?</div>
                        <div className={s.descBox}>
                            <div className={s.desc}>We’ve found your previously uploaded resume, but you didn’t complete the UniProfile submission process. Would you like to pick up where you left off using the existing resume, or upload a new resume to start over?</div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={`${s.body} ${fixedContentHeight && s.fixedHeight}`}>
                {
                    showSteps &&
                    <div className={s.sideStepWrap}>
                        <SideStep steps={StepData} currentStep={currentStep - EStep.PROFILE} onStepClick={handleStepClick} />
                    </div>
                }
                <div className={s.content}>
                    {
                        showTopRequiredTip &&
                        <div className={s.indicate}>
                            <a>*</a> Indicates required fields
                        </div>
                    }
                    {/* {
                        showFinishedTooltip &&
                        <div className={s.checkFinishedTooltip}>
                            <UniprofileCheckFinishedTooltipBar />
                        </div>
                    } */}
                    {contentRender}
                </div>
            </div>
            { showFooter &&
            <Footer
                step={currentStep}
                onClickNext={handleNextClick}
                onClickBack={onClickBack}
                onClickExit={onExit}
                nextButtonEnabled={nextButtonEnabled}
            /> }
        </div>
    );
};

export default UniprofileResume;
