import { useCallback, useEffect, useState } from 'react';
import { getAddOnUniprofileInfo } from 'api/ehr';
import { FormType } from 'types/ehr';

const useGetEHRAddOnUniprofileInfo = (): [FormType | undefined, boolean, string ] => {
    const [data, setData] = useState<FormType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const result = await getAddOnUniprofileInfo();
            if (result && !result.error) {
                setData(result?.data?.data);
            } else {
                setError(result?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error];
};

export default useGetEHRAddOnUniprofileInfo;
