import { message } from 'antd';
import { getAssessmentEmailById } from 'api/operation/assessment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IAssessmentEmail } from 'types/operation';

const useGetAssessmentEmailById = (assessmentId?: number): [IAssessmentEmail[] | undefined, boolean, string, (assessmentId:number) => void] => {
    const [loading, setLoading] = useState(false);
    const [emailList, setEmailList] = useState<IAssessmentEmail[]>([]);
    const [error, setError] = useState<string>('');

    const syncEmailList = useCallback((emailListData: IAssessmentEmail[] = []) => {
        const defaultEmailList: Partial<IAssessmentEmail>[] = [
            {
                riskLevel: 1,
                subject: '',
                bannerImageUrl: '',
                ctaText: '',
                ctaUrl: '',
                assessmentId,
            },
            {
                riskLevel: 2,
                subject: '',
                bannerImageUrl: '',
                ctaText: '',
                ctaUrl: '',
                assessmentId,
            },
            {
                riskLevel: 3,
                subject: '',
                bannerImageUrl: '',
                ctaText: '',
                ctaUrl: '',
                assessmentId,
            },
        ];
        if (emailListData.length === 0) {
            setEmailList(defaultEmailList);
        } else {
            const res = defaultEmailList?.map((defaultItem) => {
                const emailItem = emailListData.find((item) => defaultItem.riskLevel === item.riskLevel);
                if (emailItem) {
                    return {
                        ...defaultItem,
                        ...emailItem,
                    };
                }

                return {
                    ...defaultItem,
                };
            });

            setEmailList(res);
        }
    }, [assessmentId]);

    const fetch = useCallback(async (id: number) => {
        try {
            setLoading(true);

            const res = await getAssessmentEmailById(id);

            if (res && !res.error) {
                syncEmailList(res.data?.data || []);
            } else {
                message.error(res?.error);
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [syncEmailList]);

    useEffect(() => {
        if (assessmentId) {
            fetch(assessmentId);
        }
    }, [fetch, assessmentId]);

    return [emailList, loading, error, fetch];
};

export default useGetAssessmentEmailById;
