import { PATH } from 'constants/path';
import ProviderStore from 'store/Provider';

const useGetUniprofileEntry = (): [string] => {
    const [get] = ProviderStore.useStore();
    const uniprofileFinished = get('uniprofileStatus');

    const entryPath = uniprofileFinished ? PATH.CREATE_UNI_PROFILE : `/${PATH.UNIPROFILE_RESUME}`;

    return [entryPath];
};

export default useGetUniprofileEntry;
