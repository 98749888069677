// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--S6pJ6 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: s-module__gradient--Mu_6Y 15s ease infinite;
}
.s-module__opWrap--km7G0 {
  width: 80%;
  max-width: 520px;
  height: 375px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s-module__opWrap--km7G0 img {
  height: 48px;
}
.s-module__opWrap--km7G0 .s-module__googleWrap--zh4Tq {
  width: 80%;
  margin-top: 24px;
}
.s-module__opWrap--km7G0 h3 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 32px;
  color: var(--gray-900);
}
.s-module__opWrap--km7G0 .s-module__tip--H_4Lq {
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 48px;
}
.s-module__opWrap--km7G0 .s-module__tip--H_4Lq a {
  color: var(--main-blue);
}
.s-module__opWrap--km7G0 .s-module__tip--H_4Lq a:hover {
  text-decoration: underline;
}
@keyframes s-module__gradient--Mu_6Y {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uEAAA;EACA,0BAAA;EACA,sDAAA;AACF;AAEA;EACE,UAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,0CAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAZA;EAcI,YAAA;AACJ;AAfA;EAiBI,UAAA;EACA,gBAAA;AACJ;AAnBA;EAqBI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AAzBA;EA4BI,eAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AA9BA;EAgCM,uBAAA;AACN;AAjCA;EAmCM,0BAAA;AACN;AAIA;EACE;IACE,2BAAA;EAFF;EAKA;IACE,6BAAA;EAHF;EAMA;IACE,2BAAA;EAJF;AACF","sourcesContent":[".wrap {\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);\n  background-size: 400% 400%;\n  animation: gradient 15s ease infinite;\n}\n\n.opWrap{\n  width: 80%;\n  max-width: 520px;\n  height: 375px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);\n  padding: 32px;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  img {\n    height: 48px;\n  }\n  .googleWrap{\n    width: 80%;\n    margin-top: 24px;\n  }\n  h3{\n    font-size: 24px;\n    font-weight: 500;\n    margin-top: 32px;\n    color: var(--gray-900);\n\n  }\n  .tip{\n    font-size: 16px;\n    margin-top: 24px;\n    margin-bottom: 48px;\n    a{\n      color: var(--main-blue);\n    }\n    a:hover{\n      text-decoration: underline;\n    }\n  }\n}\n\n@keyframes gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n\n  50% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0% 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--S6pJ6`,
	"gradient": `s-module__gradient--Mu_6Y`,
	"opWrap": `s-module__opWrap--km7G0`,
	"googleWrap": `s-module__googleWrap--zh4Tq`,
	"tip": `s-module__tip--H_4Lq`
};
export default ___CSS_LOADER_EXPORT___;
