import React, { useCallback, useEffect } from 'react';
import { IKlarityProgramTagResp } from 'types/programs';
import { getAllTags } from 'api/operation/programs';
import ProgramsStore from 'store/Programs';

const useGetAllTags = ():[IKlarityProgramTagResp[]|undefined, boolean, ()=>void] => {
    const [getProgramsStore,setProgramsStore] = ProgramsStore.useStore();
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<IKlarityProgramTagResp[]>();
    const [fetched, setFetched] = React.useState(false);
    const fetchData = useCallback(async () => {
        if (loading) return;
        try {
            setLoading(true);
            const result = await getAllTags();
            if (!result.error) {
                const resData = result?.data?.data || [];

                setProgramsStore('tags', resData);
                setItem(resData);
            }
        } catch (e) {
            console.error(e);
        }
        setFetched(true);
        setLoading(false);
    }, [loading]);

    useEffect(() => {
        if (getProgramsStore('tags') === null && !fetched) {
            fetchData();
        }
    }, [fetchData, fetched]);

    return [item, loading, fetchData];
};

export default useGetAllTags;
