import React from 'react';
import s from './s.module.less';

interface IProps {
    step: number;
}

const ProgressBar = ({
    step = 0,
}: IProps) => {
    const totalStep = 4;
    return (
        <div className={s.wrap}>
            {
                new Array(totalStep).fill('').map((e, i) => {
                    return <div key={i} className={`${i > step ? '' : s.actived} ${s.bar}`} />;
                })
            }
        </div>
    );
};

export default ProgressBar;
