import TinyStore from 'lib/tinyStore';
import { Service } from 'types/common';

type Modal = {
    data: Service[];
    loading: boolean;
};

const Store = TinyStore.createStore<Modal>({
    nameSpace: 'klarity',
    key: 'service-template-hook',
    crossBundle: false,
    runTime: true,
    initValue: { data: [], loading: false },
});

export default Store;
