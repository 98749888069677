import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'styles/variable.css';
import 'styles/reset.css';
import * as Sentry from '@sentry/react';
import { isLocalhost } from 'utils/common';

try {
    if (!isLocalhost()) {
        Sentry.init({
            dsn: 'https://df1107fcee94474facfd284c301f6bd0@o4505503034376192.ingest.sentry.io/4505503041126400',
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    // tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
                }),
                new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
    if (window.screen.availWidth !== window.document.body.clientWidth) {
        document.body.classList.add('withMouse');
    }
} catch (e) {
    console.error(e);
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(<App />);
