import { useCallback, useEffect, useState } from 'react';
import { getReviewTrigger } from 'api/survey';

const useGetReviewTrigger = ():[{ minimalSurveyScore: number }, boolean | null, string, () => void] => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [data, setData] = useState<{ minimalSurveyScore: number }>({ minimalSurveyScore: 4 });
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getReviewTrigger();

            if (res && !res.error) {
                const reviewTrigger = res?.data?.data;

                setData(reviewTrigger);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetReviewTrigger;
