// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__content--tkh0S {
  width: 92%;
  max-width: var(--content-width);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 72px;
}
.s-module__content--tkh0S .s-module__left--gHdDd {
  width: 300px;
  flex-shrink: 0;
  margin-right: 56px;
}
.s-module__content--tkh0S .s-module__left--gHdDd h2 {
  font-size: 30px;
  line-height: 36px;
  font-weight: bolder;
}
.s-module__content--tkh0S .s-module__left--gHdDd .s-module__p1--yeNGt {
  margin-top: 16px;
  font-size: 16px;
  line-height: 26px;
  color: #6B7280;
}
.s-module__content--tkh0S .s-module__left--gHdDd .s-module__p2--n77Xu a {
  font-size: 16px;
  line-height: 26px;
  margin-top: 16px;
  color: var(--primary-color);
}
.s-module__content--tkh0S .s-module__left--gHdDd .s-module__p2--n77Xu a:hover {
  text-decoration: underline;
}
.s-module__content--tkh0S .s-module__right--hTXT_ {
  flex-grow: 1;
}
.s-module__content--tkh0S .s-module__right--hTXT_ .s-module__item--Ge0k_ {
  margin-bottom: 56px;
}
.s-module__content--tkh0S .s-module__right--hTXT_ .s-module__item--Ge0k_ .s-module__itemTitle--WhT2h {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.s-module__content--tkh0S .s-module__right--hTXT_ .s-module__item--Ge0k_ .s-module__itemContent--qxS1H {
  font-size: 16px;
  line-height: 26px;
  margin-top: 16px;
  color: #6B7280;
}
@media only screen and (max-width: 600px) {
  .s-module__content--tkh0S {
    flex-direction: column;
  }
  .s-module__content--tkh0S .s-module__left--gHdDd {
    width: 100%;
    margin-bottom: 36px;
  }
  .s-module__content--tkh0S .s-module__left--gHdDd h2 {
    font-size: 24px;
    line-height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/QuestionsPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,+BAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAPA;EASI,YAAA;EACA,cAAA;EACA,kBAAA;AACJ;AAZA;EAcM,eAAA;EACA,iBAAA;EACA,mBAAA;AACN;AAjBA;EAoBM,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAN;AAvBA;EA2BM,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,2BAAA;AADN;AA7BA;EAkCM,0BAAA;AAFN;AAhCA;EAuCI,YAAA;AAJJ;AAnCA;EA0CM,mBAAA;AAJN;AAtCA;EA4CQ,eAAA;EACA,iBAAA;EACA,gBAAA;AAHR;AA3CA;EAkDQ,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAJR;AAUA;EACE;IACE,sBAAA;EARF;EAOA;IAGI,WAAA;IACA,mBAAA;EAPJ;EAGA;IAMM,eAAA;IACA,iBAAA;EANN;AACF","sourcesContent":[".content {\n  width: 92%;\n  max-width: var(--content-width);\n  margin: 0 auto;\n  display: flex;\n  flex-direction: row;\n  margin-top: 72px;\n\n  .left {\n    width: 300px;\n    flex-shrink: 0;\n    margin-right: 56px;\n\n    h2 {\n      font-size: 30px;\n      line-height: 36px;\n      font-weight: bolder;\n    }\n\n    .p1 {\n      margin-top: 16px;\n      font-size: 16px;\n      line-height: 26px;\n      color: #6B7280;\n    }\n\n    .p2 a {\n      font-size: 16px;\n      line-height: 26px;\n      margin-top: 16px;\n      color: var(--primary-color);\n    }\n\n    .p2 a:hover {\n      text-decoration: underline;\n    }\n  }\n\n  .right {\n    flex-grow: 1;\n\n    .item {\n      margin-bottom: 56px;\n      .itemTitle {\n        font-size: 18px;\n        line-height: 24px;\n        font-weight: 500;\n      }\n\n      .itemContent {\n        font-size: 16px;\n        line-height: 26px;\n        margin-top: 16px;\n        color: #6B7280;\n      }\n    }\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .content {\n    flex-direction: column;\n    .left{\n      width: 100%;\n      margin-bottom: 36px;\n      h2{\n        font-size: 24px;\n        line-height: 30px\n      }\n    }\n  };\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `s-module__content--tkh0S`,
	"left": `s-module__left--gHdDd`,
	"p1": `s-module__p1--yeNGt`,
	"p2": `s-module__p2--n77Xu`,
	"right": `s-module__right--hTXT_`,
	"item": `s-module__item--Ge0k_`,
	"itemTitle": `s-module__itemTitle--WhT2h`,
	"itemContent": `s-module__itemContent--qxS1H`
};
export default ___CSS_LOADER_EXPORT___;
