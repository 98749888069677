// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--HWmc1 .s-module__title--C2sI0 {
  padding: 10px 0;
  margin-bottom: 16px;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.s-module__wrap--HWmc1 .s-module__subTitle--V7M75 {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.s-module__wrap--HWmc1 .s-module__formItem--uk2A_ {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SalesProviderExp/components/Account/components/ConfirmAccount/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,eAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;AAPA;EAWQ,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADR;AAdA;EAmBQ,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFR","sourcesContent":[".wrap {\n    .title {\n        padding: 10px 0;\n        margin-bottom: 16px;\n        font-size: 30px;\n        font-weight: 600;\n        line-height: 36px;\n        text-align: left;\n    }\n\n    .subTitle {\n        margin-bottom: 16px;\n        font-size: 20px;\n        font-weight: 600;\n        line-height: 24px;\n        text-align: left;\n    }\n\n    .formItem {\n        font-size: 16px;\n        font-weight: 500;\n        line-height: 20px;\n        text-align: left;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--HWmc1`,
	"title": `s-module__title--C2sI0`,
	"subTitle": `s-module__subTitle--V7M75`,
	"formItem": `s-module__formItem--uk2A_`
};
export default ___CSS_LOADER_EXPORT___;
