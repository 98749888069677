import React, { useCallback, useEffect } from 'react';
import { IKlarityProviderProgramEnroll } from 'types/programs';
import { getProgramsByProviderId } from 'api/operation/programs';

const useGetProviderPrograms = (id: number):[IKlarityProviderProgramEnroll[]|undefined, boolean, (id: number)=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<IKlarityProviderProgramEnroll[]>();
    const fetchData = useCallback(async (id: number) => {
        if (loading || !id) return;
        try {
            setLoading(true);
            const result = await getProgramsByProviderId(id);
            if (!result.error) {
                const resData = result?.data?.data || [];

                setItem(resData);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [loading]);

    useEffect(() => {
        fetchData(id);
    }, [id]);

    return [item, loading, fetchData];
};

export default useGetProviderPrograms;
