import React from 'react';
import s from './s.module.less';
import {
    useNavigate,
} from 'react-router-dom';
import { Email } from 'constants/contact';
import { PATH } from 'constants/path';
import GoogleLogin from 'components/GoogleLogin';
import { ENV_VAR, OPS_ENV } from 'constants/access';

export default function LoginPage() {
    const navigate = useNavigate();
    const isOperationPortal = true;

    return (
        <div className={s.wrap}>
            {
                isOperationPortal &&
                <div className={s.opWrap}>
                    <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_klarity_ops_572x184.png" />
                    <h3>Login to the operations portal</h3>
                    <div className={s.googleWrap}>
                        <GoogleLogin
                            isOps
                            onSuccess={() => {
                                localStorage.setItem(ENV_VAR, OPS_ENV);
                                navigate(`${PATH.OPERATION}`);
                            }}
                        />
                    </div>
                    <div className={s.tip}>Need an access? <a href={`mailto:${Email.support}`}>Contact us</a></div>
                </div>
            }
        </div>
    );
}
