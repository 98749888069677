import React from 'react';
// import Logo from 'assets/logo/kiwihealth.png';
import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import Updates from './imgs/updates.svg';
import s from './s.module.less';

const Page = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-white text-gray-700">
            <div className="text-center">
                <div className="flex justify-center items-center">
                    <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
                </div>

                <div className="flex justify-center items-center">
                    <img className="mt-6 mb-6 w-40 h-40" src={Updates} alt="" />
                </div>

                <p className={s.content}>
                    For a more streamlined application process, please open the link using a desktop or laptop browser.
                    This will ensure a better experience.
                </p>

                <a className="mt-6 text-indigo-600" href="https://app.kiwihealth.com" target="_blank" rel="noreferrer">https://app.kiwihealth.com</a>
            </div>
        </div>
    );
};

export default Page;
