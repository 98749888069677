import React from 'react';
import s from './s.module.less';
import { Input } from 'antd';
import Done from 'assets/common/circle.svg';
import Pending from 'assets/common/circle-pending.svg';

const Preview = () => {
    return (
        <div className={s.wrap}>
            <h2>UniProfile is updated in</h2>
            <div className={s.content}>
                <div className={s.left}>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Done} />
                        </div>
                        <div className={s.text}>Basic information</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Done} />
                        </div>
                        <div className={s.text}>Healthcare experience</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Done} />
                        </div>
                        <div className={s.text}>Education</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Done} />
                        </div>
                        <div className={s.text}>Recognition</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Pending} />
                        </div>
                        <div className={s.text}>Practice Info</div>
                    </div>
                    <div className={s.item}>
                        <div className={s.icon}>
                            <img src={Pending} />
                        </div>
                        <div className={s.text}>Profile picture</div>
                    </div>
                </div>
                <div className={s.right}>
                    <h2>Practice info</h2>
                    <div className={s.des}>Kiwi didn’t find your practice info please describe your practice here to move forward. </div>
                    <div className={s.inputWrap}>
                        <Input.TextArea
                            placeholder="Please provide some key details below:
Practice Name
Practice address - where you provided your service to your patients"
                            rows={6}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Preview;
