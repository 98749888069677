// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__content--CtYrn {
  width: 92%;
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 36px 0;
  margin-bottom: 60px;
}
.s-module__modalWrap--Zv8zj {
  margin-top: 36px;
  padding-bottom: 36px;
}
.s-module__modalWrap--Zv8zj p {
  margin-bottom: 24px;
  line-height: 20px;
  font-size: 14px;
}
.s-module__modalWrap--Zv8zj .s-module__selectWrap--XimzH {
  margin-top: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProviderOnboardingPage/s.module.less"],"names":[],"mappings":"AAIA;EACE,UAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AAHF;AAOA;EACE,gBAAA;EACA,oBAAA;AALF;AAGA;EAII,mBAAA;EACA,iBAAA;EACA,eAAA;AAJJ;AAFA;EASI,gBAAA;AAJJ","sourcesContent":[".wrap{\n  \n}\n\n.content{\n  width: 92%;\n  max-width: var(--content-width);\n  margin: 0 auto;\n  padding: 36px 0;\n  margin-bottom: 60px;\n  \n}\n\n.modalWrap{\n  margin-top: 36px;\n  padding-bottom: 36px;\n  p{\n    margin-bottom: 24px;\n    line-height: 20px;\n    font-size: 14px;\n  }\n  .selectWrap{\n    margin-top: 48px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `s-module__content--CtYrn`,
	"modalWrap": `s-module__modalWrap--Zv8zj`,
	"selectWrap": `s-module__selectWrap--XimzH`
};
export default ___CSS_LOADER_EXPORT___;
