import SettingEnumStore from 'store/Enumeration';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import { Meta } from 'types/common';
import { transfer } from 'utils/setting';

const useGetSettingData = (field: EnumFields, asAntdOptions?: boolean): [SettingEnum[] | Meta[] | undefined] => {
    const [getSettingEnum] = SettingEnumStore.useStore();
    const settingEnum = getSettingEnum('data');

    const settingData = settingEnum?.[field];
    if (asAntdOptions && settingData) {
        const data = transfer(settingData);
        const key = field as unknown as EnumFields;

        return [data[key]];
    }

    return [settingData];
};

export default useGetSettingData;
