import React from 'react';
import { providerBaseServiceList, providerChannelServiceList } from 'api/provider';
import { Service } from 'types/common';
import { getUserId } from 'utils/localstore';

let globalData:Service[] = [];

const useService = (channelId?:number):[Service[], boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<Service[]>(globalData);
    const fetchData = async () => {
        const uid = getUserId();
        if (!uid) {
            return;
        }
        try {
            setLoading(true);
            let result;
            if (channelId) {
                result = await providerChannelServiceList(uid, channelId);
            } else {
                result = await providerBaseServiceList(uid!);
            }
            if (!result.error) {
                const data:Service[] = result?.data?.data || [];
                // const filterData = uid ? data.filter((item) => `${item.providerId}` === `${getUserId()}`) : data;
                globalData = data;
                setItems(globalData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || Object.keys(items).length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [items, loading, fetchData];
};

export default useService;
