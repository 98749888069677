import React, { useMemo } from 'react';
import s from './s.module.less';
import { OPERATOR, OPS_ADMIN, Roles, SUPER_ADMIN } from 'constants/access';
import { IRole } from 'types/access';

interface IRoleProp extends IRole {
    style: Record<string, string>
}

interface IProps {
    role?: IRoleProp;
}

const RoleLabel = ({
    role,
}: IProps) => {
    return (
        <div className={`${s.wrap}`} style={role?.style}>{role?.name}</div>
    );
};

export default RoleLabel;
