import React, { useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { ISalesProvider } from 'types/provider';
import { STRIPE_PUBLIC_KEY } from 'constants/stripe';
import { getProviderNameDisplay } from 'utils/provider';
import { TSubscriptionItemFromServer, TSubscriptionSubType } from 'types/subscription';
import { planDesc } from './planDesc';
import { decodeHtmlEntities, loadSalesPresentationUrlInIframe } from 'utils/common';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './form';
import { Elements } from '@stripe/react-stripe-js';
import { ECurrentStep } from 'types/salesProvider';
import { kiwiDefaultMessage } from 'pages/SalesProviderExp/constant';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

// const stripe = Stripe(STRIPE_PUBLIC_KEY);

interface IProps {
    salesDetail?: ISalesProvider;
    setNextButtonCallback: (fn: (e: any) => void) => void;
    onNext: (n: ECurrentStep) => void;
    setShowPaySuccessTip: (s: boolean) => void;
    setSalesDetail: (detail: ISalesProvider) => void;
}

const Checkout = ({
    salesDetail,
    setNextButtonCallback,
    onNext,
    setShowPaySuccessTip,
    setSalesDetail,
}: IProps) => {
    const [message, setMessage] = useState('');
    const plan = salesDetail?.checkOut?.payPlan?.type;

    const planDescription = plan ? (planDesc[plan] || []) : [];

    const subPlanType = useMemo(() => {
        let res = {
            billedType: 'Billed monthly',
            period: 'month',
        };

        switch (salesDetail?.checkOut?.payPlan?.subType) {
            case TSubscriptionSubType.QUARTERLY_BILLING:
                res = {
                    billedType: 'Billed quarterly',
                    period: 'quarter',
                };
                break;
            case TSubscriptionSubType.YEARLY_BILLING:
                res = {
                    billedType: 'Billed yearly',
                    period: 'year',
                };
                break;
            default: res = {
                billedType: 'Billed monthly',
                period: 'month',
            };
        }

        return res;
    }, [salesDetail]);

    // const initStripeElement = useCallback(async (uuid: string, planId: number) => {

    // }, []);

    // useEffect(() => {
    //     if (salesDetail) {
    //         const { linkUuid, checkOut } = salesDetail;
    //         const { payPlan } = checkOut || {};
    //         const { id } = payPlan || {};

    //         if (id) {
    //             initStripeElement(linkUuid, id);
    //         }
    //     }
    // }, [initStripeElement, salesDetail]);

    const { fullName, payPlan = {}, presentationEmbeddedLink } = salesDetail?.checkOut || {};
    const { firstName, lastName, title } = salesDetail?.sales || {};
    const { type, price } = payPlan as TSubscriptionItemFromServer;

    useEffect(() => {
        if (salesDetail && salesDetail.checkOut && salesDetail.checkOut.message) {
            setMessage(salesDetail.checkOut.message);
        } else {
            setMessage(kiwiDefaultMessage);
        }
    }, [salesDetail]);

    return (
        <div className={s.wrap}>
            <div className={s.inner}>
                <div className={s.box}>
                    <div className={s.left}>
                        <div className={s.desc}>
                            <div className={s.title}>Welcome to your next step with Kiwi</div>
                            <div className={s.providerName}>👋 Hello, {fullName}!</div>
                            <div className={s.content} dangerouslySetInnerHTML={{ __html: message ? decodeHtmlEntities(message) : '' }} />
                            <div className={s.salesName}>Warm regards,</div>
                            <div className={s.salesName}>{getProviderNameDisplay({
                                firstName,
                                lastName,
                            })}
                            </div>
                            <div className={s.salesTitle}>{title}</div>
                        </div>
                        <div className={s.planList}>
                            <div className={s.planTitle}>Selected plan</div>
                            <div className={s.planBox}>
                                <div className={s.subTitle}>
                                    <div className={s.label}>{type}</div>
                                    <div className={s.price}>
                                        <span>{`$${(price / 100).toFixed(2)}`}</span>
                                        <span>{`/${subPlanType.period}`}</span>
                                    </div>
                                </div>
                                <div className={s.list}>
                                    {
                                        planDescription.map((item, index) => {
                                            return (
                                                <div key={index} className={s.planItem}>
                                                    <div className={s.checkedIcon} />
                                                    <div className={s.planText}>{item}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={s.right}>
                        <div className={s.paymentFormWrap}>
                            <div className={s.titleBox}>
                                <div className={s.paymentTitle}>Payment detail</div>
                                <div className={s.paymentSubtitle}>
                                    <div className={s.safeIcon} />
                                    <div className={s.paymentSubtitleText}>Guaranteed safe & secure checkout</div>
                                    <div className={s.stripeBrand} />
                                </div>
                            </div>
                            <Elements stripe={stripePromise}>
                                <PaymentForm setSalesDetail={setSalesDetail} setShowPaySuccessTip={setShowPaySuccessTip} salesDetail={salesDetail} setNextButtonCallback={setNextButtonCallback} onNext={onNext} />
                            </Elements>
                        </div>
                    </div>
                </div>
                {
                    presentationEmbeddedLink &&
                    <div className={s.overview}>
                        <div className={s.title}>Overview</div>
                        <div className={s.iframeWrap}>
                            <iframe className={s.iframe} title="overview" src={loadSalesPresentationUrlInIframe(presentationEmbeddedLink)} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Checkout;
