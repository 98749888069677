import React, { useCallback } from 'react';
import { getSalesContractPage } from 'api/operation';
import {
    IGetSalesContractPageDataInput,
    TContractPage,
} from 'types/contractLink';

const useContractLinkPage = (autoLoad?: boolean, props?: IGetSalesContractPageDataInput): [TContractPage, boolean, (p: IGetSalesContractPageDataInput)=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [result, setResult] = React.useState<TContractPage>({} as TContractPage);
    const fetchData = useCallback(async (params: IGetSalesContractPageDataInput = {}) => {
        try {
            setLoading(true);
            const res = await getSalesContractPage(params);
            if (!res.error) {
                const data = res?.data?.data;
                setResult(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }, []);
    React.useEffect(() => {
        if (autoLoad) {
            fetchData(props);
        }
    }, [autoLoad]);

    return [result, loading, fetchData];
};

export default useContractLinkPage;
