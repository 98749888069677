import React from 'react';
import { getChannelDetail } from 'api/operation';
import { ChannelItem } from 'types/common';

const useChannelDetail = (id?:number):[ChannelItem | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<ChannelItem>();
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getChannelDetail(id!);
            if (!result.error) {
                const data:ChannelItem = result?.data?.data;
                setItem(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (id) {
            if (!loading) {
                fetchData();
            }
        }
    }, [id]);

    return [item, loading, fetchData];
};

export default useChannelDetail;
