import { useCallback, useEffect, useState } from 'react';
import { getPFFormData } from 'api/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import { transferProviderToFormData } from 'utils/practiceFront';
import { IProviderStore } from 'types/practiceFront';

const useGetPracticeFrontFormData = ():[IProviderStore | undefined, boolean, () => void] => {
    const [loading, setLoading] = useState(false);
    const [, setProvider] = ProviderStore.useStore();
    const [data, setData] = useState<IProviderStore>();

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await getPFFormData();

        if (!res.error) {
            const pfData = transferProviderToFormData(res.data?.data);
            setProvider('data', pfData);
            setData(pfData);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [data, loading, fetchData];
};

export default useGetPracticeFrontFormData;
