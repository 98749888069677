import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import Signup from './components/Signup';
import ConfirmAccount from './components/ConfirmAccount';
import { EBindType, ECurrentStep } from 'types/salesProvider';
import { ISalesCreateNewAccount, ISalesProvider } from 'types/provider';
import { salesLinkFinallyComplete } from 'api/salesProvider';

interface IProps {
    formId: string;
    salesDetail?: ISalesProvider;
    setCheckoutLoading: (s: boolean) => void;
    setNextButtonDisabled: (s: boolean) => void;
    onNext: (s:ECurrentStep) => void;
    setSalesDetail: (detail: ISalesProvider) => void;
}

const Account = ({
    formId,
    salesDetail,
    setCheckoutLoading,
    setNextButtonDisabled,
    onNext,
    setSalesDetail,
}: IProps) => {
    const bindType = salesDetail?.accountReview?.accountStatus;
    const emailList = salesDetail?.accountReview?.emailList;

    useEffect(() => {
        setNextButtonDisabled(true);
    }, []);

    useEffect(() => {
        if (bindType === EBindType.CREATED) {
            onNext();
        }
    }, [bindType, onNext]);

    const handleFormSubmit = useCallback(async (data: ISalesCreateNewAccount) => {
        if (salesDetail?.linkUuid && bindType) {
            setCheckoutLoading(true);
            try {
                const res = await salesLinkFinallyComplete({
                    ...data,
                    linkUuid: salesDetail.linkUuid,
                    bindType,
                });

                if (res && !res.error) {
                    const detail = res.data.data;
                    setSalesDetail(detail);
                    const step = detail?.currentStep as ECurrentStep || ECurrentStep.CS;
                    onNext?.(step);
                }
            } catch (e) {
                console.error(e);
            }

            setCheckoutLoading(false);
        }
    }, [salesDetail, bindType, setCheckoutLoading, setSalesDetail, onNext]);

    const commonProps = useMemo(() => {
        return {
            formId,
            salesDetail,
            handleFormSubmit,
            emailList,
        };
    }, [emailList, formId, handleFormSubmit, salesDetail]);

    const contentRender = useMemo(() => {
        if (bindType === EBindType.CONFLICT) {
            return <ConfirmAccount {...commonProps} />;
        } else if (bindType === EBindType.NO_CREAT) {
            return <Signup {...commonProps} />;
        }

        return null;
    }, [bindType, commonProps]);
    return (
        <div className={s.wrap}>
            {contentRender}
        </div>
    );
};

export default Account;
