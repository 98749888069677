// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--BUqSR .s-module__imgWrap--INeS0 {
  text-align: center;
}
.s-module__wrap--BUqSR .s-module__imgWrap--INeS0 img {
  margin: 0 auto;
}
.s-module__wrap--BUqSR h2 {
  font-size: 36px;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 36px;
  text-align: center;
}
.s-module__tips--BWdrg,
.s-module__tips2--Z3yaL {
  text-align: center;
  color: var(--gray-700);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 36px;
}
.s-module__tips--BWdrg span,
.s-module__tips2--Z3yaL span {
  color: var(--gray-900);
}
.s-module__tips2--Z3yaL {
  margin-top: 36px;
  margin-bottom: 8px;
}
.s-module__content--cwyP8 {
  margin: 0 auto;
}
.s-module__contact--lRpnH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SignUpPage/components/VerifyEmail/s.module.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;AAAJ;AAFA;EAIM,cAAA;AACN;AALA;EAQI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAGA;;EACE,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAF;AALA;;EAOI,sBAAA;AAEJ;AAEA;EACE,gBAAA;EACA,kBAAA;AAAF;AAGA;EACE,cAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;AAFF","sourcesContent":[".wrap {\n  .imgWrap{\n    text-align: center;\n    img{\n      margin: 0 auto;\n    }\n  }\n  h2 {\n    font-size: 36px;\n    line-height: 40px;\n    margin-top: 24px;\n    margin-bottom: 36px;\n    text-align: center;\n  }\n}\n.tips, .tips2{\n  text-align: center;\n  color: var(--gray-700);\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 36px;\n  span {\n    color: var(--gray-900);\n  }\n}\n\n.tips2{\n  margin-top: 36px;\n  margin-bottom: 8px;\n}\n\n.content{\n  margin: 0 auto;\n}\n\n.contact{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  color: var(--primary-color);\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--BUqSR`,
	"imgWrap": `s-module__imgWrap--INeS0`,
	"tips": `s-module__tips--BWdrg`,
	"tips2": `s-module__tips2--Z3yaL`,
	"content": `s-module__content--cwyP8`,
	"contact": `s-module__contact--lRpnH`
};
export default ___CSS_LOADER_EXPORT___;
