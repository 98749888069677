import { useEffect, useState } from 'react';
import { STEPS } from '../data';
import { EStep, StepValue } from '../types';

const useSetFormId = (step: EStep) => {
    const [formId, setFormId] = useState(STEPS[step] || StepValue.PROFILE);

    useEffect(() => {
        const value = STEPS[step];

        setFormId(value);
    }, [step]);

    return {
        formId,
        setFormId,
    };
};

export default useSetFormId;
