import React from 'react';
import { Button, Input, message } from 'antd';
import { PATH } from 'constants/path';
import {
    useNavigate,
    useLocation } from 'react-router-dom';
import s from './s.module.less';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase } from 'utils/form';
import { Email } from 'constants/contact';
import { savePassword } from 'api/public';
import { PasswordError } from 'types/common';
import { setResetFailEmail } from 'utils/sessionStorage';

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const loca = useLocation();
    const [psw, setPsw] = React.useState('');
    const [pswError, setPswError] = React.useState<PasswordError[]>([]);
    const [loading, setLoading] = React.useState(false);
    const stateParams = loca.state;
    const email = stateParams?.email;
    const code = stateParams?.code;
    const showPwsError = psw && pswError.length > 0;

    const savePasswordFun = async () => {
        setLoading(true);

        const result = await savePassword({
            email,
            code,
            password: psw,
        });
        if (!result.error && result.data?.data) {
            navigate(`/${PATH.PROVIDER_LOGIN}`, { state: { showResetSuccess: true } });
            window.location.reload();
        } else {
            setResetFailEmail(email);
            navigate(`/${PATH.PROVIDER_LOGIN}`);
        }

        setLoading(false);
    };
    return (
        <div className={s.wrap}>
            <div className={s.container}>
                <h2 className={s.headerTitle}>
                    <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" />
                </h2>
                <div className={s.subTitle}>
                    <p>Reset the password</p>
                    <p className={s.for}>for:{email}</p>
                </div>

                <div className={s.form}>
                    <div className={s.label}>New Password</div>
                    <div className={s.value}>
                        <Input.Password
                            value={psw}
                            status={showPwsError ? 'error' : undefined}
                            onChange={(e) => {
                                const val = e.target.value;
                                const error:PasswordError[] = [];
                                if (!hasLowerCase(val)) {
                                    error.push(PasswordError.LOWERCASE);
                                }
                                if (!hasNumber(val)) {
                                    error.push(PasswordError.NUMBER);
                                }
                                if (!hasSpecialCharacter(val)) {
                                    error.push(PasswordError.SPECIAL);
                                }
                                if (!hasUpperCase(val)) {
                                    error.push(PasswordError.UPPERCASE);
                                }
                                if (val.length < 8) {
                                    error.push(PasswordError.MINIMUM);
                                }
                                setPswError(error);
                                setPsw(e.target.value);
                            }}
                            size="large"
                            style={{ marginBottom: '4px' }}
                        />
                    </div>
                    <div className={showPwsError ? `${s.pswError} ${s.pswErrorShow}` : s.pswError}>
                        <div><span className={pswError.includes(PasswordError.LOWERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One lowercase character</div>
                        <div><span className={pswError.includes(PasswordError.SPECIAL) ? `${s.icon} ${s.iconError}` : s.icon} />One special character</div>
                        <div><span className={pswError.includes(PasswordError.UPPERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One uppercase character</div>
                        <div><span className={pswError.includes(PasswordError.MINIMUM) ? `${s.icon} ${s.iconError}` : s.icon} />8 characters minimum</div>
                        <div><span className={pswError.includes(PasswordError.NUMBER) ? `${s.icon} ${s.iconError}` : s.icon} />One number</div>
                    </div>
                    <Button
                        onClick={savePasswordFun}
                        block
                        type="primary"
                        style={{ marginTop: '12px' }}
                        size="large"
                        loading={loading}
                        disabled={pswError.length > 0}
                    >
                        Save
                    </Button>
                </div>

                <div className={s.navTips}>
                    Need help?
                    <a href={`mailto:${Email.support}`}> Contact us</a>
                </div>
            </div>
        </div>
    );
}
