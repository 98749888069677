import { getOnboardingStepInfo } from 'api/ehr';
import { useCallback, useEffect, useState } from 'react';
import { EStepTypes } from 'types/common';
import { IEHROnboardingInfo } from 'types/ehr';

const useGetStepInfo = (stepType: EStepTypes = EStepTypes.EHR_SET_UP):[IEHROnboardingInfo | undefined, boolean, string] => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IEHROnboardingInfo>();
    const [error, setError] = useState('');

    const fetch = useCallback(async (type: EStepTypes) => {
        setLoading(true);

        try {
            const response = await getOnboardingStepInfo(type);

            if (response && !response.error) {
                setData(response.data?.data);
            } else {
                setError(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetch(stepType);
    }, [fetch, stepType]);

    return [data, loading, error];
};

export default useGetStepInfo;
