import React from 'react';
import s from './s.module.less';
import Header from 'components/Header';
import Photo from './saved.png';
import Mail from 'assets/common/Mail.svg';
import { Email } from 'constants/contact';

const Page = () => {
    return (
        <div className={s.wrap}>
            <Header />
            <div className={s.content}>
                <img src={Photo} />
                <div className={s.p1}>
                    Your account application process has been saved!
                </div>
                <div className={s.p2}>
                    Please use the link and your email address to continue your application later.
                </div>
                <div className={s.p3}>
                    Have questions? Speak to our friendly team.
                </div>
                <a className={s.p4} href={`mailto:${Email.support}`}>
                    <img src={Mail} /> <span>{Email.support}</span>
                </a>
            </div>
        </div>
    );
};

export default Page;
