import React, { useCallback, useMemo } from 'react';
import { Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { PATH } from 'constants/path';
import { getUrlParam } from 'utils/common';
import { PRACTICE_FRONT_FROM } from 'constants/common';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const SuccessPage = () => {
    const navigate = useNavigate();
    const from = getUrlParam('from');
    const [data, loadingHome] = useProviderHomeInfo();
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    const klarityStarterSetUpCompleted = !!data?.klarityStarterSetUpCompleted;
    const klarityUser = !!data?.klarityUser;
    const klarityUserWithoutKiwiPlan = data?.klarityUserWithoutKiwiPlan;

    const handleBackToHome = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`, { replace: true });
    }, [navigate]);

    const handleBack = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`, { replace: true });
    }, [navigate]);

    const handleToPFEdit = useCallback(() => {
        navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}`, { replace: true });
    }, [navigate]);

    const handleBackToPF = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
    }, [navigate]);

    const handleToKlairtyProcess = useCallback(() => {
        navigate(`/${PATH.EHR_ONBOARDING}`, { replace: true });
    }, [navigate]);

    const renderButtons = useMemo(() => {
        if (klarityUser && klarityUserWithoutKiwiPlan) {
            return (
                <>
                    <Button type="primary" style={{ marginBottom: '24px' }} onClick={handleToKlairtyProcess}>Set up my practice</Button>
                    <Button type="ghost" style={{color: '#00816B'}} onClick={handleBackToHome}>Back to home</Button>
                </>
            )
        }
        if (!practiceFrontCompleted) {
            return <Button type="primary" style={{ marginBottom: '12px' }} onClick={handleToPFEdit}>Create Practice Front: estimated 2 mins</Button>;
        } else if (from === PRACTICE_FRONT_FROM) {
            return <Button type="primary" ghost onClick={handleBackToPF}>Back to Practice Front page</Button>;
        }

        return <Button type="primary" onClick={handleBack}>Back to Channel page</Button>;
    }, [from, handleBack, handleBackToPF, handleToKlairtyProcess, handleToPFEdit, klarityStarterSetUpCompleted, klarityUser, klarityUserWithoutKiwiPlan, practiceFrontCompleted]);

    const descRender = useMemo(() => {
        if (klarityUser && klarityUserWithoutKiwiPlan) {
            return (
                <>
                    <p className={s.desc}>UniProfile submitted!</p>
                    <p className={s.exp}>🎉 Congratulations on completing your UniProfile!</p>
                    <p className={s.exp}>It will take 3-5 business days for our team to review.</p>
                    <p className={s.exp} style={{marginTop: '38px'}}>Next step:</p>
                    <p className={s.exp} style={{marginBottom: '24px'}}>Set up your practice by setup EHR and selecting the necessary add-ons.</p>
                </>
            )
        }
        return (
            <>
                <p className={s.desc}>UniProfile submission successful!</p>
                <p className={s.exp} style={{marginBottom: '24px'}}>Thank you for submitting your UniProfile. Our team will review your profile within the next 7 days. In the meantime, let’s explore how Kiwi can help you grow your practice by increasing your online visibility.</p>
            </>
        )
    }, [klarityUser, klarityUserWithoutKiwiPlan]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loadingHome}>
                {
                    data &&
                    <div className={s.box}>
                        <div className={`${s.successImage} ${!practiceFrontCompleted ? s.pfSuccess : s.updateSuccess}`} />
                        {
                            !klarityUser && !practiceFrontCompleted ?
                                <>
                                    <p className={s.desc} style={{ width: '75%' }}>Create your own Practice Front to Unlock all channels</p>
                                    <p className={s.exp} style={{ width: '83%' }}>Your UniProfile is ready! To list your practices on channels, just set up and launch your Practice Front - a patient-facing website to boost visibility and brand. Practice Front will be linked to all of your channels managed by Kiwi to convert patient prospects. </p>
                                </> :
                                descRender
                        }
                        {renderButtons}
                    </div>
                }
            </Spin>
        </div>
    );
};

export default SuccessPage;
