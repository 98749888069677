import { useEffect } from 'react';
import { getTheDraftDataKey, getDraftDataPrefixKey } from 'utils/helloklarity';

const useDeleteOldPPPPreviewDraftData = (providerId?: number) => {
  useEffect(() => {
    if (providerId) {
      const currentVersionKey = getTheDraftDataKey(providerId);
      const targetString = getDraftDataPrefixKey(providerId);
  
      // 遍历 localStorage 中的所有键
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        
        // 检查键是否包含目标字符串
        if (key && key.includes(targetString) && key !== currentVersionKey) {
          // 删除包含目标字符串的键
          localStorage.removeItem(key);
        }
      }
    }
  }, []);
}

export default useDeleteOldPPPPreviewDraftData;
