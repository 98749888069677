import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Form, Input, Popconfirm, Typography, message, Table } from 'antd';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { addRole, getRoleMain, updateRoleById } from 'api/access';
import getColumnData from './getColumnData';
import { RoleItem } from 'types/operation';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: string;
    inputType: string;
    record: Record<string, string>;
    index: number;
    options: Array<{ label: string, value: number }>,
    children: React.ReactNode;
    // handleChange: (value: boolean | string, index: number) => void;
}

const EditableCell = ({
    options,
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: EditableCellProps) => {
    const inputNode = inputType === 'checkbox' ? <Checkbox /> : <Input disabled={!!record?.id} />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    className={s.formItem}
                    help=""
                    valuePropName={inputType === 'checkbox' ? 'checked' : 'value'}
                    rules={[
                        {
                            required: inputType !== 'checkbox',
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Roles = () => {
    const [editingKey, setEditingKey] = useState<number | undefined>(-1);
    const { data, loading, run } = useRequest(getRoleMain);
    const [tableData, setTableData] = useState<RoleItem[]>([]);
    const [formInstance] = Form.useForm();
    // 每个叶子阶段在整棵树中的path, 先保留，防止后续用到，暂时无用
    const pathMap = useRef<Record<string, string>>({});
    // 每个叶子节点的id；
    const idMap = useRef<Record<string, number>>({});
    const [originalData, setOriginalData] = useState([]);
    const [columns, setColumns] = useState<unknown[]>([]);
    useEffect(() => {
        const sourceData = data?.data?.data || [];
        setOriginalData(sourceData);
        let currentRowIndex = 0;

        const loop = (listData = [], path = '') => {
            const res = listData?.map((item, index) => {
                const { path: dataIndex, id, checked, resourceDtoList, children, name } = item;
                const newObj:Partial<RoleItem> = {
                    id,
                    name,
                };

                // 最顶层级没有path，将其index作为row index
                if (!path) {
                    currentRowIndex = index;
                }

                // 当前节点的path，使用时候需要再当前节点后面追加当前的节点key，比如currentPath.account,如果是修改值，应该追加checked | name
                const currentPath = path ? `${path}[${index}]` : '';

                if (dataIndex) {
                    newObj[dataIndex] = checked;
                    // 每一条数据都有同样的字段，所以需要加上index才能保证唯一
                    const key = `${currentRowIndex}${dataIndex}`;
                    pathMap.current[key] = currentPath;
                    idMap.current[key] = id;
                }

                const childrenList:any = resourceDtoList || children;

                if (childrenList?.length > 0) {
                    const childrenKeyName = resourceDtoList ? 'resourceDtoList' : 'children';

                    const nextPath = currentPath ? `${currentPath}.${childrenKeyName}` : childrenKeyName;

                    const childrenRes = loop(childrenList, nextPath);

                    childrenRes.forEach((childItem) => {
                        Object.keys(childItem).forEach((key) => {
                            if (key !== 'id' && key !== 'name') {
                                newObj[key] = childItem[key];
                            }
                        });
                    });
                }

                return newObj;
            });

            return res;
        };

        const resp = loop(sourceData);

        setTableData(resp);
    }, [data]);

    const tableIsEditing = useCallback(() => editingKey !== -1, [editingKey]);

    const handleAddRole = useCallback(() => {
        if (tableIsEditing()) {
            message.error('Please save your changes before add new data');
        } else if (tableData[0] && !tableData[0].id) {
            message.error('You must save the previously added role before you add another one');
            setEditingKey(undefined);
            setTimeout(() => {
                formInstance.validateFields();
            }, 100);
        } else {
            formInstance.resetFields();
            const newRowData:RoleItem = {
                name: '',
                providers: false,
                account: false,
                uniprofile: false,
                channels: false,
                channel_store: false,
                channel_listing: false,
                service_template: false,
                invite_member: false,
                add: false,
                super_edit: false,
                admin_edit: false,
                operator_edit: false,
                web_app_dev: false,
            };

            setTableData([newRowData].concat(tableData));
            setEditingKey(undefined);
        }
    }, [formInstance, tableData, tableIsEditing]);

    const handleEdit = useCallback((item:RoleItem) => {
        if (!tableIsEditing()) {
            formInstance.setFieldsValue(item);
            setEditingKey(item?.id);
        } else {
            message.error('Please save your changes before proceeding to edit another section');
        }
    }, [formInstance, tableIsEditing]);

    const isEditingRow = useCallback((record:RoleItem) => {
        return record.id === editingKey;
    }, [editingKey]);

    const submit = useCallback(async (record:unknown, rowIndex: number) => {
        const row = (await formInstance.validateFields());
        const { id } = record;

        let res = {
            error: '',
        };

        const resourceIdList: Array<number> = [];
        Object.keys(row).forEach(async (key) => {
            if (key !== 'name') {
                const value = row[key];
                const leafId = idMap.current[`${rowIndex}${key}`];

                if (leafId && value) {
                    resourceIdList.push(leafId);
                }
            }
        });

        if (id) {
            res = await updateRoleById(id, {
                id,
                name: row.name,
                resourceIdList,
            });
        } else {
            res = await addRole({
                name: row.name,
                resourceIdList,
            });
        }

        if (!res?.error) {
            run();
            setEditingKey(-1);
        } else {
            message.error(res.error);
        }
    }, [formInstance, run]);

    const handleCancelEdit = useCallback(() => {
        setEditingKey(-1);
    }, []);

    useEffect(() => {
        const columnRoleName:any = {
            title: () => {
                return (
                    <div className={s.columnName}>
                        Role
                    </div>
                );
            },
            dataIndex: 'name',
            filterSearch: true,
            width: '150px',
            editable: true,
            fixed: 'left',
        };

        const columnAction = {
            title: '',
            width: '80px',
            className: s.tableColumn,
            editable: false,
            fixed: 'right',
            render: (item:RoleItem, row:unknown, rowIndex:number) => {
                const editable = isEditingRow(item);

                return (
                    <div className={s.actionCell}>
                        {
                            editable ? (
                                <span>
                                    <Typography.Link onClick={() => submit(row, rowIndex)} className={s.actionBtn}>
                                        Save
                                    </Typography.Link>
                                    <Popconfirm title="Confirm to cancel?" onConfirm={handleCancelEdit}>
                                        <a className={s.cancelBtn}>Cancel</a>
                                    </Popconfirm>
                                </span>
                            ) : (
                                <Typography.Link className={s.actionBtn} onClick={() => handleEdit(item)}>
                                    Edit
                                </Typography.Link>
                            )
                        }
                    </div>
                );
            },
        };
        const columnsData = getColumnData(data?.data?.data?.[0]?.resourceDtoList || []);

        columnsData.unshift(columnRoleName);
        columnsData?.push(columnAction);

        setColumns(columnsData);
    }, [data, handleCancelEdit, handleEdit, isEditingRow, submit]);

    const loopMergeColumns = useCallback((cols: unknown[]) => {
        return cols.map((col:any) => {
            if (!col.editable) {
                return col;
            }
            if (col.children) {
                return {
                    ...col,
                    children: loopMergeColumns(col.children),
                };
            }
            return {
                ...col,
                width: '200px',
                onCell: (record:RoleItem) => {
                    return ({
                        record,
                        inputType: col.dataIndex === 'name' ? 'text' : 'checkbox',
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: isEditingRow(record),
                    });
                },
            };
        });
    }, [isEditingRow]);

    // const mergedColumns =

    return (
        <div className={s.wrap}>
            <div className={s.title}>ROLES</div>
            <div className={s.inviteBar}>
                <Button type="primary" onClick={handleAddRole}>Add a new role</Button>
            </div>
            <div className={s.tableWrap}>
                <Form form={formInstance} component={false}>
                    <Table
                        rowKey={(record:any) => record.id || record.name}
                        pagination={false}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        scroll={{ x: '1800' }}
                        columns={loopMergeColumns(columns)}
                        dataSource={tableData}
                        bordered
                        loading={loading}
                    />
                </Form>
            </div>
        </div>
    );
};

export default Roles;
