import { useCallback, useEffect, useState } from 'react';
import { getResumeInfo } from 'api/uniprofileResume';
import uniprofileResumeStore from 'store/UniprofileResume';
import channelProviderStore from 'store/ChannelApplicationProvider';
import { UniprofileFormData } from 'types/provider';
import { transferProviderToFormData } from 'utils/channel';
import useSetPercentages from './useSetPercentages';

const useGetUniprofileResumeInfo = (documentId?:string):[UniprofileFormData | undefined, boolean | null, string, (id?: string) => void] => {
    const [, setResumeInfo] = uniprofileResumeStore.useStore();
    const [, setProvider] = channelProviderStore.useStore();
    const [data, setData] = useState<UniprofileFormData>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [setPercentage] = useSetPercentages();

    const fetchData = async (id?: string) => {
        setLoading(true);
        try {
            const res = await getResumeInfo({
                documentId: id,
            });
            if (res && !res.error) {
                const resultData = transferProviderToFormData(res.data?.data);

                setResumeInfo('data', resultData);
                setProvider('data', resultData!);
                setData(resultData);
                setPercentage(resultData);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (documentId) {
            fetchData(documentId);
        }
    }, [documentId]);

    return [data, loading, error, fetchData];
};

export default useGetUniprofileResumeInfo;
