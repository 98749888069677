import { useEffect, useState } from 'react';
import { getAirtableUserDetail } from 'api/operation';
import { TAirtableProvider } from 'types/common';

interface IParams {
    providerId?: string;
    //isOps?:boolean;
}

const useAirtableProviderDetail = (providerId:string):{ data?: TAirtableProvider, refetch:(id:string)=>void, loading: boolean } => {
    const [data, setData] = useState<TAirtableProvider>();
    const [loading, setLoading] = useState(false);
    const fetchData = async (id:string) => {
        if (!id) {
            return;
        }
        setLoading(true);
        const res = await getAirtableUserDetail(id);
        if (!res.error) {
            setData(res?.data?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!providerId) return;
        setData(undefined);
        fetchData(providerId);
    }, [providerId]);

    return { data, loading, refetch: fetchData };
};

export default useAirtableProviderDetail;
