// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--vT52Y h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}
.s-module__wrap--vT52Y .s-module__subTitle--I1jUV {
  color: var(--gray-600);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 8px;
}
.s-module__wrap--vT52Y .ant-form-item-required::after {
  display: none !important;
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM {
  background: var(--gray-50);
  padding: 28px 28px 1px 28px;
  margin-top: 16px;
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM .s-module__module--Uje8G {
  background: #fff;
  margin-bottom: 28px;
  padding: 12px 24px;
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM .s-module__module--Uje8G h3 {
  color: var(--gray-800);
  font-size: 20px;
  line-height: 44px;
  font-weight: 500;
  border-bottom: 1px solid var(--bottom-line-color);
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM .s-module__module--Uje8G .s-module__row--e7_t7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM .s-module__module--Uje8G .s-module__rowItem--kqF8u {
  margin-right: 36px;
}
.s-module__wrap--vT52Y .s-module__contentInner--QoqnM .s-module__module--Uje8G .s-module__colItem--BdjPP {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProviderOnboardingPage/components/Profile/s.module.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,iBAAA;EACA,iBAAA;AAAJ;AAJA;EAOI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAXA;EAeM,wBAAA;AADN;AAdA;EAmBI,0BAAA;EACA,2BAAA;EACA,gBAAA;AAFJ;AAnBA;EAuBM,gBAAA;EACA,mBAAA;EACA,kBAAA;AADN;AAxBA;EA2BQ,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,iDAAA;AAAR;AA/BA;EAkCQ,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAAR;AArCA;EAwCQ,kBAAA;AAAR;AAxCA;EA2CQ,aAAA;EACA,sBAAA;AAAR","sourcesContent":[".wrap{\n  h2 {\n    font-size: 24px;\n    line-height: 28px;\n    font-weight: bold;\n  }\n  .subTitle{\n    color: var(--gray-600);\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 28px;\n    margin-top: 8px;\n  }\n  :global{\n    .ant-form-item-required::after{\n      display: none !important;\n    }\n  }\n  .contentInner{\n    background: var(--gray-50);\n    padding: 28px 28px 1px 28px;\n    margin-top: 16px;\n    .module{\n      background: #fff;\n      margin-bottom: 28px;\n      padding: 12px 24px;\n      h3{\n        color: var(--gray-800);\n        font-size: 20px;\n        line-height: 44px;\n        font-weight: 500;\n        border-bottom: 1px solid var(--bottom-line-color);\n      }\n      .row{\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        margin-top: 16px;\n      }\n      .rowItem{\n        margin-right: 36px;\n      }\n      .colItem{\n        display: flex;\n        flex-direction: column;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--vT52Y`,
	"subTitle": `s-module__subTitle--I1jUV`,
	"contentInner": `s-module__contentInner--QoqnM`,
	"module": `s-module__module--Uje8G`,
	"row": `s-module__row--e7_t7`,
	"rowItem": `s-module__rowItem--kqF8u`,
	"colItem": `s-module__colItem--BdjPP`
};
export default ___CSS_LOADER_EXPORT___;
