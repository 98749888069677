import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './s.module.css';
import { isOpPortal } from 'utils/common';
import SignPage from 'pages/SignUpPage';
import LoginPage from 'pages/LoginPage';
import { getHasOnceSuccessLogin } from 'utils/localstore';
import ProviderLoginPage from 'pages/ProviderLoginPage';

const Home = () => {
    const navigate = useNavigate();

    // When the user enters the boxpratice home page, it is necessary to check whether the user is logged in or not.
    // useEffect(() => {
    //     // if (!getUserId()) {
    //     //     navigate(PATH.SIGNUP);
    //     // }
    //     navigate(PATH.SIGNUP);
    // }, []);

    if (isOpPortal()) {
        return (
            <div className={s.wrap}>
                <LoginPage />
            </div>
        );
    }

    if (getHasOnceSuccessLogin()) {
        return (
            <div className={s.wrap}>
                <ProviderLoginPage />
            </div>
        );
    }

    return (
        <div className={s.wrap}>
            <SignPage />
        </div>
    );
};

export default Home;
