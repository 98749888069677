import { useCallback, useEffect, useState } from 'react';
import { getAddOnDetail } from 'api/operation';
import { transferProviderToFormData } from 'utils/channel';
import { IEHRProviderInfo } from 'types/ehr';
import { truncate } from 'fs';

const useGetAddOnDetail = (providerId?: string): [IEHRProviderInfo | undefined, boolean, string, (id?: string) => void] => {
    const [addOnDetail, setAddOnDetail] = useState<IEHRProviderInfo>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const fetch = useCallback(async (id?: string) => {
        if (!id) {
            return;
        }
        setLoading(true);
        try {
            const result = await getAddOnDetail(id);
            if (result && !result.error && result?.data?.data) {
                setAddOnDetail({
                    ...transferProviderToFormData(result.data.data),
                    efaxInfo: result.data.data.faxInfo || {},
                    addonPhysicianList: result.data.data.addonPhysicianList,
                    addonList: result.data.data.addonList,
                    assistantList: result.data.data.assistantList,
                    hasEhrAccountPwd: result.data.data.hasEhrAccountPwd,
                });
            } else {
                setError(result?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (providerId) {
            fetch(providerId);
        }
    }, [fetch, providerId]);

    return [addOnDetail, loading, error, fetch];
};

export default useGetAddOnDetail;
