import { getChannelDetailById, submitRecommandChannels } from 'api/channel';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, SINGLE_TYPE_SINGLE_SERVICE } from 'constants/common';
import { useCallback, useEffect, useState } from 'react';
import useService from './useService';
import { Service } from 'types/common';
import { useNavigate } from 'react-router-dom';
import useGetRecommendChannels from './useGetRecommendChannels';
import { PATH } from 'constants/path';
import { ProviderChannelService } from 'types/channelApplicationProvider';
import { NoticeType } from 'antd/es/message/interface';
import isEmpty from 'lodash/isEmpty';

interface IProps {
    channelName?: string;
    services: Array<ProviderChannelService>;
    channelId: number;
    submitWithoutSingleServiceSelect: boolean;
    successPagePath?: string;
    onShowMessage: (type:NoticeType, message: string) => void;
    onShowSelectionModal: (show: boolean) => void;
    from?: string;
    IgnoreMultipleServiceSelect?: boolean;
    isKlarityPlan?: boolean;
}

const useListToChannel = ({
    IgnoreMultipleServiceSelect,
    services,
    channelId,
    submitWithoutSingleServiceSelect,
    onShowMessage,
    onShowSelectionModal,
    successPagePath = PATH.CHANNEL_APPOINTMENT_SUCCESS,
    from,
    channelName,
    isKlarityPlan,
}: IProps) => {
    const navigate = useNavigate();
    const [allServices] = useService();
    const [loading, setLoading] = useState(false);

    const [singleServiceTypeIds, setSingleServiceTypeIds] = useState<Array<number>>([]);
    const [otherServiceList, setOtherServiceList] = useState<Array<Service>>([]);

    const [recommendChannelsData, isNonRecommandChannels] = useGetRecommendChannels();

    useEffect(() => {
        const singleServiceTypes = singleServiceTypeIds.map((id) => {
            const serv = services.find((e) => e.id === id);

            return serv?.type;
        });
        setOtherServiceList(allServices.filter((service) => !singleServiceTypes.includes(service.type)));
    }, [allServices, services, singleServiceTypeIds]);

    const handleSingleServiceTypeSelected = useCallback((ids: Array<number>) => {
        setSingleServiceTypeIds(ids);
    }, []);

    const navigateToRecommendChannelsPage = useCallback(() => {
        sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);
        const recomendChannelUrlSearchObj: Record<string, string> = {};
        let searchString = '';
        const recommendPath = PATH.RECOMMEND_CHANNELS;

        if (from) {
            recomendChannelUrlSearchObj.from = from;
        }
        if (channelName) {
            recomendChannelUrlSearchObj.channelName = channelName;
        }

        if (channelId) {
            recomendChannelUrlSearchObj.channelId = channelId.toString();
        }

        if (!isEmpty(recomendChannelUrlSearchObj)) {
            searchString = new URLSearchParams(recomendChannelUrlSearchObj).toString();
        }
        if (searchString) {
            navigate(`/${recommendPath}?${searchString}`);
        } else {
            navigate(`/${recommendPath}`);
        }
    }, [from, channelName, channelId, navigate]);

    const navigateToChannelSuccessPage = useCallback(() => {
        sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);

        navigate(`/${successPagePath}`);
    }, [navigate, successPagePath]);

    const handleFinalSubmit = useCallback(async () => {
        let multServiceList = allServices;
        if (singleServiceTypeIds.length > 0) {
            const singleSerivce = singleServiceTypeIds.map((id) => {
                const service = allServices.find((serv) => serv.id === id) as Service;

                return service;
            });

            multServiceList = [...otherServiceList, ...singleSerivce];
        }

        try {
            const res = await submitRecommandChannels({
                channelIdList: [channelId],
                multServiceList,
            });

            if (res && !res.error) {
                return true;
            } else {
                onShowMessage('error', res.error);
                return false;
            }
        } catch (e) {
            console.error(e);

            return false;
        }
    }, [allServices, channelId, onShowMessage, otherServiceList, singleServiceTypeIds]);

    const handleServiceIsSingle = useCallback(async () => {
        const channelDetailRes = await getChannelDetailById(channelId.toString());

        if (!channelDetailRes.error) {
            const { data } = channelDetailRes.data;

            if (!IgnoreMultipleServiceSelect && data.serviceConfig === SINGLE_TYPE_SINGLE_SERVICE && singleServiceTypeIds.length === 0) {
                onShowSelectionModal(true);
            } else {
                const res = await handleFinalSubmit();

                return !!res;
            }
        } else {
            onShowMessage('error', channelDetailRes.error);
        }
    }, [channelId, handleFinalSubmit, onShowMessage, onShowSelectionModal, singleServiceTypeIds, IgnoreMultipleServiceSelect]);

    const handleChannelConfirmSubmit = useCallback(async () => {
        setLoading(true);
        // 先处理自己的提交
        const isSubmitted = await handleServiceIsSingle();
        setLoading(false);

        if (isSubmitted) {
            // 然后确定是否需要跳去recommend channel page
        // 如果 recommend channel 为空
            if (isNonRecommandChannels || isKlarityPlan) {
                navigateToChannelSuccessPage();
            } else if (recommendChannelsData.length === 1 && recommendChannelsData[0].id === channelId) {
                // 如果recommend channel 只有一个，且是当前的channel，直接提交
                navigateToChannelSuccessPage();
            } else {
                navigateToRecommendChannelsPage();
            }
        }
    }, [channelId, handleServiceIsSingle, isKlarityPlan, isNonRecommandChannels, navigateToChannelSuccessPage, navigateToRecommendChannelsPage, recommendChannelsData]);

    const handleModalOk = useCallback(async () => {
        if (singleServiceTypeIds.length > 0 || submitWithoutSingleServiceSelect) {
            setLoading(true);
            const res = await handleFinalSubmit();
            if (res) {
                onShowSelectionModal(false);
                navigateToChannelSuccessPage();
            }
            setLoading(false);
        } else {
            onShowMessage('error', 'Please select one from duplicate service-type');
        }
    }, [handleFinalSubmit, navigateToChannelSuccessPage, onShowMessage, onShowSelectionModal, singleServiceTypeIds, submitWithoutSingleServiceSelect]);

    return { handleChannelConfirmSubmit, handleModalOk, singleServiceTypeIds, handleSingleServiceTypeSelected, loading };
};

export default useListToChannel;
