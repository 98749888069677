// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__toolWrap--UaDwh {
  max-width: 300px;
  color: var(--gray-500, #6B7280);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  min-width: 80px;
  padding: 0 16px;
}
.s-module__footer--ZViJ4 {
  flex-shrink: 0;
  width: 100%;
  border-top: 1px solid #eee;
  background: #ffffff;
  z-index: 9;
}
.s-module__footer--ZViJ4 .s-module__footerInner--mWGCT {
  padding: 0 24px;
  margin: 0 auto;
  line-height: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__footer--ZViJ4 .s-module__back--m9GCa {
  color: var(--primary-color);
  margin-left: 24px;
  cursor: pointer;
  font-weight: 500;
}
.s-module__footer--ZViJ4 .s-module__back--m9GCa:hover {
  text-decoration: underline;
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateUniProfilePage/components/Footer/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AACF;AACA;EACE,cAAA;EACA,WAAA;EACA,0BAAA;EACA,mBAAA;EACA,UAAA;AACF;AANA;EAQI,eAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAhBA;EAkBI,2BAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAtBA;EAwBI,0BAAA;EACA,YAAA;AACJ","sourcesContent":[".toolWrap {\n  max-width: 300px;\n  color: var(--gray-500, #6B7280);\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n  min-width: 80px;\n  padding: 0 16px;\n}\n.footer{\n  flex-shrink: 0;\n  width: 100%;\n  border-top: 1px solid #eee;\n  background: rgba(255, 255, 255, 1);\n  z-index: 9;\n\n  .footerInner{\n    padding: 0 24px;\n    margin: 0 auto;\n    line-height: 60px;\n    height: 60px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .back{\n    color: var(--primary-color);\n    margin-left: 24px;\n    cursor: pointer;\n    font-weight: 500;\n  }\n  .back:hover{\n    text-decoration: underline;\n    opacity: 0.7;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolWrap": `s-module__toolWrap--UaDwh`,
	"footer": `s-module__footer--ZViJ4`,
	"footerInner": `s-module__footerInner--mWGCT`,
	"back": `s-module__back--m9GCa`
};
export default ___CSS_LOADER_EXPORT___;
