import { useCallback, useEffect, useState } from 'react';
import { getReviewChannelList } from 'api/survey';
import { Channel } from 'types/common';

const useGetReviewChannelList = ():[Channel[] | undefined, boolean | null, string, () => void] => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [data, setData] = useState<Channel[]>();
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getReviewChannelList();

            if (res && !res.error) {
                const channels = res?.data?.data;

                setData(channels);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetReviewChannelList;
