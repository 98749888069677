import React from 'react';
import { getSpecialityPrice } from 'api/klarityService';
import { getOpsSpecialityPrice } from 'api/operation';
import { EServiceConfigPriceType, KlarityServicePrice, TConfigTypePrice } from 'types/common';

let globalData: TConfigTypePrice = {
    [EServiceConfigPriceType.KRC]: [],
    [EServiceConfigPriceType.MARKETING_MARKUP]: [],
    [EServiceConfigPriceType.PLATFORM_FEE]: [],
};

const useKlarityServicePrice = (specialty?: string, stateContent?: string[], isOps?: boolean, providerId?: number): [TConfigTypePrice, boolean, () => void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TConfigTypePrice>(globalData);
    const [init, setInit] = React.useState(false);
    const fetchData = async () => {
        if (loading) {
            return;
        }
        setInit(true);
        try {
            setLoading(true);
            let result: any;
            if (isOps && providerId) {
                result = await getOpsSpecialityPrice(specialty!, stateContent || [], providerId);
            } else {
                result = await getSpecialityPrice(specialty!, stateContent || []);
            }
            if (!result.error) {
                const data: KlarityServicePrice[] = result?.data?.data || [];
                const krc = data.filter((item) => item.configType === EServiceConfigPriceType.KRC);
                const mmu = data.filter((item) => item.configType === EServiceConfigPriceType.MARKETING_MARKUP);
                const pf = data.filter((item) => item.configType === EServiceConfigPriceType.PLATFORM_FEE);
                const newData = {
                    [EServiceConfigPriceType.KRC]: krc || [],
                    [EServiceConfigPriceType.MARKETING_MARKUP]: mmu || [],
                    [EServiceConfigPriceType.PLATFORM_FEE]: pf || [],
                };
                globalData = newData;
                setItems(newData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    const length = stateContent?.length || 0;
    React.useEffect(() => {
        setInit(false);
    }, [length]);
    React.useEffect(() => {
        if (specialty && stateContent && stateContent.length > 0 && !init) {
            fetchData();
        }
    }, [specialty, stateContent, init]);

    return [items, loading, fetchData];
};

export default useKlarityServicePrice;
