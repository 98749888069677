import { getCCCList } from 'api/ccc';
import { useCallback, useEffect, useState } from 'react';
import { ICCCItem } from 'types/ccc';
import CCCStore from 'store/CCC';
import { sortByPriority } from 'utils/ccc';

const useGetCCCList = ():[ICCCItem[] | undefined, boolean, string, () => void] => {
    const [, setCCCStore] = CCCStore.useStore();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ICCCItem[]>();
    const [error, setError] = useState('');
    const [fetched, setFetched] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getCCCList();

            if (res && !res.error) {
                const respData = res.data?.data || [];
                const sortedData = sortByPriority(respData);
                setData(sortedData);
                setCCCStore('cccList', sortedData);
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setFetched(true);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!fetched) {
            fetch();
        }
    }, [fetched]);

    return [data, loading, error, fetch];
};

export default useGetCCCList;
