import React from 'react';
import {
    getProspectList,
} from 'api/prospect';
import Store from './store';
import { EProspectType, Prospect } from 'types/common';

let loading = false;

const useMyPatientList = ():[Prospect[], boolean, ()=>void] => {
    const [getModal, setModal] = Store.useStore();
    const fetchData = async () => {
        try {
            loading = true;
            setModal('loading', true);
            const result = await getProspectList();

            if (!result.error) {
                let data = result?.data?.data || [];
                data = data.filter((item: Prospect) => item.type === EProspectType.PATIENT);
                setModal('data', data);
            }
            loading = false;
            setModal('loading', false);
        } catch (e) {
            console.error(e);
            loading = false;
            setModal('loading', false);
        }
    };
    React.useEffect(() => {
        if (!getModal('data') || getModal('data')?.length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [getModal('data') || [], getModal('loading') || false, fetchData];
};

export default useMyPatientList;
