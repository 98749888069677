import React from 'react';
import { getProviderKlarityServiceList } from 'api/klarityService';
import { getOpsProviderKlarityServiceList } from 'api/operation';
import { TKlarityServiceServerTableItem } from 'types/common';

const useProviderKlarityServiceList = (isOps?: boolean, providerId?: number):[TKlarityServiceServerTableItem[] | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<TKlarityServiceServerTableItem[]>();
    const fetchData = async () => {
        try {
            setLoading(true);
            let result:any;
            if (isOps && providerId) {
                result = await getOpsProviderKlarityServiceList(providerId);
            } else {
                result = await getProviderKlarityServiceList();
            }
            if (!result.error) {
                const data:TKlarityServiceServerTableItem[] = result?.data?.data || [];
                setItem(data);
            } else {
                setItem([]);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [item, loading, fetchData];
};

export default useProviderKlarityServiceList;
