import React from 'react';
import { Prospect } from 'types/common';
import { getProspectDetail } from 'api/prospect';

const useProspectInfo = (id?:number):[Prospect | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [item, setItem] = React.useState<Prospect>();
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getProspectDetail(id!);
            if (!result.error) {
                const { data } = result.data;
                setItem(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading && id) {
            fetchData();
        }
    }, [id]);

    return [item, loading, fetchData];
};

export default useProspectInfo;
