import { getOpsUserPermissions } from 'api/operation';
import { getHomeInfo } from 'api/provider';
import { PATH, loginPages } from 'constants/path';
import { useEffect, useState } from 'react';
import { isOpPortal } from 'utils/common';
import { redirecToLogout } from 'utils/redirecToLogout';

const useTryToLoad = ():[boolean | undefined] => {
    const [loading, setLoading] = useState<boolean>();
    useEffect(() => {
        const tryTofetchProvider = async () => {
            setLoading(true);
            const isOPS = isOpPortal();
            let result;
            if (isOPS) {
                result = await getOpsUserPermissions();
            } else {
                result = await getHomeInfo();
            }
            setLoading(false);
            if (!result.error && ['/', '', PATH.PROVIDER_LOGIN, PATH.LOGIN, PATH.SIGNUP].includes(window.location.pathname) && window) {
                const { origin } = window.location;
                if (isOPS) {
                    if (window.navigate) {
                        window.navigate(`${PATH.OPERATION}`);
                        return;
                    }
                    window.location.href = `${origin}${PATH.OPERATION}`;
                } else {
                    if (window.navigate) {
                        window.navigate(`${PATH.DASHBOARD}`);
                        return;
                    }
                    window.location.href = `${origin}${PATH.DASHBOARD}`;
                }
            } else if (result?.errorCode >= 4000 && result?.errorCode < 5000) {
                redirecToLogout();
            }

            // 延时去除loading的原因是，防止url跳转还未生效，优先渲染了当前路由
            // 情况：比如ops端，/路由，会先闪现一下登录页，然后跳去/operation
            // setTimeout(() => {
            //     setLoading(false);
            // }, 500);
        };

        // login，signup页面，需要立马将loading转为false，让app.tsx正常渲染页面
        if (!loginPages.includes(window?.location?.pathname)) {
            tryTofetchProvider();
        } else {
            setLoading(false);
        }
    }, []);

    return [loading];
};

export default useTryToLoad;
