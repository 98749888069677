import { useEffect, useState } from 'react';
import { getReputationStatus } from 'api/survey';

const useGetProviderReputationStatus = ():[boolean | null, boolean, string] => {
    const [hasConfig, setHasConfig] = useState<boolean | null>(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fetch = async () => {
        setLoading(true);
        try {
            const res = await getReputationStatus();

            if (res && !res.error) {
                setHasConfig(!!(res.data?.data));
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    return [hasConfig, loading, error];
};

export default useGetProviderReputationStatus;
