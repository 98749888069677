import { getProviderPaidPlanDetails } from 'api/subscription';
import { useCallback, useEffect, useState } from 'react';
import { IPaymentInfo } from 'types/payment';

const useGetProviderPlanDetail = ():[IPaymentInfo | undefined, boolean, string] => {
    const [data, setData] = useState<IPaymentInfo>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getProviderPaidPlanDetails();
            setLoading(false);

            if (res && res.error) {
                setError(res.error);
            } else {
                setData(res?.data?.data);
            }
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    }, []);
    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error];
};

export default useGetProviderPlanDetail;
