import React from 'react';
import { Meta } from 'types/common';
import useSettings from './useSettings';
import { EnumFields } from 'types/enumerationData';
import { getBrowserTimezone, getBrowserTimezoneOffset } from 'utils/common';
import { providerAvailabilityList } from 'api/appointmentSetting';
import { getUserId } from 'utils/localstore';

let globalMySettingTimezone:string = '';

const useTimezone = ():[Meta[], Meta, boolean] => {
    const [setting, loading] = useSettings();
    const [loadingMySettingTimezone, setLoadingMySettingTimezone] = React.useState(false);
    const [mySettingTimezone, setMySettingTimezone] = React.useState(globalMySettingTimezone);
    const timeZoneList = setting?.[EnumFields.TIMEZONE] || [
    ];
    const timezoneOffset = getBrowserTimezoneOffset();
    const isChina = timezoneOffset === '+08';
    const data = [...timeZoneList];

    const fetchData = async () => {
        try {
            setLoadingMySettingTimezone(true);
            const uid = getUserId();
            const result = await providerAvailabilityList(uid!);
            if (!result.error) {
                const timezone:string = result?.data?.data?.timezone;
                globalMySettingTimezone = timezone;
                setMySettingTimezone(globalMySettingTimezone);
            }
            setLoadingMySettingTimezone(false);
        } catch (e) {
            console.error(e);
            setLoadingMySettingTimezone(false);
        }
    };
    React.useEffect(() => {
        if (!globalMySettingTimezone) {
            fetchData();
        }
    }, []);
    if (isChina) {
        data.push({
            value: 'Asia/Shanghai',
            label: '(UTC+08:00) China Time Zone',
        });
    }
    let myTimezone;
    if (mySettingTimezone) {
        myTimezone = data.find((item:Meta) => {
            return (item.value as string).toLowerCase() === mySettingTimezone.toLowerCase();
        });

        if (!myTimezone) {
            myTimezone = data.find((item:Meta) => {
                return item.label.includes(timezoneOffset);
            });
        }
    }

    if (!myTimezone) {
        myTimezone = {
            label: getBrowserTimezone(),
            value: getBrowserTimezone(),
        };
    }

    return [data, myTimezone, loading || loadingMySettingTimezone];
};

export default useTimezone;
