// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--SH2ZK {
  min-height: calc(100vh - 148px);
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  color: var(--gray-900);
  padding: 36px 0;
}
.s-module__item--U9J1m h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 36px;
}
.s-module__item--U9J1m .s-module__subTitle--I8b65 {
  color: #6B7280;
  font-size: 18px;
  line-height: 32px;
  max-width: 80%;
}
.s-module__item--U9J1m .s-module__uploadWrap--awv5M {
  max-width: 80%;
  margin-top: 36px;
}
.s-module__item--U9J1m .s-module__text1--kDsWo {
  margin-top: 36px;
}
.s-module__item--U9J1m .s-module__text2--zO3wB {
  margin-top: 8px;
}
.s-module__item--U9J1m .s-module__text1--kDsWo,
.s-module__item--U9J1m .s-module__text2--zO3wB {
  color: var(--gray-500);
  font-size: 14px;
  line-height: 20px;
}
.s-module__item--U9J1m .s-module__link--RXfvG {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.s-module__item--U9J1m .s-module__link--RXfvG:hover {
  text-decoration: underline;
}
.s-module__inputWrap--jV_0I {
  margin-top: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/InitUniProfilePage/components/Init/s.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,eAAA;AACF;AAEA;EAEI,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAJA;EASI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAVA;EAgBI,cAAA;EACA,gBAAA;AAHJ;AAdA;EAoBI,gBAAA;AAHJ;AAjBA;EAuBI,eAAA;AAHJ;AApBA;;EA0BI,sBAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AA1BA;EA+BI,2BAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAFJ;AAhCA;EAqCI,0BAAA;AAFJ;AAKA;EACE,gBAAA;AAHF","sourcesContent":[".wrap {\n  min-height: calc(100vh - 148px);\n  display: flex;\n  justify-content: center;\n  width: 80%;\n  max-width: 900px;\n  margin: 0 auto;\n  flex-direction: column;\n  color: var(--gray-900);\n  padding: 36px 0;\n}\n\n.item {\n  h2 {\n    font-weight: bold;\n    font-size: 36px;\n    line-height: 40px;\n    margin-bottom: 36px;\n  }\n\n  .subTitle {\n    color: #6B7280;\n    font-size: 18px;\n    line-height: 32px;\n    max-width: 80%;\n  }\n\n  .uploadWrap {\n    max-width: 80%;\n    margin-top: 36px;\n  }\n  .text1{\n    margin-top: 36px;\n  }\n  .text2{\n    margin-top: 8px;\n  }\n  .text1,.text2 {\n    color: var(--gray-500);\n    font-size: 14px;\n    line-height: 20px;\n  }\n  .link{\n    color: var(--primary-color);\n    font-size: 14px;\n    line-height: 20px;\n    cursor: pointer;\n  }\n  .link:hover{\n    text-decoration: underline;\n  }\n}\n.inputWrap{\n  margin-top: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--SH2ZK`,
	"item": `s-module__item--U9J1m`,
	"subTitle": `s-module__subTitle--I8b65`,
	"uploadWrap": `s-module__uploadWrap--awv5M`,
	"text1": `s-module__text1--kDsWo`,
	"text2": `s-module__text2--zO3wB`,
	"link": `s-module__link--RXfvG`,
	"inputWrap": `s-module__inputWrap--jV_0I`
};
export default ___CSS_LOADER_EXPORT___;
