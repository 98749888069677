import React from 'react';
import { Button, Checkbox, Divider, Input, message } from 'antd';
import KiwiBanner from 'components/KiwiBanner';
import { PRIVACY_LINK, TERMS_LINK } from 'constants/common';
import GoogleSignUp from './components/GoogleSignUp';
import { PATH } from 'constants/path';
import {
    useNavigate,
} from 'react-router-dom';
import s from './s.module.less';
import WarningIcon from 'assets/common/warningRed.svg';
import VerifyEmail from './components/VerifyEmail';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase, isValidEmail } from 'utils/form';
import { registerWithPwd, sendVerify, TRegisterWithPwd } from 'api/public';
import { setUtmInfo, getUtmInfo, clearUtmInfo } from 'utils/localstore';
import { getUrlParam } from 'utils/common';
import { TUtmInfo } from 'types/common';

enum PasswordError {
    LOWERCASE = 'lowercase',
    SPECIAL = 'special',
    UPPERCASE = 'uppercase',
    MINIMUM = 'minimum',
    NUMBER = 'number',
}

export default function SignUpPage() {
    const navigate = useNavigate();
    const [psw, setPsw] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [pswError, setPswError] = React.useState<PasswordError[]>([]);
    const [showExistError, setShowExistError] = React.useState(false);

    const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
    const [inform, setInform] = React.useState(true);

    const showPwsError = psw && pswError.length > 0;
    const showEmailError = email && !isValidEmail(email);

    const [loading, setLoading] = React.useState(false);

    const addUtmInfo = () => {
        const source = getUrlParam('utm_source');
        const medium = getUrlParam('utm_medium');
        const campaign = getUrlParam('utm_campaign');
        const utmInfo: TUtmInfo = {};
        if (source) {
            utmInfo.source = source;
        }
        if (medium) {
            utmInfo.medium = medium;
        }
        if (campaign) {
            utmInfo.campaign = campaign;
        }
        setUtmInfo(utmInfo);
    };

    React.useEffect(() => {
        addUtmInfo();
    }, [])

    const registerWithPsw = async () => {
        if (showEmailError) {
            return;
        }
        setLoading(true);
        const utmInfo = getUtmInfo();
        const reqData: TRegisterWithPwd = {
            inform: inform ? 1 : 0,
            email,
            password: psw,
        };
        if (utmInfo.campaign) {
            reqData.signUpFrom = utmInfo.campaign;
        }
        const result = await registerWithPwd(reqData);
        if (!result.error) {
            clearUtmInfo();
            setShowVerifyEmail(true);
        } else {
            setShowExistError(true);
        }
        setLoading(false);
    };

    const onGoogleSignCallback = (res: any) => {
        if (!res?.error) {
            message.success('Sign up success, you can login with your account');
            clearUtmInfo();
            navigate(`/${PATH.PROVIDER_LOGIN}`);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } else {
            setShowExistError(true);
        }
    };

    const onResent = async () => {
        if (email) {
            setLoading(true);
            const result = await sendVerify(email);
            if (!result.error) {
                message.success(`An new activation link has been sent to ${email}`);
            }
            setLoading(false);
        } else {
            message.error('Please input email first');
        }
    };

    return (
        <div className={`${s.wrap} flex`}>
            <div className={s.left}>
                <div className={s.container}>
                    {
                        showVerifyEmail ?
                            <VerifyEmail email={email} /> :
                            <>
                                <h2 className={s.headerTitle}>
                                    <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" />
                                </h2>
                                <div className={s.subTitle}>
                                    Create your free account
                                </div>
                                <div className={s.navTips}>
                                    Already on Kiwi Health?
                                    <span
                                        style={{ color: '#006957', cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate('/provider-login');
                                            //google bug, need reload
                                            window.location.reload();
                                        }}
                                    > Log in
                                    </span>
                                </div>
                                {/* disable chrome auto fill */}
                                <input type="text" style={{ display: 'none' }} name="email" disabled autoComplete="off" />
                                <input type="password" style={{ display: 'none' }} disabled autoComplete="off" />
                                <div className={s.form}>
                                    <div className={s.label}>Email address</div>
                                    <div className={s.value}>
                                        <Input
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setShowExistError(false);
                                            }}
                                            value={email}
                                            size="large"
                                            status={showEmailError ? 'error' : undefined}
                                        />
                                    </div>
                                    <div className={s.label}>Password</div>
                                    <div className={s.value}>
                                        <Input.Password
                                            autoComplete="off"
                                            value={psw}
                                            status={showPwsError ? 'error' : undefined}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                const error: PasswordError[] = [];
                                                if (!hasLowerCase(val)) {
                                                    error.push(PasswordError.LOWERCASE);
                                                }
                                                if (!hasNumber(val)) {
                                                    error.push(PasswordError.NUMBER);
                                                }
                                                if (!hasSpecialCharacter(val)) {
                                                    error.push(PasswordError.SPECIAL);
                                                }
                                                if (!hasUpperCase(val)) {
                                                    error.push(PasswordError.UPPERCASE);
                                                }
                                                if (val.length < 8) {
                                                    error.push(PasswordError.MINIMUM);
                                                }
                                                setPswError(error);
                                                setPsw(e.target.value);
                                            }}
                                            size="large"
                                            style={{ marginBottom: '4px' }}
                                        />
                                    </div>
                                    <div className={showPwsError ? `${s.pswError} ${s.pswErrorShow}` : s.pswError}>
                                        <div
                                            onClick={() => {
                                                //@ts-ignore
                                                inform();
                                            }}
                                        >
                                            <span className={pswError.includes(PasswordError.LOWERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One lowercase character
                                        </div>
                                        <div><span className={pswError.includes(PasswordError.SPECIAL) ? `${s.icon} ${s.iconError}` : s.icon} />One special character</div>
                                        <div><span className={pswError.includes(PasswordError.UPPERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One uppercase character</div>
                                        <div><span className={pswError.includes(PasswordError.MINIMUM) ? `${s.icon} ${s.iconError}` : s.icon} />8 characters minimum</div>
                                        <div><span className={pswError.includes(PasswordError.NUMBER) ? `${s.icon} ${s.iconError}` : s.icon} />One number</div>
                                    </div>
                                    <div className={showExistError ? `${s.existError} ${s.existErrorShow}` : s.existError}>
                                        <img src={WarningIcon} />
                                        <span>An account with this email already exists. Please
                                            <span
                                                onClick={() => {
                                                    navigate('/provider-login');
                                                    //google bug, need reload
                                                    window.location.reload();
                                                }}
                                                className={s.resend}
                                            >
                                                {' log in '}
                                            </span>
                                            instead or
                                            <span
                                                onClick={onResent}
                                                className={s.resend}
                                            >
                                                {' request to resent a verification email.'}
                                            </span>
                                        </span>
                                    </div>
                                    <Button
                                        onClick={registerWithPsw}
                                        block
                                        type="primary"
                                        style={{ marginTop: '32px' }}
                                        size="large"
                                        loading={loading}
                                        disabled={!email || !psw || !!showPwsError || !!showEmailError}
                                    >Continue
                                    </Button>
                                </div>

                                <Divider plain className={s.divi}>Or</Divider>

                                <div className={s.googleWrap}>
                                    <GoogleSignUp
                                        onCallback={onGoogleSignCallback}
                                    />
                                </div>

                                <div className={s.tips}>
                                    By clicking on Continue above, you agree to the Kiwi Health
                                    <a style={{ color: '#006957' }} target="_blank" href={TERMS_LINK} rel="noreferrer"> terms </a>
                                    and
                                    <a style={{ color: '#006957' }} target="_blank" href={PRIVACY_LINK} rel="noreferrer"> privacy policy. </a>
                                </div>
                                <div>
                                    <Checkbox
                                        className={s.check}
                                        onChange={(e) => {
                                            setInform(e.target.checked);
                                        }}
                                        checked={inform}
                                    >
                                        Keep me informed with valuable updates and offers.
                                    </Checkbox>
                                </div>
                            </>
                    }

                </div>
            </div>
            <div className={s.right}>
                <div className={s.banner}>
                    <KiwiBanner />
                </div>
            </div>
        </div>
    );
}
