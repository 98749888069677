import React from 'react';
import { message, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { providerFetch } from 'api/provider';
import { checkMail } from 'api/public';
import ProviderStore from 'store/Provider';
import { getUrlParam } from 'utils/common';
import { PATH } from 'constants/path';
import { ERedirect } from 'types/common';
import { getUserId, setUserId } from 'utils/localstore';
import { authCalendarPermission } from 'api/google';
import CommonStore from 'store/Common';

const RedirectPage = () => {
    const navigate = useNavigate();
    const mail = getUrlParam('mail');
    const code = getUrlParam('code');
    const type = getUrlParam('type');
    const [, set] = ProviderStore.useStore();
    const [getCommon] = CommonStore.useStore();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showModal, setShowModal] = React.useState(false);
    const checkEmailFunc = async () => {
        const result = await checkMail({ email: mail!, code: code! });
        if (!result.error && result.data?.data) {
            message.success('Your email has been verified');
            navigate(`/${PATH.PROVIDER_LOGIN}`, { state: { email: mail } });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            navigate(PATH.VERIFICATION_FAIL);
        }
    };

    const authGoogleCalendarPermission = async () => {
        try {
            const result = await authCalendarPermission(code!);
            if (!result.error) {
                //update token status
                const userInfoResult = await providerFetch(getUserId()!);
                if (!userInfoResult.error) {
                    const { data } = userInfoResult.data;
                    set('data', {
                        ...data,
                    });
                    //can remove if check mail returned uid
                    const callbackPath = getCommon('authGoogleCalendarCallbackPath') || PATH.SIGNUP;
                    navigate(callbackPath);
                } else {
                    navigate(PATH.SIGNUP);
                }
            } else {
                navigate(PATH.SIGNUP);
            }
        } catch (e) {
            message.error('Request error');
            navigate(PATH.SIGNUP);
        }
    };

    React.useEffect(() => {
        if (type === ERedirect.VERIFY_EMAIL || type === ERedirect.RESUME_FROM_EMAIL) {
            checkEmailFunc();
        } else if (type === ERedirect.RESET_PASSWORD) {
            navigate(PATH.RESET_PASSWORD, { state: { email: mail, code } });
        } else if (code) {
            //setShowModal(true);
            authGoogleCalendarPermission();
        }
    }, []);
    return (
        <div style={{ minHeight: '300', textAlign: 'center', lineHeight: '300px' }}>
            <Spin spinning />
            <Modal
                open={showModal}
            >
                <div>{code}</div>
            </Modal>
        </div>
    );
};
export default RedirectPage;
