import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { PATH } from 'constants/path';
import { getUrlParam } from 'utils/common';

const ChannelDetailListSuccessPage = () => {
    const navigate = useNavigate();
    const channelId = getUrlParam('channelId');

    const handleBack = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${channelId}`, { replace: true });
    }, [channelId, navigate]);

    return (
        <div className={s.wrap}>
            <div className={s.box}>
                <div className={`${s.successImage} ${s.listSuccess}`} />
                <p className={s.title}>We have received your channel listing request!</p>
                <p className={s.desc}>We will be reviewing your listing applications shortly. You can track your channel status under Channels/My channels. </p>
                <Button type="primary" onClick={handleBack}>Back</Button>
            </div>
        </div>
    );
};

export default ChannelDetailListSuccessPage;
