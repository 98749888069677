import { useMemo } from 'react';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';

const useGetCorrectChannelStatus = (listStatus: string) => {
    const [listStatusOptions] = useGetSettingData(EnumFields.LIST_CHANNEL_STATUS);

    const statusText = useMemo(() => {
        const statusItem = listStatusOptions?.find((option) => listStatus === option.dictKey);

        return statusItem?.content;
    }, [listStatus, listStatusOptions]);

    return statusText;
};

export default useGetCorrectChannelStatus;
