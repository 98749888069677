import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import { Form, Input, Radio, Space } from 'antd';
import { PasswordError } from 'types/common';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase } from 'utils/form';
import { ISalesCreateNewAccount, ISalesProvider } from 'types/provider';
import type { RadioChangeEvent } from 'antd';
import { emailRegex } from 'pages/SalesProviderExp/constant';

interface IProps {
    formId: string;
    salesDetail?: ISalesProvider;
    handleFormSubmit: (formData: ISalesCreateNewAccount) => void;
    emailList?: string[];
}

const Signup = ({
    formId,
    handleFormSubmit,
    salesDetail,
    emailList,
}: IProps) => {
    const emails = emailList?.filter((e) => !!e) || [];
    const [formInstance] = Form.useForm<ISalesCreateNewAccount>();
    const [psw, setPsw] = useState('');
    const [pswError, setPswError] = useState<PasswordError[]>([]);
    const [emailType, setEmailType] = useState(emails[0]);
    const [email, setEmail] = useState(emails[0]);
    const [manualEmailError, setManualEmailError] = useState(false);
    const showPwsError = psw && pswError.length > 0;

    const handleEmailSelectChange = useCallback((e:RadioChangeEvent) => {
        setEmailType(e.target.value);

        if (e.target.value === 'manual') {
            setEmail('');
        } else {
            setManualEmailError(false);
            setEmail(e.target.value);
        }
    }, []);

    const handleEmailInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value);
        const verifyEmail = emailRegex.test(value);
        setManualEmailError(!verifyEmail);
    }, []);

    // const handleSignSubmit = useCallback(async (data: ISalesLinkFinnalyCompleteInput) => {
    //     setCheckoutLoading(true);
    //     try {
    //         const res = await salesLinkFinallyComplete(data);

    //         if (res && !res.error) {
    //             onNext?.();
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, [onNext, setCheckoutLoading]);

    const handleFormFinish = useCallback(() => {
        const verifyEmail = emailRegex.test(email);
        setManualEmailError(!verifyEmail);

        if (salesDetail?.linkUuid && verifyEmail && pswError.length === 0) {
            formInstance.validateFields().then((formData: ISalesCreateNewAccount) => {
                handleFormSubmit({
                    ...formData,
                    email,
                });
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [email, formInstance, handleFormSubmit, pswError, salesDetail]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Activate your Kiwi Health account</div>
            <div className={s.signBox}>
                <div className={s.subTitle}>Sign up with your work email</div>
                <div className={s.desc}>The email used for registration becomes your permanent Kiwi Health contact. All patient and system notifications will be sent to this address. Choose a frequently used work email, as it cannot be changed later.</div>
                <div className={s.item}>
                    <div className={s.label}>Choose a work email</div>
                    <Radio.Group onChange={handleEmailSelectChange} value={emailType}>
                        <Space direction="vertical">
                            {
                                emails.map((value, index) => {
                                    return <Radio key={index} value={value}>{value}</Radio>;
                                })
                            }
                            {/* <Radio value="2">Option B</Radio> */}
                            <Radio value="manual">I want to use another work email</Radio>
                        </Space>
                    </Radio.Group>
                    { emailType === 'manual' &&
                        <div className={s.manualEmail}>
                            <Input status={manualEmailError ? 'error' : ''} onChange={handleEmailInputChange} value={email} />
                            { manualEmailError && <div className={s.errorMsg}>Email is required</div> }
                        </div>
                    }
                </div>
                <div className={s.item}>
                    <div className={s.label}>Set up a password</div>
                    <Form
                        id={formId}
                        form={formInstance}
                        layout="vertical"
                        onFinish={handleFormFinish}
                    >
                        <div className={s.foromItems}>
                            <div className={s.formItem}>
                                <Form.Item
                                    label="New password"
                                    name="password"
                                    className={s.itemBox}
                                    rules={[
                                        { required: true, message: 'new password is required' },
                                    ]}
                                >
                                    <Input.Password
                                        value={psw}
                                        status={showPwsError ? 'error' : undefined}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            const error:PasswordError[] = [];
                                            if (!hasLowerCase(val)) {
                                                error.push(PasswordError.LOWERCASE);
                                            }
                                            if (!hasNumber(val)) {
                                                error.push(PasswordError.NUMBER);
                                            }
                                            if (!hasSpecialCharacter(val)) {
                                                error.push(PasswordError.SPECIAL);
                                            }
                                            if (!hasUpperCase(val)) {
                                                error.push(PasswordError.UPPERCASE);
                                            }
                                            if (val.length < 8) {
                                                error.push(PasswordError.MINIMUM);
                                            }
                                            setPswError(error);
                                            setPsw(e.target.value);
                                        }}
                                    />
                                </Form.Item>
                                <div className={showPwsError ? `${s.pswError} ${s.pswErrorShow}` : s.pswError}>
                                    <div><span className={pswError.includes(PasswordError.LOWERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One lowercase character</div>
                                    <div><span className={pswError.includes(PasswordError.SPECIAL) ? `${s.icon} ${s.iconError}` : s.icon} />One special character</div>
                                    <div><span className={pswError.includes(PasswordError.UPPERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One uppercase character</div>
                                    <div><span className={pswError.includes(PasswordError.MINIMUM) ? `${s.icon} ${s.iconError}` : s.icon} />8 characters minimum</div>
                                    <div><span className={pswError.includes(PasswordError.NUMBER) ? `${s.icon} ${s.iconError}` : s.icon} />One number</div>
                                </div>
                            </div>
                        </div>
                        <div className={s.foromItems}>
                            <div className={s.formItem}>
                                <Form.Item
                                    label="Confirm password"
                                    name="confirmPassword"
                                    className={s.itemBox}
                                    rules={
                                        [
                                            {
                                                validator: (rules, value) => {
                                                    const newPwd = formInstance.getFieldValue('password');

                                                    if (value !== newPwd) {
                                                        return Promise.reject(
                                                            new Error('The two passwords are entered differently'),
                                                        );
                                                    }

                                                    return Promise.resolve(true);
                                                },
                                            },
                                        ]
                                    }
                                >
                                    <Input.Password
                                        value={psw}
                                        status={showPwsError ? 'error' : undefined}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
