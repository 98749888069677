import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    AppointmentIconIcon,
    ChannelsIcon,
    PracticeFrontIcon,
    ProspectsIcon,
    HomeIcon,
    EhrIcon,
    ReputationManagementIcon,
    KlarityReviewsIcon,
} from 'components/MenuIcons';
import SubscriptionStore from 'store/Subscription';
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Dropdown, Spin, message, Button, Alert } from 'antd';
import type { MenuProps } from 'antd';
import s from './s.module.less';
import { PATH } from 'constants/path';
import ProviderStore from 'store/Provider';
import { Provider } from 'types/provider';

import Logo from 'assets/logo/kh.svg';
//import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import LogoMini from 'assets/campain/blackfriday2024/kiwiLogo_mini.png';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { openSubscriptionModal, openManageSubscriptionModal, openKlaritySubscriptionModal, openKiwiPlanAdvertisingModal } from 'utils/globalLayerControl';
import { FileTextOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { redirecToLogout } from 'utils/redirecToLogout';
import { EDot, EKey, getHasViewDotPage, getHasViewKiwiEhrPage, getHasViewKlarityReviewPage, getKey, setHasViewDotPage, setHasViewKiwiEhrPage } from 'utils/localstore';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { isFreeUser, isPaidFailUser, shouldShowKlarityPlanUI } from 'utils/provider';
import useGetMyChannels from 'hooks/useGetMyChannels';
import ProviderChannelsStore from 'store/ProviderChannles';
import { getPFStatusData } from 'api/practiceFront';
import useGetUniprofileStatus from 'hooks/useGetUniprofileStatus';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';
import useProviderServiceFlag from 'hooks/useProviderServiceFlag';
import ProviderAgreement from 'components/ProviderAgreement';
import HomeStore from 'store/Home';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { TSubscriptionType } from 'types/subscription';
import useGetKlarityReviewList from 'hooks/useGetKlarityReviewList';
import { EOpenType, ESortType } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import ReviewStore from 'store/Review';
import AlertStore from 'store/GlobalAlert';

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

enum MenuKey {
    HOME = 'home',
    APPOINTMENT = 'appointment',
    CHANNELS = 'channels',
    PROSPECTS = 'prospects',
    PRACTICE_FRONT = 'practice-front',
    EHR = 'ehr',
    REPUTATION_MANAGEMENT = 'reputation-management',
    KLARITY_REVIEWS = 'klarity-reviews',
    KLARITY_ASSESSMENT = 'klarity-assessment',
}

// if (isLocalhost()) {
//     items.push(getItem('Practice front', MenuKey.PRACTICE_FRONT, PracticeFrontIcon));
//     items.push(getItem('My Prospects', MenuKey.PROSPECTS, ProspectsIcon));
// }

const keyPathMap: Partial<Record<MenuKey, string>> = {
    [MenuKey.EHR]: `${PATH.DASHBOARD}/${PATH.EHR_HOME_PROVIDER}`,
    [MenuKey.HOME]: `${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`,
    [MenuKey.CHANNELS]: `${PATH.DASHBOARD}/${PATH.CHANNEL}`,
    [MenuKey.APPOINTMENT]: `${PATH.DASHBOARD}/${PATH.APPOINTMENT}`,
    [MenuKey.PRACTICE_FRONT]: `${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`,
    [MenuKey.PROSPECTS]: `${PATH.DASHBOARD}/${PATH.PROSPECTS}`,
    [MenuKey.REPUTATION_MANAGEMENT]: `${PATH.DASHBOARD}/${PATH.REPUTATION_MANAGEMENT}`,
    [MenuKey.KLARITY_REVIEWS]: `${PATH.DASHBOARD}/${PATH.KLARITY_REVIEWS}`,
};

const getDefaultKey = (pathStr?: string): MenuKey => {
    const path = pathStr || window.location.pathname;
    if (path.includes(MenuKey.APPOINTMENT)) {
        //setting also use this
        return MenuKey.APPOINTMENT;
    }
    if (path.includes('channel')) {
        return MenuKey.CHANNELS;
    }
    if (path.includes('ehr')) {
        return MenuKey.EHR;
    }
    if (path.includes('practice-front')) {
        return MenuKey.PRACTICE_FRONT;
    }
    if (path.includes('prospects')) {
        return MenuKey.PROSPECTS;
    }
    if (path.includes('reputation')) {
        return MenuKey.REPUTATION_MANAGEMENT;
    }
    if (path.includes('klarity-reviews')) {
        return MenuKey.KLARITY_REVIEWS;
    }
    return MenuKey.HOME;
};

const LayoutPage: React.FC = () => {
    const [getGloalAlert, setGlobalAlert] = AlertStore.useStore();
    const [collapsed, setCollapsed] = useState(false);
    const [get] = ProviderStore.useStore();
    const [currentKey, setCurrentKey] = useState<MenuKey>(getDefaultKey());
    const provider: Provider = get('data') as Provider;
    const uniprofileFinished = get('uniprofileStatus');
    const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);
    // const [down]

    const navigate = useNavigate();
    const location = useLocation();
    const [loadSettingData] = useLoadBasicData();
    const [subscriptionPlan, loadingSubscriptionPlan] = useSubscriptionPlan();

    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');

    const isKlarityStarter = currentPlan?.type === TSubscriptionType.KLARITY_STARTER;
    const isFree = isFreeUser();
    const isPaidFail = isPaidFailUser();
    const [myChannels, loadingMychannels, error, refetch] = useGetMyChannels();
    const [, setProviderChannels] = ProviderChannelsStore.useStore();
    const [isOpenUpgrade, setIsOpenUpgrade] = useState(true);

    const [flag] = useProviderServiceFlag();
    const isKlarity = !!flag?.klarityServiceFlag;
    const kiwiEhrHighLight = !getHasViewKiwiEhrPage();
    const prospectHighLight = !getHasViewDotPage(EDot.PROSPECT_0810);

    const [homeInfo, loadingHomeInfo, refetchHomeInfo] = useProviderHomeInfo();

    const defautlReviewListParams = useRef({
        sortType: ESortType.MOST_RECENT,
        publicOrPrivate: EOpenType.BOTH_PUBLIC_AND_PRIVATE,
        size: 10,
        current: 1,
        waitToReply: true,
    });
    const [, , loadingReviewList] = useGetKlarityReviewList(defautlReviewListParams.current);
    const [getReviewStore] = ReviewStore.useStore();
    const klarityReviewWaitToReplyNumber = getReviewStore('klarityReviewWaitToReplyNumber');

    const klarityReviewHighLight = !getHasViewKlarityReviewPage() || (klarityReviewWaitToReplyNumber && klarityReviewWaitToReplyNumber > 0);

    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);

    // Use case 1:the provider is Kiwi only; not listed on Klarity --> Kiwi Reputation
    // Use case 2:Klarity with Kiwi for Klarity; not paying for Kiwi before --> Klarity Review
    // Use case 3:Klarity & pay for Kiwi currently --> Let’s keep both tab
    let showKiwiRepucation = false;
    let showKlarityRepucation = false;

    if (homeInfo) {
        if (!homeInfo.klarityUser) {
            showKiwiRepucation = true;
            showKlarityRepucation = false;
        } else if (homeInfo.klarityUserWithoutKiwiPlan) {
            showKiwiRepucation = false;
            showKlarityRepucation = true;
        } else if (!homeInfo.klarityUserWithoutKiwiPlan) {
            showKiwiRepucation = true;
            showKlarityRepucation = true;
        }
    }

    React.useEffect(() => {
        if (homeInfo) {
            const path = window.location.pathname;
            if (!showKlarityRepucation && path.includes('klarity-reviews')) {
                navigate(PATH.DASHBOARD);
                return;
            }
            if (!showKiwiRepucation && path.includes('reputation')) {
                navigate(PATH.DASHBOARD);
            }
        }
    }, [homeInfo, showKiwiRepucation, showKlarityRepucation]);

    const items: MenuItem[] = [
        getItem('Home', MenuKey.HOME, HomeIcon),
        isKlarity ? getItem(<span className={kiwiEhrHighLight ? s.hightLight : ''}>Kiwi EHR</span>, MenuKey.EHR, EhrIcon) : undefined,
        //getItem(<span className={s.hightLight}>Kiwi EHR</span>, MenuKey.EHR, EhrIcon),
        getItem('Channels', MenuKey.CHANNELS, ChannelsIcon),
        getItem('Practice front', MenuKey.PRACTICE_FRONT, PracticeFrontIcon),
        getItem(<span className={prospectHighLight ? s.hightLight : ''}>My Prospects</span>, MenuKey.PROSPECTS, ProspectsIcon),
        showKlarityPlanUI ? undefined : getItem('Appointment', MenuKey.APPOINTMENT, AppointmentIconIcon),
        showKiwiRepucation ? getItem('Reputation Management', MenuKey.REPUTATION_MANAGEMENT, ReputationManagementIcon) : undefined,
        showKlarityRepucation ? getItem(<span className={klarityReviewHighLight ? s.hightLight : ''}>Klarity reviews</span>, MenuKey.KLARITY_REVIEWS, KlarityReviewsIcon) : undefined,
    ].filter(Boolean) as MenuItem[];

    const [, loading] = useGetUniprofileStatus();
    const [uniprofileEntryPath] = useGetUniprofileEntry();

    const ProviderMenus = useMemo(() => [
        {
            key: '1',
            label: (
                // <Link to={`${PATH.CREATE_UNI_PROFILE}?type=uniprofile`}>
                <Link to={`${uniprofileEntryPath}?type=uniprofile`}>
                    <FileTextOutlined />
                    <span style={{ marginLeft: '8px' }}>UniProfile</span>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => openManageSubscriptionModal()}>
                    <SettingOutlined />
                    <span style={{ marginLeft: '8px' }}>Setting</span>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={() => {
                    const key = getKey(EKey.BOX_PRACTICE_TOKEN);
                    localStorage.removeItem(key);

                    redirecToLogout();
                }}
                >
                    <LogoutOutlined />
                    <span style={{ marginLeft: '8px' }}>Logout</span>
                </a>
            ),
        },
    ], [uniprofileFinished]);

    useEffect(() => {
        loadSettingData();
        setProviderChannels('data', myChannels);
    }, [loadSettingData, myChannels]);

    const onClickMenu = ({ key }: { key: string }) => {
        const path: string | undefined = keyPathMap[key as MenuKey];
        if (key === MenuKey.EHR) {
            setHasViewKiwiEhrPage('true');
        }
        if (key === MenuKey.PROSPECTS) {
            setHasViewDotPage(EDot.PROSPECT_0810, 'true');
        }
        if (path) {
            navigate(path);
            setCurrentKey(key as MenuKey);
        }
    };

    useEffect(() => {
        const newKey = getDefaultKey(location.pathname);
        setCurrentKey(newKey);
    }, [location]);

    const handleUpgradeClick = useCallback(() => {
        if (showKlarityPlanUI) {
            openKlaritySubscriptionModal();
        } else {
            //openSubscriptionModal();
            openKiwiPlanAdvertisingModal();
        }
    }, [showKlarityPlanUI]);

    const handleCloseGlobalAlert = useCallback(() => {
        setGlobalAlert('info', { show: false, message: '', type: 'success' });
    }, []);

    if (loadingSubscriptionPlan === null || loadingSubscriptionPlan === true || loadingMychannels === null || loading) {
        return <div className={s.spinnerWrap}><Spin /></div>;
    }

    const updateBenifit: string[] = ['Access to more channels', 'AI-Powered prospect management', 'Reputation management', 'Advanced website builder'];

    return (
        <Layout className={s.layout}>
            <ProviderAgreement />
            <Sider theme="light" className={s.sider} collapsed={collapsed} width="255px">
                <div className={s.siderContent}>
                    <div className={s.menuHead}>
                        {
                            collapsed &&
                            <img
                                onClick={() => {
                                    toggleCollapsed();
                                }}
                                src={LogoMini}
                                className={s.logoS}
                            />
                        }
                        {!collapsed && <img
                            onClick={() => {
                                toggleCollapsed();
                            }}
                            src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png"
                            //src={Logo}
                            className={s.logo}
                        />}
                    </div>
                    <Menu onClick={onClickMenu} key={currentKey} defaultSelectedKeys={[getDefaultKey()]} mode="inline" items={items} />
                </div>
                <div className={s.siderFooter}>
                    <div className={s.footerMenu}>
                        {
                            (isFree || isKlarityStarter) && !isPaidFail && !collapsed && currentPlan &&
                            <div className={`${s.upgradeWrap} ${isOpenUpgrade ? s.upgradeWrapOpen : ''}`}>
                                <div className={s.uTitle}>
                                    <div className={s.uLeft}>
                                        Upgrade now to elevate your practice with our early bird offer; save
                                        <span className={s.highLight}> 20%</span>!
                                    </div>
                                    <div
                                        onClick={() => {
                                            setIsOpenUpgrade(!isOpenUpgrade);
                                        }}
                                        className={s.uRight}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M5 15L12 8L19 15" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>

                                <div className={s.uItemWrap}>
                                    {
                                        updateBenifit.map((val: string) => {
                                            return (
                                                <div className={s.uItem} key={val}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z" fill="#00816B" />
                                                    </svg>
                                                    {val}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <Button
                                    block
                                    type="primary"
                                    className={s.upgrade}
                                    onClick={handleUpgradeClick}
                                >
                                    🚀 Upgrade
                                </Button>
                            </div>
                        }
                        <div className={s.menuItem}>
                            <img className={s.profilePhoto} src={provider?.photo || Logo} />
                            {!collapsed && <div className={s.profileName}>{provider?.firstName || provider?.email}</div>}
                            <Dropdown className={s.dropDown} menu={{ items: ProviderMenus }} trigger={['click']}>
                                <div className={s.moreActions} />
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </Sider>
            <Layout className={s.siteLayout} id="siteLayout">
                <Spin spinning={loadingSubscriptionPlan || loadingMychannels || loadingReviewList}>
                    {getGloalAlert('info')?.show && <Alert className={s.globalAlert} message={getGloalAlert('info')?.message || ''} type={getGloalAlert('info')?.type || 'success'} closable onClose={handleCloseGlobalAlert} />}
                    <Content>
                        <Outlet />
                    </Content>
                </Spin>
            </Layout>
        </Layout>
    );
};

export default LayoutPage;
