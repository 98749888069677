import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Form, Input, Popconfirm, Select, Typography, message, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import CommonTable from 'components/CommonTable';
import debounce from 'lodash/debounce';
import { useRequest } from 'ahooks';
import { deleteOpsMembers, getOpsMemberList, updateOpsMembers, addOpsMember } from 'api/access';
import RoleLabel from '../Components/RoleLabel';
import { IRole, IRowData } from 'types/access';
import useGetRoleList from 'hooks/useGetRoleList';
import dayjs from 'dayjs';
import AccessRoleStore from 'store/Access/role';
import { checkUserRole, isSuperAdmin, transferRoleStringToNunber } from 'utils/access';
import { limitedEmails } from 'constants/access';
import remove from 'lodash/remove';

interface IRow extends IRowData {
    editable: boolean;
    dataIndex: string;
    title: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    form: any;
    editing: boolean;
    dataIndex: string;
    title: string;
    inputType: string;
    record: IRowData;
    index: number;
    options: Array<{ label: string, value: number }>,
    children: React.ReactNode;
}

const emailTailErrorText = 'Only can add members with @helloklarity.com @kiwihealth.com @boxpractice.com @hush.com email';

const EditableCell = ({
    form,
    options,
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: EditableCellProps) => {
    const inputNode = inputType === 'checkbox' ? <Select options={options} /> : <Input />;
    const rules: Record<string, any>[] = [
        {
            required: true,
        },
    ];

    if (dataIndex === 'gmail') {
        rules.push(
            {
                validator: (r, value: string) => {
                    if (!limitedEmails.includes(value?.split('@')[1])) {
                        return Promise.reject(
                            new Error(emailTailErrorText),
                        );
                    }

                    return Promise.resolve(true);
                },
            },
        );
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    help=""
                    rules={rules}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const OpsMember = () => {
    const [getAccessRoleStore] = AccessRoleStore.useStore();
    const roleStore = getAccessRoleStore('data');
    const [modal, contextHolder] = Modal.useModal();
    const [formInstance] = Form.useForm();
    const [filterParams, setFilterParams] = useState({
        firstName: '',
        lastName: '',
        gmail: '',
    });

    const [selectedRows, setSelectedRows] = useState<Array<IRowData>>([]);
    const [editingKey, setEditingKey] = useState<number | undefined>(-1);
    const [tableData, setTableData] = useState<Array<IRowData>>([]);
    const [originalTableData, setOriginalTableData] = useState<Array<IRowData>>([]);

    const { data, loading, run } = useRequest(getOpsMemberList);
    const userRole = checkUserRole(roleStore);
    const [roleList] = useGetRoleList();

    const roleOptions = useMemo(() => roleList?.map((role) => ({
        label: role.name,
        value: role.id,
        disabled: userRole >= transferRoleStringToNunber(role.name),
    })), [roleList, userRole]);

    useEffect(() => {
        const originalData = (data?.data?.data || []).map((rowData: IRowData) => {
            const roleData = roleList.find(((item) => item.name === rowData.role));

            return ({
                ...rowData,
                role: roleData?.id || -1,
                roleName: roleData?.name || '',
            });
        });

        setTableData(originalData);
        setOriginalTableData(originalData);
    }, [data, roleList]);

    const tableIsEditing = useCallback(() => editingKey !== -1, [editingKey]);

    const filterNameDebounce = useCallback(debounce((params: Partial<IRowData>) => {
        const filterSourceData = originalTableData;

        const filterRes = filterSourceData.filter((item:IRowData) => {
            const res = Object.entries(params).reduce((r, c) => {
                const [key, value] = c as [keyof IRowData, string];
                return r && item[key]?.toString().indexOf(value) !== -1;
            }, true);

            return res;
        });

        setTableData(filterRes);
    }, 500), [originalTableData]);

    const handleAddMember = useCallback(async () => {
        if (tableIsEditing()) {
            message.error('Please save your changes before add new data');
        } else if (tableData[0] && !tableData[0].id) {
            message.error('You must save the previously added member before you add another one');
            setEditingKey(undefined);
            setTimeout(() => {
                formInstance.validateFields();
            }, 100);
        } else {
            formInstance.resetFields();
            const newRowData: IRowData = {
                accessToken: '',
                firstName: '',
                gmail: '',
                lastName: '',
                password: '',
                role: -1,
                tel: '',
                roleName: '',
            };

            setTableData([newRowData].concat(tableData));
            setOriginalTableData([newRowData].concat(originalTableData));
            setEditingKey(undefined);
        }
    }, [formInstance, originalTableData, tableData, tableIsEditing]);

    const handleEdit = useCallback((item: IRowData) => {
        if (!tableIsEditing()) {
            formInstance.setFieldsValue(item);
            setEditingKey(item?.id);
        } else {
            message.error('Please save your changes before proceeding to edit another section');
        }
    }, [formInstance, tableIsEditing]);

    const submit = useCallback(async (item: IRowData) => {
        try {
            const row = (await formInstance.validateFields()) as IRowData;

            const { firstName, lastName, gmail, role } = row;

            if (limitedEmails.includes(gmail.split('@')[1])) {
                const { id } = item;
                let res = {
                    error: '',
                };
                const newData = {
                    accessToken: item.accessToken,
                    firstName,
                    gmail,
                    lastName,
                    password: item.password,
                    tel: item.tel,
                    roleList: [role],
                };

                if (id) {
                    res = await updateOpsMembers(id, newData);
                } else {
                    res = await addOpsMember(newData);
                }

                if (!res.error) {
                    setEditingKey(-1);
                    run();
                } else {
                    message.error(res.error);
                }
            } else {
                message.error(emailTailErrorText);
            }
        } catch (errInfo) {
            if (errInfo?.errorFields?.find((field) => field?.name?.[0] === 'gmail')) {
                message.error(emailTailErrorText);
            }
            console.log('Validate Failed:', errInfo);
        }
    }, [formInstance, run]);

    const handleCancelEdit = useCallback(() => {
        setEditingKey(-1);
        remove(tableData, (item) => !item.id);
        setTableData([...tableData]);
    }, [tableData]);

    const confirmDelete = useCallback(async () => {
        const ids = selectedRows.filter((e) => !!e.id).map((e) => e.id) as number[];
        const res = await deleteOpsMembers(ids);
        // const res = await deleteOpa1sMemberById(ids[0]);

        if (!res.error) {
            run();
        } else {
            message.error(res.error);
        }
    }, [run, selectedRows]);

    const handleDeleteMembers = useCallback(() => {
        if (selectedRows.length > 0) {
            modal.confirm({
                title: 'Confirm Member Deletion',
                content: 'do you want to delete this member from the Ops Portal?',
                onOk: confirmDelete,
                okText: 'Delete',
            });
        }
    }, [selectedRows.length, modal, confirmDelete]);

    const isEditingRow = useCallback((record: IRow) => {
        return record.id === editingKey;
    }, [editingKey]);

    const renderActionColunm = useCallback((item) => {
        if (userRole === 0) {
            const editable = isEditingRow(item);

            return (
                <div className={s.actionCell}>
                    {
                        editable ? (
                            <span>
                                <Typography.Link onClick={() => submit(item)} className={s.actionBtn}>
                                    Save
                                </Typography.Link>
                                <Popconfirm title="Confirm to cancel?" placement="topRight" onConfirm={handleCancelEdit}>
                                    <a className={s.cancelBtn}>Cancel</a>
                                </Popconfirm>
                            </span>
                        ) : (
                            <Typography.Link className={s.actionBtn} onClick={() => handleEdit(item)}>
                                Edit
                            </Typography.Link>
                        )
                    }
                </div>
            );
        } else if (userRole === 1) {
            const editable = isEditingRow(item);
            const role = transferRoleStringToNunber(item.roleName);

            return (userRole < role || isSuperAdmin([item.roleName])) ? (
                <div className={s.actionCell}>
                    {
                        editable ? (
                            <span>
                                <Typography.Link onClick={() => submit(item)} className={s.actionBtn}>
                                    Save
                                </Typography.Link>
                                <Popconfirm title="Confirm to cancel?" placement="topRight" onConfirm={handleCancelEdit}>
                                    <a className={s.cancelBtn}>Cancel</a>
                                </Popconfirm>
                            </span>
                        ) : (
                            <Typography.Link className={s.actionBtn} onClick={() => handleEdit(item)}>
                                Edit
                            </Typography.Link>
                        )
                    }
                </div>
            ) : null;
        }

        return null;
    }, [handleCancelEdit, handleEdit, isEditingRow, submit, userRole]);

    const columns: ColumnsType<IRow> = useMemo(() => (
        [
            {
                title: 'FIRST NAME',
                dataIndex: 'firstName',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                editable: true,
                width: '150px',
                fixed: 'left',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const firstName = e.target.value;
                                setTimeout(() => {
                                    setFilterParams({
                                        ...filterParams,
                                        firstName,
                                    });

                                    filterNameDebounce({
                                        ...filterParams,
                                        firstName,
                                    });
                                }, 0);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
            },
            {
                title: 'LAST NAME',
                dataIndex: 'lastName',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                width: '200px',
                editable: true,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const lastName = e.target.value;
                                setFilterParams({
                                    ...filterParams,
                                    lastName,
                                });
                                filterNameDebounce({
                                    ...filterParams,
                                    lastName,
                                });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
            },
            {
                title: 'CONTACT',
                dataIndex: 'gmail',
                width: '300px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                editable: true,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const gmail = e.target.value;
                                setFilterParams({
                                    ...filterParams,
                                    gmail,
                                });
                                filterNameDebounce({
                                    ...filterParams,
                                    gmail,
                                });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
            },
            {
                title: 'PERMISSION',
                dataIndex: 'permission',
                width: '320px',
                className: s.tableColumn,
                editable: false,
            },
            {
                title: 'LAST MODIFY',
                dataIndex: 'updateTime',
                width: '200px',
                className: s.tableColumn,
                render: (updateTime) => updateTime && dayjs(updateTime).format('YYYY-MM-DD hh:mm:ss'),
            },
            {
                title: 'ROLE',
                dataIndex: 'role',
                width: '120px',
                className: s.tableColumn,
                editable: true,
                fixed: 'right',
                render: (role, { roleName }) => roleName && <RoleLabel role={roleList.find((r: IRole) => r.name === roleName)} />,
            },
            {
                title: '',
                width: '100px',
                className: s.tableColumn,
                editable: false,
                fixed: 'right',
                render: renderActionColunm,
            },
        ]
    ), [renderActionColunm, filterParams, filterNameDebounce, roleList]);

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: IRow) => {
                return ({
                    record,
                    inputType: col.dataIndex === 'role' ? 'checkbox' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditingRow(record),
                });
            },
        };
    });

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>Ops member</div>
            <div className={s.inviteBar}>
                <div className={s.members}>{tableData.length} Ops member in Kiwi Health</div>
                { userRole <= 1 && <Button type="primary" onClick={handleAddMember}>Invite a new member</Button> }
            </div>
            {
                userRole <= 1 &&
                <div className={s.deleteBar}>
                    <div className={s.deleteBtn} onClick={handleDeleteMembers}>Delete</div>
                    <div className={s.selectedNumbers}>Selected {selectedRows.length} member</div>
                </div>
            }
            <div className={s.tableWrap}>
                <Form form={formInstance} component={false}>
                    <CommonTable
                        pagination={false}
                        components={{
                            body: {
                                cell: (props) => EditableCell({ ...props, options: roleOptions }),
                            },
                        }}
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRowsData) => setSelectedRows(selectedRowsData),
                        }}
                        rowKey={(record: IRowData) => record.id || record.accessToken}
                        bordered
                        loading={loading}
                        columns={mergedColumns}
                        data={tableData}
                        scroll={{ x: '1200' }}
                    />
                </Form>
            </div>
        </div>
    );
};

export default OpsMember;
