import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Form, Input, InputNumber } from 'antd';
import ProviderStore from 'store/Provider';
import { OTHERS_KEY } from 'constants/meta';
import { ProfileFormFieldEnum, ProfileFormInterface } from 'types/provider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import CheckGroupWithInput from 'components/form/CheckGroupWithInput';
import useSettings from 'hooks/useSettings';
import StateSelect from 'components/form/StateSelect';
import AdapterInput from 'components/form/AdapterInput';
import { EnumFields } from 'types/enumerationData';
import FormValidationHelper from 'utils/validation';

type Props = {
    form: FormInstance<ProfileFormInterface>,
};

const Profile = (props: Props) => {
    const { form } = props;
    const [getUser] = ProviderStore.useStore();
    const user = getUser('data');
    const [settings, loadingSettings] = useSettings();
    const supportPracticeExp = true;

    const specialitiesInPsychiatricMentalHealth = settings[EnumFields.SIPMH] || [];
    const specialitiesInPsychiatricMentalHealthOptions = [...specialitiesInPsychiatricMentalHealth, { label: 'Other, not listed here', value: OTHERS_KEY }];

    const specialitiesInFamily = settings[EnumFields.SIFAP] || [];

    const specialitiesInFamilyOptions = [...specialitiesInFamily, { label: 'Other, not listed here', value: OTHERS_KEY }];
    const [hasSetSpecialitiesInPsychiatric, setHasSetSpecialitiesInPsychiatric] = React.useState(!!form.getFieldValue(ProfileFormFieldEnum.SPECIALITYINHEALTH));
    const [hasSetSpecialitiesInFamily, setHasSetSpecialitiesInFamily] = React.useState(!!form.getFieldValue(ProfileFormFieldEnum.SPECIALITYINFAMILY));

    return (
        <div className={s.wrap}>
            <h2>Hello, {user?.firstName}!</h2>
            <p className={s.subTitle}>Welcome to Kiwi Health application process! First, let&apos;s get to know you.</p>
            <div className={s.contentInner}>
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <div className={s.module}>
                        <h3>Personal Information</h3>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '280px' }}
                                    label="Email"
                                    name={ProfileFormFieldEnum.EMAIL}
                                    rules={[{ required: true, message: 'Email is required' }]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '280px' }}
                                    label="Personal phone number"
                                    name={ProfileFormFieldEnum.TEL}
                                    rules={[{ required: true, message: 'Phone is required' }]}
                                >
                                    <Input maxLength={30} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '280px' }}
                                    label="Mailing address"
                                    name={ProfileFormFieldEnum.MAILING_ADDRESS}
                                    rules={[{ required: true, message: 'Mailing address is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '120px' }}
                                    label="City"
                                    name={ProfileFormFieldEnum.CITY}
                                    rules={[{ required: true, message: 'City is required' }]}
                                >
                                    <AdapterInput
                                        adapterFun={(val) => {
                                            if (!val) {
                                                return val;
                                            }
                                            const capitalized = val.charAt(0).toUpperCase() + val.slice(1);
                                            return capitalized;
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '160px' }}
                                    label="State"
                                    name={ProfileFormFieldEnum.STATE}
                                    rules={[{ required: true, message: 'State is required' }]}
                                >
                                    <StateSelect />
                                </Form.Item>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '160px' }}
                                    label="ZIP code"
                                    name={ProfileFormFieldEnum.ZIP_CODE}
                                    rules={[{ required: true, message: 'ZIP code is required' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    {supportPracticeExp &&
                    <div className={s.module}>
                        <h3>Practice Experience</h3>

                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="NPI number"
                                    name={ProfileFormFieldEnum.NPI_NUMBER}
                                    rules={[
                                        { required: true, message: 'NPI number is required' },
                                        FormValidationHelper.validateLicenseNumber('The NPI must contain only letters or numbers'),
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Years of experience in healthcare"
                                    name={ProfileFormFieldEnum.YEARS_OF_EXPERIENCE}
                                    rules={[{ required: true, message: 'Years of experience is required' }]}
                                >
                                    <InputNumber controls={false} min={0} />
                                </Form.Item>
                            </div>
                        </div>

                        <div className={s.row}>
                            <div className={s.colItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Select all your specialties in Psychiatry/Mental Health"
                                    name={ProfileFormFieldEnum.SPECIALITYINHEALTH}
                                    rules={[{ required: (!hasSetSpecialitiesInPsychiatric && !hasSetSpecialitiesInFamily), message: 'Specialties in Psychiatry or Primary Care are required' }]}
                                    dependencies={[ProfileFormFieldEnum.SPECIALITYINFAMILY]}
                                    shouldUpdate
                                >
                                    <CheckGroupWithInput
                                        onChange={(val:unknown[]) => {
                                            if (!val || val.length === 0) {
                                                setHasSetSpecialitiesInPsychiatric(false);
                                            } else {
                                                setHasSetSpecialitiesInPsychiatric(true);
                                            }
                                            form.validateFields([ProfileFormFieldEnum.SPECIALITYINFAMILY]);
                                        }}
                                        options={specialitiesInPsychiatricMentalHealthOptions}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.colItem}>
                                <Form.Item
                                    style={{ width: '360px' }}
                                    label="Select all your specialties in Primary Care"
                                    name={ProfileFormFieldEnum.SPECIALITYINFAMILY}
                                    rules={[{ required: (!hasSetSpecialitiesInPsychiatric && !hasSetSpecialitiesInFamily), message: 'Specialties in Psychiatry or Primary Care are required' }]}
                                    shouldUpdate
                                    dependencies={[ProfileFormFieldEnum.SPECIALITYINHEALTH]}
                                >
                                    <CheckGroupWithInput
                                        onChange={(val:unknown[]) => {
                                            if (!val || val.length === 0) {
                                                setHasSetSpecialitiesInFamily(false);
                                            } else {
                                                setHasSetSpecialitiesInFamily(true);
                                            }
                                            form.validateFields([ProfileFormFieldEnum.SPECIALITYINHEALTH]);
                                        }}
                                        options={specialitiesInFamilyOptions}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    }
                </Form>
            </div>
        </div>
    );
};

export default Profile;
