import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { ISalesProvider } from 'types/provider';
import { klarityDefaultMessage } from 'pages/SalesProviderExp/constant';
import { decodeHtmlEntities, loadSalesPresentationUrlInIframe } from 'utils/common';

interface IProps {
    salesDetail?: ISalesProvider;
}

const Helloklairty = ({
    salesDetail,
}: IProps) => {
    const [message, setMessage] = useState(klarityDefaultMessage);
    const { fullName, presentationEmbeddedLink } = salesDetail?.checkOut || {};

    useEffect(() => {
        if (salesDetail && salesDetail.checkOut && salesDetail.checkOut.message) {
            setMessage(salesDetail.checkOut.message);
        } else {
            setMessage(klarityDefaultMessage);
        }
    }, [salesDetail]);

    return (
        <div className={s.wrap}>
            <div className={s.inner}>
                <div className={s.box}>
                    <div className={s.left}>
                        <div className={s.desc}>
                            <div className={s.title}>Welcome to your next step with Klarity</div>
                            <div className={s.providerName}>👋 Hello, {fullName}!</div>
                            <div className={s.content} dangerouslySetInnerHTML={{ __html: message ? decodeHtmlEntities(message) : '' }} />
                        </div>
                    </div>
                </div>
                {
                    presentationEmbeddedLink &&
                    <div className={s.overview}>
                        <div className={s.title}>Overview</div>
                        <div className={s.iframeWrap}>
                            <iframe title="overview" src={loadSalesPresentationUrlInIframe(presentationEmbeddedLink)} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Helloklairty;
