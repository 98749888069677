import { getAddonProductList } from 'api/ehr';
import React from 'react';
import { EAddOnProductCode, EhrAddOnItem } from 'types/ehr';

type TEhrItems = {
    all:EhrAddOnItem[],
    self:EhrAddOnItem[],
    collaboratingPhysician:EhrAddOnItem[],
    assistant: EhrAddOnItem[],
};

let globalData:TEhrItems = {
    all: [],
    self: [],
    collaboratingPhysician: [],
    assistant: [],
};

const useAddOnProduct = ():[TEhrItems, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TEhrItems>(globalData);
    const fetchData = async () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            const result = await getAddonProductList();
            if (!result.error) {
                const allData:EhrAddOnItem[] = result?.data?.data || [];
                const selfData:EhrAddOnItem[] = [EAddOnProductCode.SELF_EPRESCRIPTION_EPCS, EAddOnProductCode.SELF_EPRESCRIPTION, EAddOnProductCode.SELF_EFAX].map((type) => {
                    const item = allData.find((i) => i.productCode === type);
                    return item!;
                }).filter(Boolean);
                const collaboratingPhysicianData:EhrAddOnItem[] = [EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT, EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS, EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION].map((type) => {
                    const item = allData.find((i) => i.productCode === type);
                    return item!;
                }).filter(Boolean);
                const assistantData:EhrAddOnItem[] = [EAddOnProductCode.ASSISTANT_SEAT, EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS, EAddOnProductCode.ASSISTANT_EPRESCRIPTION].map((type) => {
                    const item = allData.find((i) => i.productCode === type);
                    return item!;
                }).filter(Boolean);
                const data:TEhrItems = {
                    all: allData,
                    self: selfData,
                    collaboratingPhysician: collaboratingPhysicianData,
                    assistant: assistantData,
                };
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [items, loading, fetchData];
};

export default useAddOnProduct;
