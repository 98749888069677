import React from 'react';
import { Spin } from 'antd';
import { isOpPortal } from 'utils/common';
import { getSubscriptionCallbackShowSuccess, getSubscriptionCallbackPath, setSubscriptionCallbackShowSuccess } from 'utils/localstore';
import { PATH } from 'constants/path';
import { useNavigate } from 'react-router-dom';
import GlobalLayerStore from 'store/GlobalLayer';
import SubscriptionStore from 'store/Subscription';
import { getProviderPlan } from 'api/subscription';
import { IS_BACK_FROM_STRIPE } from 'constants/common';

const MAX_CHECK_TIME = 5;
const PaySuccessPage = () => {
    const showPaySuccess = !!getSubscriptionCallbackShowSuccess();
    const [, setGlobalLayer] = GlobalLayerStore.useStore();
    const [, setSubscription] = SubscriptionStore.useStore();
    const checkTimeRef = React.useRef(0);
    const navigate = useNavigate();

    const getPaySuccessPath = () => {
        const subscriptionCallbackPath = getSubscriptionCallbackPath();

        if (subscriptionCallbackPath) {
            const query = window?.location?.search;

            if (query) {
                return `${subscriptionCallbackPath}${query}`;
            }

            return subscriptionCallbackPath;
        }

        return PATH.DASHBOARD;
    };

    React.useEffect(() => {
        let timer:number | undefined;
        const loadUserSubscriptionInfo = async () => {
            if (!isOpPortal()) {
                setSubscription('loading', true);
                const result = await getProviderPlan();
                if (!result.error && result.data.data) {
                    const { data } = result.data;
                    setSubscription('currentPlan', data);
                    sessionStorage.setItem(IS_BACK_FROM_STRIPE, 'true');

                    const paySuccessPath = getPaySuccessPath();
                    if (showPaySuccess) {
                        setGlobalLayer('showSubscriptionSuccess', true);
                        setSubscriptionCallbackShowSuccess(false);
                    }
                    navigate(paySuccessPath || PATH.DASHBOARD);
                    clearInterval(timer);
                } else {
                    if (checkTimeRef.current >= MAX_CHECK_TIME) {
                        navigate(PATH.DASHBOARD);
                        return;
                    }
                    timer = window.setTimeout(() => {
                        loadUserSubscriptionInfo();
                        checkTimeRef.current += 1;
                    }, 3000);
                }
                setSubscription('loading', false);
            }
        };
        loadUserSubscriptionInfo();
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, []);

    return (
        <div style={{ minHeight: '300', textAlign: 'center', lineHeight: '300px' }}>
            <Spin spinning />
        </div>
    );
};
export default PaySuccessPage;
