import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { ISalesProvider } from 'types/provider';
import { TSubscriptionItemFromServer, TSubscriptionSubType } from 'types/subscription';
import { Button, Input, Select, message } from 'antd';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import countries from 'constants/country';
import { ECurrentStep } from 'types/salesProvider';
import { applyPromocode, checkoutPayment } from 'api/salesProvider';
import { emailRegex } from 'pages/SalesProviderExp/constant';

interface IProps {
    salesDetail?: ISalesProvider;
    setNextButtonCallback: (fn: (e: any) => void) => void;
    onNext: (n: ECurrentStep) => void;
    setShowPaySuccessTip: (s: boolean) => void;
    setSalesDetail: (detail: ISalesProvider) => void;
}

const filedName = {
    cardNumber: 'cardNumber',
    cardExpiry: 'cardExpiry',
    cardCvc: 'cardCvc',
    email: 'email',
    country: 'country',
    zipCode: 'zipCode',
};

const PaymentForm = ({
    salesDetail,
    setNextButtonCallback,
    onNext,
    setShowPaySuccessTip,
    setSalesDetail,
}: IProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [fieldsVerifyResult, setFieldsVerifyResult] = useState({
        [filedName.cardNumber]: {
            error: true,
            message: 'Card number is required',
        },
        [filedName.cardExpiry]: {
            error: true,
            message: 'Card expiry date is required',
        },
        [filedName.cardCvc]: {
            error: true,
            message: 'Card CVC is required',
        },
        [filedName.email]: {
            error: true,
            message: 'Please enter the correct email',
        },
        [filedName.zipCode]: {
            error: true,
            message: 'This filed is required',
        },
        [filedName.country]: {
            error: false,
            message: 'This filed is required',
        },
    });
    const [showErrors, setShowErrors] = useState(false);
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('US');
    const [zipCode, setZipCode] = useState();
    const [promoCode, setPromoCode] = useState('');
    const [promoLoading, setPromoLoading] = useState(false);
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [showErrorMessageBox, setShowErrorMessageBox] = useState(false);
    const [totlaPrice, setTotalPrice] = useState<number>(0);
    const [discoutPrice, setDiscoutPrice] = useState(0);
    const defaultErrorMessage = 'Unfortunately, your payment could not be processed. Please check your payment details and try again, or contact your bank for more information.';
    const defaultPromoCodeErrorMessage = 'Please enter a valid code';
    const [mainErrorMessage, setMainErrorMessage] = useState(defaultErrorMessage);
    const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState(defaultPromoCodeErrorMessage);

    //服务端的coupon数据
    const [coupon, setCoupon] = useState();

    const { payPlan = {}, promotionCode, inviteEmail } = salesDetail?.checkOut || {};
    const { price, type } = payPlan as TSubscriptionItemFromServer;

    const subPlanType = useMemo(() => {
        let res = {
            billedType: 'Billed monthly',
            period: 'monthly',
        };

        switch (salesDetail?.checkOut?.payPlan?.subType) {
            case TSubscriptionSubType.QUARTERLY_BILLING:
                res = {
                    billedType: 'Billed quarterly',
                    period: 'quarterly',
                };
                break;
            case TSubscriptionSubType.YEARLY_BILLING:
                res = {
                    billedType: 'Billed yearly',
                    period: 'yearly',
                };
                break;
            default: res = {
                billedType: 'Billed monthly',
                period: 'monthly',
            };
        }

        return res;
    }, [salesDetail]);

    const handlePromoCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value);

        if (!e.target.value) {
            setPromoCodeError(false);
            setTotalPrice(price);
            setDiscoutPrice(0);
            setCoupon(undefined);
        }
    }, [price]);

    const handleApplyPromoCode = useCallback(async (code: string) => {
        if (salesDetail?.linkUuid && code) {
            setPromoLoading(true);

            try {
                const res = await applyPromocode(salesDetail.linkUuid, code.trim());

                if (res && !res.error) {
                    const couponData = res.data?.data;

                    if (couponData?.coupon) {
                        setCoupon(couponData);
                        const { percentOff, amountOff } = couponData.coupon;

                        if (percentOff) {
                            setDiscoutPrice(price * (percentOff / 100));
                            setTotalPrice((price * (1 - (percentOff / 100))));
                        } else {
                            const p = price - amountOff;

                            setDiscoutPrice(amountOff || 0);
                            setTotalPrice(p < 0 ? 0 : p);
                        }
                    }
                } else {
                    setPromoCodeErrorMessage(res?.error || defaultErrorMessage);
                    setPromoCodeError(true);
                }
            } catch (e) {
                console.error(e);
            }

            setPromoLoading(false);
        }
    }, [price, salesDetail]);

    useEffect(() => {
        if (inviteEmail) {
            setEmail(inviteEmail);
            const emailVerify = emailRegex.test(inviteEmail);
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [filedName.email]: {
                    error: !emailVerify,
                    message: emailVerify ? '' : 'Please enter the correct email',
                },
            });
        }
        if (promotionCode) {
            setPromoCode(promotionCode);
            handleApplyPromoCode(promotionCode);
        }

        setTotalPrice(price || 0);
    }, [inviteEmail, promotionCode, handleApplyPromoCode, price]);

    const verifyAllField = useCallback(() => {
        return Object.values(fieldsVerifyResult).every((e) => !e.error);
    }, [fieldsVerifyResult]);

    const handleSubmit = useCallback(async (event: any) => {
        event?.preventDefault();

        setShowErrors(true);

        if (verifyAllField() && salesDetail && !promoCodeError) {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet.
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);
            const res = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (res.error) {
                setMainErrorMessage(res.error.message || defaultErrorMessage);
                setShowErrorMessageBox(true);
                console.error(res.error);
            } else {
                const paymentSubmitData = {
                    linkUuid: salesDetail.linkUuid,
                    email,
                    paymentMethodId: res.paymentMethod.id,
                    couponId: coupon?.id,
                    country,
                    postalCode: zipCode,
                };
                try {
                    const resp = await checkoutPayment(paymentSubmitData);

                    if (resp && !resp.error) {
                        setShowPaySuccessTip(true);
                        const detail = resp.data.data;
                        setSalesDetail(detail);
                        const step = detail?.currentStep as ECurrentStep || ECurrentStep.CS;

                        onNext?.(step);
                    } else {
                        setMainErrorMessage(resp.error || defaultErrorMessage);
                        setShowErrorMessageBox(true);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }, [verifyAllField, salesDetail, stripe, elements, email, coupon, setShowPaySuccessTip, onNext, promoCodeError, country, zipCode]);

    const handleInputChange = useCallback((e: any, t: string) => {
        if (t === filedName.cardCvc || t === filedName.cardExpiry || t === filedName.cardNumber) {
            // e.complete && e.error (e.error.message)
            const correct = e.complete && !e.error;
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !correct,
                    message: correct ? '' : e.error?.message,
                },
            });
        } else if (t === filedName.country) {
            setCountry(e);
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !e,
                    message: e ? '' : 'This filed is required',
                },
            });
        } else if (t === filedName.email) {
            setEmail(e.target.value);
            const emailVerify = emailRegex.test(e.target.value);
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !emailVerify,
                    message: emailVerify ? '' : 'Please enter the correct email',
                },
            });
        } else {
            if (t === filedName.zipCode) {
                setZipCode(e.target.value);
            }
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !e?.target?.value,
                    message: e?.target?.value ? '' : 'This filed is required',
                },
            });
        }
    }, [fieldsVerifyResult]);

    useEffect(() => {
        setNextButtonCallback(handleSubmit);
    }, [handleSubmit, setNextButtonCallback]);

    return (
        <div>
            {
                showErrorMessageBox &&
                <div className={s.errorMessageBox}>
                    <div className={s.errorMessageInner}>
                        <div className={s.wrongIcon} />
                        <div className={s.errorText}>{mainErrorMessage}</div>
                        <div className={s.closeIcon} onClick={() => setShowErrorMessageBox(false)} />
                    </div>
                </div>
            }
            <form className={s.formBox}>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Email</div>
                        <div className={s.inputBox}>
                            <Input value={email} onChange={(e) => handleInputChange(e, filedName.email)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.email].error && <div className={s.error}>{fieldsVerifyResult[filedName.email].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Card Number</div>
                        <div className={s.inputBox}>
                            <div className={s.inputBoxInner}>
                                <CardNumberElement className={`${s.input} ${s.inputWithoutBorder}`} onChange={(e) => handleInputChange(e, filedName.cardNumber)} />
                                <div className={s.cardIconList}>
                                    <div className={`${s.card} ${s.mastercard}`} />
                                    <div className={`${s.card} ${s.amex}`} />
                                    <div className={`${s.card} ${s.discover}`} />
                                    <div className={`${s.card} ${s.visa}`} />
                                </div>
                            </div>
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardNumber].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardNumber].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Expiration</div>
                        <div className={s.inputBox}>
                            <CardExpiryElement className={s.input} onChange={(e) => handleInputChange(e, filedName.cardExpiry)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardExpiry].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardExpiry].message}</div>
                        }
                    </div>
                    <div className={s.formItem}>
                        <div className={s.label}>CVC</div>
                        <div className={s.inputBox}>
                            <CardCvcElement className={s.input} onChange={(e) => handleInputChange(e, filedName.cardCvc)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardCvc].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardCvc].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Country</div>
                        <div className={s.inputBox}>
                            <Select
                                showSearch
                                filterOption={(inputValue, option) =>
                                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                }
                                value={country}
                                options={countries}
                                className={s.select}
                                onChange={(e) => handleInputChange(e, filedName.country)}
                            />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.country].error && <div className={s.error}>{fieldsVerifyResult[filedName.country].message}</div>
                        }
                    </div>
                    <div className={s.formItem}>
                        <div className={s.label}>Postal code</div>
                        <div className={s.inputBox}>
                            <Input value={zipCode} type="number" onChange={(e) => handleInputChange(e, filedName.zipCode)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.zipCode].error && <div className={s.error}>{fieldsVerifyResult[filedName.zipCode].message}</div>
                        }
                    </div>
                </div>
                <div className={s.divider} />
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Have a promotion code?</div>
                        <div className={s.inputBox}>
                            <Input value={promoCode} className={s.promoCodeInput} onChange={handlePromoCodeChange} />
                            <Button type="primary" loading={promoLoading} onClick={() => handleApplyPromoCode(promoCode)}>Apply code</Button>
                        </div>
                        {
                            promoCodeError && <div className={s.error}>{promoCodeErrorMessage}</div>
                        }
                    </div>
                </div>
            </form>
            <div className={s.summaryBox}>
                <div className={s.title}>Price summary</div>
                <div className={s.planPrice}>
                    <div className={s.planType}>{`${type} plan/${subPlanType.period}`}</div>
                    <div className={s.planPrice}>{`$${(price / 100).toFixed(2)}`}</div>
                </div>
                {
                    !!discoutPrice && promoCode &&
                    <div className={s.discountPrice}>
                        <div className={s.discountLabel}>Discount:</div>
                        <div className={s.discountPriceValue}>{`-$${(discoutPrice / 100).toFixed(2)}`}</div>
                    </div>
                }
                <div className={s.divider} />
                <div className={s.totalBox}>
                    <div className={s.totalLabel}>Total:</div>
                    <div className={s.totlaPrice}>{`$${(totlaPrice / 100).toFixed(2)}`}</div>
                </div>
            </div>
        </div>
    );
};

export default PaymentForm;
