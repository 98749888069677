import { useCallback, useEffect, useState } from 'react';
import { getProviderConnectedChannels } from 'api/klarityPlan';
import { message } from 'antd';
import { IProviderConnectedChannleResponse } from 'types/klarityPlan';

const useGetProviderConnnectedChannlesInKlarityPlanProcess = ():[IProviderConnectedChannleResponse[], boolean, string] => {
    const [data, setData] = useState<IProviderConnectedChannleResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        try {
            setLoading(true);
            const result = await getProviderConnectedChannels();
            if (result && !result.error) {
                setData(result.data.data);
            } else {
                message.error(result?.error);
                setError(result?.error);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error];
};

export default useGetProviderConnnectedChannlesInKlarityPlanProcess;
