import React from 'react';
import { getHomeInfo } from 'api/provider';
import { HomeInfo } from 'types/provider';
import HomeStore from 'store/Home';

let globalData:HomeInfo;
const useProviderHomeInfo = (isOperation?: boolean):[HomeInfo | undefined, boolean, ()=>void] => {
    const [,setHomeStore] = HomeStore.useStore();
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<HomeInfo>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getHomeInfo();
            if (!result.error) {
                const data:HomeInfo = result?.data?.data;
                globalData = data;
                setItems(data);
                setHomeStore('reload', false);
                setHomeStore('data', data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading && !isOperation) {
            fetchData();
        }
    }, []);

    return [items, loading, fetchData];
};

export default useProviderHomeInfo;
