import React from 'react';
import { Button, Divider, Input, Modal } from 'antd';
import {
    useNavigate,
} from 'react-router-dom';
import s from './s.module.less';
import { isValidEmail } from 'utils/form';
import { Email } from 'constants/contact';
import Check from 'assets/common/check.svg';
import WarningIcon from 'assets/common/warningRed.svg';
import { sendForget } from 'api/public';

type Props = {
    show:boolean;
    onCancel: () => void;
    defaultFailEmail?: string;
};

export default function ForgotPassword(props: Props) {
    const { show, onCancel, defaultFailEmail } = props;
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [hasSend, setHasSend] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (defaultFailEmail && show) {
            setEmail(defaultFailEmail);
        }
    }, [defaultFailEmail, show]);

    const showEmailError = email && !isValidEmail(email);

    const sendForgetFun = async () => {
        setLoading(true);
        const result = await sendForget({
            email,
        });
        if (!result.error) {
            setShowSuccess(true);
            setHasSend(true);
        } else {
            setShowError(true);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (!show) {
            //reset
            setEmail('');
            setShowSuccess(false);
            setHasSend(false);
            setShowError(false);
        }
    }, [show]);
    return (
        <Modal
            open={show}
            className={s.modal}
            title={
                <h2 className={s.headerTitle}>
                    <img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" />
                </h2>}
            onCancel={onCancel}
            footer={null}
            width={500}
        >
            <div className={s.wrap}>

                <div className={s.subTitle}>
                    Forgot password?
                </div>
                {
                    defaultFailEmail && !showSuccess && (
                        <div className={s.errorTips}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.58579 10L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L10 11.4142L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L11.4142 10L12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L10 8.58579L8.70711 7.29289Z" fill="#F87171" />
                                </svg>
                            </span>
                            <p>
                                The link you used has expired for security reasons. Please request a new link to reset password.
                            </p>
                        </div>
                    )
                }

                <div className={s.form}>
                    <div className={s.label}>Enter your email address to reset password</div>
                    <div className={s.value}>
                        <Input
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}
                            size="large"
                            style={{ marginBottom: '12px' }}
                            status={showEmailError ? 'error' : undefined}
                        />
                    </div>

                    <div className={showSuccess ? `${s.success} ${s.successShow}` : s.success}>
                        <img src={Check} />
                        <span>An email with instructions to reset your password has been sent. If you don&apos;t receive the email, please click Resent.</span>
                    </div>

                    <div className={showError ? `${s.error} ${s.errorShow}` : s.error}>
                        <img src={WarningIcon} />
                        <span>We couldn&apos;t find the email you entered in Kiwi Health. Please double-check and try again.</span>
                    </div>

                    <div className={s.btnWrap}>
                        <Button
                            onClick={sendForgetFun}
                            block
                            type="primary"
                            size="large"
                            loading={loading}
                            disabled={!(email && isValidEmail(email))}
                        >
                            {hasSend ? 'Resent' : 'Reset password'}
                        </Button>
                    </div>
                    <div className={s.btnWrap}>
                        <Button
                            block
                            size="large"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className={s.navTips}>
                        Need help?
                        <a href={`mailto:${Email.support}`}> Contact us</a>
                    </div>
                </div>
            </div>
        </Modal>

    );
}
