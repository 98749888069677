import React, { useMemo } from 'react';
import { Button } from 'antd';
import { EStep } from '../../types';
import s from './s.module.less';
import { ButtonType, ButtonHTMLType } from 'antd/es/button';

type Props = {
    nextButtonEnabled?: boolean;
    hideBackButton?: boolean;
    step?: EStep | string,
    onClickNext?:()=>void,
    onClickBack?:()=>void,
    onClickExit?:()=>void,
    onClickSubmit?:()=>void,
    loading?:boolean,
    formId?: string
};

interface IButtonProps {
    type: ButtonType;
    htmlType?: ButtonHTMLType;
    form?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
}

const Footer = (props: Props) => {
    const { step = EStep.RESUME_ENTRY, onClickNext, onClickBack, onClickExit, onClickSubmit, loading, formId, hideBackButton, nextButtonEnabled } = props;

    const renderNextButton = useMemo(() => {
        const buttonText = 'Next';
        let buttonProps: IButtonProps = {
            type: 'primary',
            htmlType: 'submit',
            form: formId,
            disabled: !nextButtonEnabled,
        };

        if (step === EStep.RESUME_ENTRY || step === EStep.UPLOAD_UNIPROFILE || step === EStep.ANALYSIS_RESULT) {
            buttonProps = {
                type: 'primary',
                onClick: onClickNext,
                disabled: !nextButtonEnabled,
            };
        }

        // if (step === EStep.PREVIEW) {
        //     buttonProps = {
        //         loading,
        //         type: 'primary',
        //         onClick: onClickSubmit,
        //     };
        // }

        return <Button {...buttonProps}>{buttonText}</Button>;
    }, [formId, nextButtonEnabled, onClickNext, step]);

    return (
        <div className={s.footer}>
            <div className={s.footerInner}>
                <div>
                    <Button onClick={onClickExit}>Exit</Button>
                    {(step === EStep.RESUME_ENTRY || hideBackButton) ? <span /> : <span className={s.back} onClick={onClickBack}>Back</span>}
                </div>
                {renderNextButton}
            </div>
        </div>
    );
};

export default Footer;
