import { message } from 'antd';
import { getEmailListByType } from 'api/operation/assessment';
import { useCallback, useEffect, useState } from 'react';
import { IEmailListItem } from 'types/operation';

const useGetEmailListByType = (type: string): [IEmailListItem[] | undefined, boolean, string, (type:string) => void] => {
    const [loading, setLoading] = useState(false);
    const [emailList, setEmailList] = useState<IEmailListItem[]>([]);
    const [error, setError] = useState<string>('');

    const fetch = useCallback(async (t: string) => {
        try {
            setLoading(true);

            const res = await getEmailListByType(t);

            if (res && !res.error) {
                setEmailList(res.data.data);
            } else {
                message.error(res?.error);
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetch(type);
    }, [fetch, type]);

    return [emailList, loading, error, fetch];
};

export default useGetEmailListByType;
