import React from 'react';
import { providerBillingList } from 'api/appointmentSetting';
import { IproviderBillingList } from 'types/appointmentSetting';
import { getUserId } from 'utils/localstore';

const initialValues: IproviderBillingList = {
    id: 0,
    providerId: 0,
    bankOrder: 0,
    bankName: '',
    bankRoutingNumber: '',
    accountHolder: '',
    accountNumber: '',
    address: '',
    zip: '',
    state: '',
    channel: '',
};

let globalData:IproviderBillingList[] = [];

const useBilling = (userId?: number):[IproviderBillingList[], boolean, (id?: number)=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<IproviderBillingList[]>(globalData);
    const fetchData = async (id?: number) => {
        try {
            setLoading(true);
            const uid = id || getUserId();
            const result = await providerBillingList(uid!);
            if (!result.error) {
                const data:IproviderBillingList[] = result?.data?.data || [];
                globalData = data;
                if (globalData.length < 3) {
                    const length = 3 - globalData.length;
                    for (let i = 0; i < length; i++) {
                        globalData.push(initialValues);
                    }
                }
                setItems(globalData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || Object.keys(items).length === 0) {
            if (!loading) {
                fetchData(userId);
            }
        }
    }, []);

    return [items, loading, fetchData];
};

export default useBilling;
