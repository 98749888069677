// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__logo--wZ3tU {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
  float: right;
  margin: 16px 0 16px 24px;
}

.s-module__site-layout-background--RnIBU {
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/TemplatePage/s.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,wBAAwB;EACxB,oCAAoC;EAIpC,YAAY;EACZ,wBAAwB;AAJ1B;;AAOA;EACE,gBAAgB;AAClB","sourcesContent":[".logo {\n  float: left;\n  width: 120px;\n  height: 31px;\n  margin: 16px 24px 16px 0;\n  background: rgba(255, 255, 255, 0.3);\n}\n\n.logo {\n  float: right;\n  margin: 16px 0 16px 24px;\n}\n\n.site-layout-background {\n  background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `s-module__logo--wZ3tU`,
	"site-layout-background": `s-module__site-layout-background--RnIBU`
};
export default ___CSS_LOADER_EXPORT___;
