import React from 'react';
import { Button } from 'antd';
import { EStep } from '../../types';
import s from './s.module.less';

type Props = {
    step: EStep,
    onClickNext:()=>void,
    onClickBack:()=>void,
    onClickSaveAndExit:()=>void
    submiting?:boolean
};

const Footer = (props: Props) => {
    const { step = EStep.PROFILE, onClickNext, onClickBack, onClickSaveAndExit, submiting } = props;
    return (
        <div className={s.footer}>
            <div className={s.footerInner}>
                <div>
                    {step !== EStep.PROFILE && <Button onClick={onClickSaveAndExit}>Exit</Button>}
                    {step === EStep.PROFILE ? <span /> : <span className={s.back} onClick={onClickBack}>Back</span>}
                </div>
                <Button loading={submiting} type="primary" onClick={onClickNext}>
                    {step === EStep.PREVIEW ? 'Submit' : 'Next'}
                </Button>
            </div>
        </div>
    );
};

export default Footer;
