import React from 'react';
import { getAddonShoppingCart, getAddonShoppingCartCount } from 'api/ehr';
import { TShoppingCart } from 'types/ehr';

let globalData:TShoppingCart;

const useShoppingCart = ():[TShoppingCart | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TShoppingCart | undefined>(globalData);
    const fetchData = async () => {
        console.log('fetchData');
        if (loading) return;
        try {
            setLoading(true);
            const cartResult = await getAddonShoppingCart();
            const cartCountResult = await getAddonShoppingCartCount();
            const data:TShoppingCart = {
                count: 0,
                items: [],
            };
            if (!cartCountResult.error) {
                const resData = cartCountResult?.data?.data || 0;
                data.count = resData;
            }
            if (!cartResult.error) {
                const resData = cartResult?.data?.data || [];
                data.items = resData;
            }
            globalData = data;
            setItems(data);
            // setLoading(false);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [items, loading, fetchData];
};

export default useShoppingCart;
