import React from 'react';
import { TCollaboratingPhysician } from 'types/common';
import { getCollaboratingPhysicianList } from 'api/ehr';
import { EAddOnProductCode } from 'types/ehr';

let globalData:TCollaboratingPhysician[] = [];

const useCollaboratingPhysician = (productCode?: EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT | EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS | EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION):[TCollaboratingPhysician[], boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TCollaboratingPhysician[]>(globalData);
    const fetchData = async () => {
        if (loading || !productCode) return;
        try {
            setLoading(true);
            const result = await getCollaboratingPhysicianList(productCode);
            if (!result.error) {
                const resData = result?.data?.data || [];
                globalData = resData;
                setItems(resData);
            }
            // setLoading(false);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    React.useEffect(() => {
        if (productCode) {
            fetchData();
        }
    }, [productCode]);

    return [items, loading, fetchData];
};

export default useCollaboratingPhysician;
