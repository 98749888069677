import React from 'react';
import { getPromotionFilterCodes } from 'api/operation';
import { Meta } from 'types/common';

const useOpsPromotionCode = (): [Meta[] | undefined, boolean, () => void] => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<Meta[]>();
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getPromotionFilterCodes();
            if (!result.error) {
                const data: string[] = result.data?.data || [];
                const newData: Meta[] = data?.map((key) => {
                    const ret: Meta = {
                        label: key || "Without any coupon",
                        value: key,
                    }
                    return ret
                })
                setData(newData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [data, loading, fetchData];
};

export default useOpsPromotionCode;
