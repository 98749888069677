import React from 'react';
import ChatBot from 'components/ChatBot';
import { getRandomUser } from 'api/aiChat';
import { Switch } from 'antd';

import s from './s.module.less';

const Page = () => {
    const [proxy, setProxy] = React.useState<boolean>(false);
    React.useEffect(() => {
        const _getRandomUser = async () => {
            const user = await getRandomUser();
        };
        _getRandomUser();
    }, []);

    return (
        <div className={s.wrap}>
            <div>User proxy : <Switch checked={proxy} onChange={setProxy} /></div>
            <ChatBot api={proxy ? 'https://dev.d31frxe1u41op.amplifyapp.com/api/aichat' : 'http://onwish.co//brewery/chat/'} />
        </div>
    );
};

export default Page;
