import React from 'react';
import { getLiveChannelList } from 'api/operation';
import { getChannelList } from 'api/channel';
import { Channel } from 'types/common';

let globalData:Channel[] = [];

const useLiveChannels = (isOperation?:boolean):[Channel[], boolean] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<Channel[]>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const fn = isOperation ? getLiveChannelList : getChannelList;
            const result = await fn();
            if (!result.error) {
                const data:Channel[] = result?.data?.data || [];
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading) {
            fetchData();
        }
    }, []);

    return [items, loading];
};

export default useLiveChannels;
