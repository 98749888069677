import { PF_CHANNEL_ID } from 'constants/common';
import React, { useCallback } from 'react';
import { Channel, ChannelPayPlanTypeEnum, EKlarityPlanLevel } from 'types/common';
import useLiveChannels from './useLiveChannels';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { getProviderKlarityPlanLevel } from 'utils/provider';

const useLiveChannelsWithCategory = (isOperation?: boolean, withPracticeFront?: boolean, withInactiveAndHide?: boolean): [Record<ChannelPayPlanTypeEnum & 'allLiveChannels', Channel[]>, boolean] => {
    const [allLiveChannels, loading] = useLiveChannels(isOperation);
    const [homeInfo, homeInfoLoading] = useProviderHomeInfo(isOperation);
    const myPlanLevel = getProviderKlarityPlanLevel(homeInfo);
    const [items, setItems] = React.useState<Record<ChannelPayPlanTypeEnum & 'allLiveChannels', Channel[]>>({
        [ChannelPayPlanTypeEnum.FREE]: [],
        [ChannelPayPlanTypeEnum.PAID]: [],
        [ChannelPayPlanTypeEnum.PREMIUM]: [],
        [ChannelPayPlanTypeEnum.STARTAR]: [],
        [ChannelPayPlanTypeEnum.PLUS]: [],
        [ChannelPayPlanTypeEnum.PRO]: [],
        allLiveChannels: [],
    });

    const checkIsLocked = useCallback((channel: Channel) => {
        return isOperation ? false : myPlanLevel < channel.klarityPlanLevel;
    }, [isOperation, myPlanLevel]);

    React.useEffect(() => {
        if (allLiveChannels && !loading && !homeInfoLoading) {
            const free = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                return e.payPlanType === ChannelPayPlanTypeEnum.FREE;
            }) || [];
            const paid = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                return e.payPlanType === ChannelPayPlanTypeEnum.PAID;
            }) || [];
            const premium = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                return e.payPlanType === ChannelPayPlanTypeEnum.PREMIUM;
            }) || [];

            const startar = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.STARTER && e.klarityPlanLevel > 0) {
                    return true;
                }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.FREE) {
                //     return true;
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PAID) {
                //     if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.STARTER) {
                //         return true;
                //     }
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                //     if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.STARTER) {
                //         return true;
                //     }
                // }
                return false;
            }) || [];
            const plus = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.PLUS && e.klarityPlanLevel > 0) {
                    return true;
                }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.FREE) {
                //     return true;
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PAID) {
                //     return true;
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                //     if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.PLUS) {
                //         return true;
                //     }
                // }
                return false;
            }) || [];
            const pro = allLiveChannels.filter((e) => {
                if (!withInactiveAndHide && e.activeStatus !== 'A') {
                    return false;
                }
                if (!withInactiveAndHide && !e.isShow) {
                    return false;
                }
                if (!withPracticeFront && `${e.id}` === `${PF_CHANNEL_ID}`) {
                    return false;
                }
                if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.PRO && e.klarityPlanLevel > 0) {
                    return true;
                }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.FREE) {
                //     return true;
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PAID) {
                //     return true;
                // }
                // if (e.payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                //     if (e.klarityPlanLevel !== null && e.klarityPlanLevel !== undefined && e.klarityPlanLevel <= EKlarityPlanLevel.PRO) {
                //         return true;
                //     }
                // }
                return false;
            }) || [];
            setItems({
                allLiveChannels,
                [ChannelPayPlanTypeEnum.FREE]: free,
                [ChannelPayPlanTypeEnum.PAID]: paid,
                [ChannelPayPlanTypeEnum.PREMIUM]: premium,
                [ChannelPayPlanTypeEnum.STARTAR]: startar.map((channel) => {
                    const locked = checkIsLocked(channel);

                    return {
                        ...channel,
                        locked,
                    };
                }).sort((a, b) => {
                    if (a.locked === b.locked) {
                        return 0;
                    }
                    return a.locked ? 1 : -1;
                }),
                [ChannelPayPlanTypeEnum.PLUS]: plus.map((channel) => {
                    const locked = checkIsLocked(channel);

                    return {
                        ...channel,
                        locked,
                    };
                }).sort((a, b) => {
                    if (a.locked === b.locked) {
                        return 0;
                    }
                    return a.locked ? 1 : -1;
                }),
                [ChannelPayPlanTypeEnum.PRO]: pro.map((channel) => {
                    const locked = checkIsLocked(channel);

                    return {
                        ...channel,
                        locked,
                    };
                }).sort((a, b) => {
                    if (a.locked === b.locked) {
                        return 0;
                    }
                    return a.locked ? 1 : -1;
                }),
            });
        }
    }, [allLiveChannels, loading, homeInfoLoading, withPracticeFront, myPlanLevel, checkIsLocked]);

    return [items, loading];
};

export default useLiveChannelsWithCategory;
