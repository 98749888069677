/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { PATH } from 'constants/path';
import { Spin, message } from 'antd';
import { sendVerify } from 'api/public';
import { ISalesProvider } from 'types/provider';
import { maskEmail } from 'utils/common';

interface IProps {
    salesDetail?: ISalesProvider;
}

const Complete = ({
    salesDetail,
}: IProps) => {
    const verify = salesDetail?.accountReview?.verify;
    const linkId = salesDetail?.linkId;
    const loginEmail = salesDetail?.accountReview?.loginEmail || '';
    const [loading, setLoading] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
    const timer = useRef(60);
    const SI = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        if (countDown > 0) {
            SI.current = setInterval(() => {
                setCountDown(countDown - 1);
            }, 1000);
            setResendButtonDisabled(true);
        } else if (SI.current) {
            setResendButtonDisabled(false);
            clearInterval(SI.current);
        }

        return () => {
            if (SI.current) {
                clearInterval(SI.current);
            }
        };
    }, [countDown]);

    const onResent = useCallback(async () => {
        if (!resendButtonDisabled) {
            setLoading(true);
            const result = await sendVerify(loginEmail, 'SCL', linkId);
            if (!result.error) {
                message.success(`An new activation link has been sent to ${maskEmail(loginEmail, 6)}`);
                setCountDown(timer.current);
            } else {
                message.error(result.error);
            }
            setLoading(false);
        }
    }, [resendButtonDisabled, loginEmail, linkId]);

    const redirectToLoginPage = useCallback(() => {
        if (window) {
            const { origin } = window.location;
            window.location.href = `${origin}/${PATH.PROVIDER_LOGIN}`;
        }
    }, []);

    const list = useMemo(() => {
        const defaultChildren = [
            (
                <div className={s.content}>
                    <div className={s.itemTitle}>Log in to Kiwi Health</div>
                    <div className={s.desc}>{`We found your account in Kiwi with ${maskEmail(loginEmail, 6)}. Please use the link below to log in Kiwi, and start to explore premium features.`}</div>
                    <span onClick={redirectToLoginPage} className={s.link}>Log in to Kiwi Health</span>
                </div>
            ),
            (
                <div className={s.content}>
                    <div className={s.itemTitle}>Create a UniProfile</div>
                    <div className={s.desc}>Create your UniProfile if you haven’t set it up. The tutorial below will guide you to create an awesome UniProfile to differentiate your practice from others.</div>
                    <a href="https://www.youtube.com/watch?v=nXXvhwEWFfY" target="_blank" className={s.link} rel="noreferrer">View tutorial video</a>
                </div>
            ),
            // (
            //     <div className={s.content}>
            //         <div className={s.itemTitle}>Join your onboarding call</div>
            //         <div className={s.desc}>{`Your onboarding call is scheduled on ${maskEmail(loginEmail, 6)} with your onboarding specialist. Please create your UniProfile ahead of the meeting to maximize the value of the onboarding workshop.`}</div>
            //     </div>
            // ),
        ];

        if (!verify) {
            defaultChildren.unshift(
                <div className={s.content}>
                    <div className={s.itemTitle}>Verify your email</div>
                    <div className={s.desc}>{`An activation link has been sent to ${maskEmail(loginEmail, 6)}. If you haven’t received it after a few minutes, please check your spam folder.`}</div>
                    <div className={s.instruction}>Didn't get the email? <span onClick={onResent} className={`${s.link} ${resendButtonDisabled ? s.disabled : ''}`}>{resendButtonDisabled ? `Click to resend(${countDown})` : 'Click to resend'}</span></div>
                </div>,
            );
        }

        return defaultChildren;
    }, [loginEmail, redirectToLoginPage, verify, onResent, resendButtonDisabled, countDown]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div className={s.title}>You are all set! 🎉 </div>
                <div className={s.subTitle}>We have link your selected plan to your Kiwi Health account, what’s next?</div>
                <div className={s.list}>
                    {
                        list.map((item, index) => {
                            return (
                                <div key={index} className={s.listItem}>
                                    <div className={s.number}>{index + 1}</div>
                                    {item}
                                </div>
                            );
                        })
                    }
                </div>
            </Spin>
        </div>
    );
};

export default Complete;
