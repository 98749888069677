import React from 'react';
import s from './s.module.less';
import FullLayout from 'components/FullLayout';
import { InitType } from '../../types';
import { getUrlParam, insertUrlParam } from 'utils/common';
import FileUpload from 'components/form/FileUpload';
import TextArea from 'antd/lib/input/TextArea';

const URL_KEY = 'type';
const InitUniProfile = () => {
    const type = getUrlParam(URL_KEY);
    const [currentType, setCurrentType] = React.useState<InitType>(type === InitType.MANUAL ? InitType.MANUAL : InitType.UPLOAD);
    const setType = (t: InitType) => {
        setCurrentType(t);
        insertUrlParam(URL_KEY, t);
    };
    return (
        <div className={s.wrap}>
            {
                currentType === InitType.UPLOAD && (
                    <div className={s.item}>
                        <h2>Create a UniProfile via resume upload</h2>
                        <div className={s.subTitle}>
                            Upload your resume to build a powerful UniProfile across channels. It should include: Name, Contact Info, Title, Healthcare Experience, Education, Current Practice Info. Recognitions and Credentials (NPI, Certificates) are appreciated.
                        </div>
                        <div className={s.uploadWrap}>
                            <FileUpload />
                        </div>
                        <div className={s.text1}>
                            Don&apos;t have a resume ready?
                        </div>
                        <div className={s.text2}>
                            <span
                                onClick={() => {
                                    setType(InitType.MANUAL);
                                }}
                                className={s.link}
                            >
                                Enter info manually
                            </span>
                            {' | '}
                            <span className={s.link}>Use public website profile</span>
                        </div>
                    </div>
                )
            }
            {
                currentType === InitType.MANUAL && (
                    <div className={s.item}>
                        <h2>Create a UniProfile via text input</h2>
                        <div className={s.subTitle}>
                            Type in your professional details manually. Include essential information like name, contact info, healthcare experience, education, current practice, recognitions, and credentials to create a unique UniProfile.
                        </div>
                        <div className={s.inputWrap}>
                            <TextArea
                                rows={8}
                                placeholder={
                                    `Please provide some key details below:\n1. Full Name\n2. Education (schools, degrees, certifications)]\n3. Specialties or Areas of Expertise\n4. Professional Experience (past roles, practices, hospitals)\n5. Any Awards or Acknowledgments\n6. Professional Affiliations
                                `}
                            />
                        </div>
                        <div className={s.text1}>
                            Don&apos;t want to input manually?
                        </div>
                        <div className={s.text2}>
                            <span
                                onClick={() => {
                                    setType(InitType.UPLOAD);
                                }}
                                className={s.link}
                            >
                                Upload a resume
                            </span>
                            {' | '}
                            <span className={s.link}>Use public website profile</span>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default InitUniProfile;
