import { ProfileFormFieldEnum, AdditionalPracticeFormFieldEnum, ServicesFormFieldEnum } from 'pages/CreateUniProfilePage/types';
import { HealthcareExperienceFieldEnum, EducationFieldEnum } from 'types/channelApplicationProvider';
import { ELicenseType } from 'types/common';

export enum CheckLogicEnmu {
    OR = 'or',
    AND = 'and',
    INVERTER = 'inverter',
}

export enum ValueTypeEnum {
    ARRAY = 'array',
    STRING = 'string',
    NUMBER = 'number',
    OBJECT = 'object',
    BOOLEAN = 'boolean',
}

// 空数组的字段说明：
// 这些字段目前不会被AI解析出来 对于新用户，是肯定没完成的，对于老用户，肯定是完成的，不存在一部分字段有值，一部分无值的情况
export const checkedFields = {
    profile: {
        // AI初始化的时候是否绝对为空？
        mustBeEmptyWhenInit: false,
        fields: [
            {
                fieldName: ProfileFormFieldEnum.FIRST_NAME,
                text: 'First Name',
            },
            {
                fieldName: ProfileFormFieldEnum.LAST_NAME,
                text: 'Last Name',
            },
            {
                fieldName: ProfileFormFieldEnum.YEARS_OF_EXPERIENCE,
                text: 'Years of experience in healthcare',
            },
            {
                fieldName: `${ProfileFormFieldEnum.CREDENTIAL}[0]`,
                text: 'Credentials',
            },
            {
                fieldName: ProfileFormFieldEnum.HEADLINE,
                text: 'Headline',
            },
            {
                fieldName: ProfileFormFieldEnum.TEL,
                text: 'Personal phone number',
            },
            {
                fieldName: ProfileFormFieldEnum.INTRO,
                text: 'About me',
            },
            {
                fieldName: ProfileFormFieldEnum.PHOTO,
                text: 'Profile photo',
            },
            {
                fieldName: ProfileFormFieldEnum.PRIMARY_LANGUAGE,
                text: 'Main language',
            },
        ],
    },
    experienceList: {
        mustBeEmptyWhenInit: false,
        isRequired: false,
        isArray: true,
        fields: [
            {
                fieldName: HealthcareExperienceFieldEnum.ORGANIZATION_NAME,
                text: 'Organization name',
            },
            {
                fieldName: HealthcareExperienceFieldEnum.TITLE,
                text: 'Title',
            },
            {
                fieldName: HealthcareExperienceFieldEnum.START_DATE,
                text: 'Start Date',
            },
            // `[$_$].${HealthcareExperienceFieldEnum.ORGANIZATION_NAME}`,
            // `[$_$].${HealthcareExperienceFieldEnum.TITLE}`,
            // `[$_$].${HealthcareExperienceFieldEnum.START_DATE}`,
            // HealthcareExperienceFieldEnum.END_DATE,
        ],
    },
    educationList: {
        mustBeEmptyWhenInit: false,
        isRequired: false,
        isArray: true,
        fields: [
            {
                fieldName: EducationFieldEnum.SCHOOL_NAME,
                text: 'School name',
            },
            {
                fieldName: EducationFieldEnum.MAJOR,
                text: 'Major',
            },
            {
                fieldName: EducationFieldEnum.DEGREE,
                text: 'Degree',
            },
            {
                fieldName: EducationFieldEnum.START_DATE,
                text: 'Start Date',
            },
            // `[$_$].${EducationFieldEnum.SCHOOL_NAME}`,
            // `[$_$].${EducationFieldEnum.MAJOR}`,
            // `[$_$].${EducationFieldEnum.DEGREE}`,
            // `[$_$].${EducationFieldEnum.START_DATE}`,
            // EducationFieldEnum.END_DATE,
        ],
    },
    practice: {
        mustBeEmptyWhenInit: false,
        fields: [
            // 'addressList[$_$].practiceAddress',
            {
                fieldName: 'specialtyList[0]',
                text: 'Specialties',
            },
            {
                fieldName: 'conditionTreatedList[0]',
                text: 'Conditions treated',
            },
            {
                fieldName: 'addressList[0].practiceAddress',
                text: 'Practice address',
                anchorId: 'practiceAddress',
            },
            // {
            //     type: ValueTypeEnum.ARRAY,
            //     fieldName: 'specialtyList',
            // },
            // {
            //     type: ValueTypeEnum.ARRAY,
            //     fieldName: 'conditionTreatedList',
            // },
            // AdditionalPracticeFormFieldEnum.NAME,
            // AdditionalPracticeFormFieldEnum.TEL,
        ],
    },
    licenses: {
        // mustBeEmptyWhenInit: true,
        // isArray: true,
        fields: [
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].state`,
                text: 'License state',
                anchorId: 'state',
            },
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].licenseNumber`,
                text: 'Practice license number',
                anchorId: 'licenseNumber',
            },
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].expireDate`,
                text: 'Expiration date',
                anchorId: 'expireDate',
            },
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].type`,
                text: 'License name',
                anchorId: 'type',
            },
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].secondType`,
                text: 'License type',
                anchorId: 'secondType',
            },
            {
                fieldName: `${ELicenseType.PRACTICE}[$_$].url`,
                text: 'Upload the License',
                anchorId: 'url',
            },
            // 'state',
            // 'licenseNumber',
            // 'expireDate',
            // 'type',
            // 'secondType',
            // 'url',
        ],
    },
    services: {
        mustBeEmptyWhenInit: false,
        isRequired: true,
        isArray: true,
        fields: [
            {
                fieldName: ServicesFormFieldEnum.SERVICE_TYPE,
                text: 'Service Type',
            },
            {
                fieldName: ServicesFormFieldEnum.NAME,
                text: 'Service Name',
            },
            {
                fieldName: ServicesFormFieldEnum.DURATION,
                text: 'Duration',
            },
            {
                fieldName: ServicesFormFieldEnum.PRICE,
                text: 'Price',
            },
            // `[$_$].${ServicesFormFieldEnum.SERVICE_TYPE}`,
            // `[$_$].${ServicesFormFieldEnum.NAME}`,
            // `[$_$].${ServicesFormFieldEnum.DURATION}`,
            // `[$_$].${ServicesFormFieldEnum.PRICE}`,
            // 'addressList[0].practiceAddress',
            // ServicesFormFieldEnum.LOWEST_PRICE,
            // ServicesFormFieldEnum.HIGHEST_PRICE,
            // {
            //     type: CheckLogicEnmu.OR,
            //     fields: [
            //         {
            //             type: ValueTypeEnum.ARRAY,
            //             fieldName: ServicesFormFieldEnum.TELEHEALTH_TYPE,
            //         },
            //         {
            //             type: CheckLogicEnmu.AND,
            //             fields: [
            //                 {
            //                     type: ValueTypeEnum.NUMBER,
            //                     fieldName: ServicesFormFieldEnum.ADDRESS_ID,
            //                 },
            //                 {
            //                     type: ValueTypeEnum.ARRAY,
            //                     fieldName: ServicesFormFieldEnum.PERSON_TYPE,
            //                 },
            //             ],
            //         },
            //     ],
            // },
            // ServicesFormFieldEnum.ADDRESS_ID,
        ],
    },

};

export type TypeCheckFiledList = typeof checkedFields;
