import { useEffect } from 'react';
import { EStep } from '../types';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY } from 'constants/common';

const useStoreCurrentStep = (currentStep: EStep | string) => {
    useEffect(() => {
        sessionStorage.setItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, currentStep.toString());
    }, [currentStep]);
};

export default useStoreCurrentStep;
