import { Checkbox } from 'antd';
import s from './s.module.less';
import React from 'react';

interface IData {
    id: number;
    name: string;
    parentId: number | null;
    path: string;
    type: string;
    checked: boolean;
    children: Array<IData>
}

const getColumnData = (data:IData[] = []) => {
    const columns = data.map((item: IData) => {
        const { name, path, children } = item;
        const column = {
            title: name,
            editable: true,
        };

        if (children && children.length > 0) {
            const childrenColumns = getColumnData(children);

            column.children = childrenColumns;
        } else {
            column.dataIndex = path;
            column.key = path;
            column.align = 'center';
            column.render = (item) => {
                return (
                    <div className={s.cellCheckbox}>
                        <Checkbox checked={item} disabled />
                    </div>
                );
            };
        }

        return column;
    });

    return columns;
};

export default getColumnData;
