import { useCallback, useEffect, useState } from 'react';
import { getUserId } from 'utils/localstore';
import { providerFetch } from 'api/provider';
import { Provider } from 'types/provider';

const useProviderFetch = ():[Provider | undefined, boolean | null, string, () => void] => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [data, setData] = useState<Provider | undefined>();
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await providerFetch(getUserId()!);

            if (res && !res.error) {
                const providerData = res?.data?.data;

                setData(providerData);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useProviderFetch;
