import React from 'react';
import { providerAvailabilityList } from 'api/appointmentSetting';
import { IproviderAvailabilityList } from 'types/appointmentSetting';
import { getUserId } from 'utils/localstore';

let globalData:IproviderAvailabilityList = {};

const initData:IproviderAvailabilityList = {
    providerId: 0,
    timezone: '',
    week: [
        {
            weekDay: 'sunday',
            hourFromTo: [],
        },
        {
            weekDay: 'monday',
            hourFromTo: [],
        },
        {
            weekDay: 'tuesday',
            hourFromTo: [],
        },
        {
            weekDay: 'wednesday',
            hourFromTo: [],
        },
        {
            weekDay: 'thursday',
            hourFromTo: [],
        },
        {
            weekDay: 'friday',
            hourFromTo: [],
        },
        {
            weekDay: 'saturday',
            hourFromTo: [],
        },
    ],
};

const useAvailability = ():[IproviderAvailabilityList, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<IproviderAvailabilityList>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const uid = getUserId();
            const result = await providerAvailabilityList(uid);
            if (!result.error) {
                const data:IproviderAvailabilityList = result?.data?.data || initData;
                globalData = data;
                setItems(globalData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [items, loading, fetchData];
};

export default useAvailability;
