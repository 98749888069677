import React from 'react';
import { getAiEditorConfig } from 'api/ai';
import { opsGetAiEditorConfig } from 'api/operation';
import { TConfigModule } from 'types/ai';

let globalData:TConfigModule[] = [];

const useAiConfigModule = (isOps?:boolean):[TConfigModule[], boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TConfigModule[]>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const api = isOps ? opsGetAiEditorConfig : getAiEditorConfig;
            const result = await api();
            if (!result.error) {
                const data:TConfigModule[] = result?.data?.data || [];
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || Object.keys(items).length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [items, loading, fetchData];
};

export default useAiConfigModule;
