import { useCallback, useEffect, useState } from 'react';
import { getSpecialtiesAndConditionList } from 'api/applicationForm';
import { getSpecialtiesAndConditionList as getAdminSpecialtiesAndConditionList } from 'api/operation';
import { ISpecialtiesOptions } from 'types/applicationForm';

interface IProps {
    isOps: boolean;
}

const useGetSpecialtiesAndConditions = ({
    isOps = false,
}: IProps) => {
    const [options, setOptions] = useState<ISpecialtiesOptions>([]);

    const fetchData = useCallback(async () => {
        let res;

        if (isOps) {
            res = await getAdminSpecialtiesAndConditionList();
        } else {
            res = await getSpecialtiesAndConditionList();
        }

        if (res && !res.error) {
            const data = res.data?.data || {};
            const result:ISpecialtiesOptions = Object.keys(data).reduce((r: ISpecialtiesOptions, k: string) => {
                const opt = {
                    label: k,
                    value: k,
                    children: data[k].map((item: string) => ({
                        label: item.trim(),
                        value: item.trim(),
                    })),
                };

                r.push(opt);

                return r;
            }, []);

            setOptions(result);
        }
    }, [isOps]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [options];
};

export default useGetSpecialtiesAndConditions;
