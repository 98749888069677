import { useCallback } from 'react';
import transfromPermission from 'utils/transformPermission';
import AccessPermissionStore from 'store/Access/permission';
import AccessRoleStore from 'store/Access/role';
import { Permission } from 'types/access';

const useSyncAccess = ():[(privList?: Permission[]) => void, (role?: string[]) => void] => {
    const [,setPermissionStore] = AccessPermissionStore.useStore();
    const [,setRoleStore] = AccessRoleStore.useStore();

    const saveAccess = useCallback((privList: Permission[] = []) => {
        const permissions = transfromPermission(privList);

        setPermissionStore('data', permissions);
    }, []);

    const saveRole = useCallback((role: string[] = []) => {
        setRoleStore('data', role);
    }, []);

    return [saveAccess, saveRole];
};

export default useSyncAccess;
