import React from 'react';
import { getUserId } from 'utils/localstore';
import { getOpsUserInfo } from 'api/operation';
import { TOpsUserInfo } from 'types/operation';

const useOpsUserInfo = (id?: number):[TOpsUserInfo | undefined, boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<TOpsUserInfo>();
    const fetchData = async () => {
        try {
            const uid = id || getUserId();
            if (!uid) {
                return;
            }
            setLoading(true);
            const result = await getOpsUserInfo(uid);
            if (!result.error) {
                setData(result.data?.data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (id || getUserId()) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [data, loading, fetchData];
};

export default useOpsUserInfo;
