import React from 'react';
import s from './s.module.less';
import Img from './PageNotFound.svg';
import { Button } from 'antd';
import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import {
    useNavigate,
} from 'react-router-dom';

export default function Page() {
    const [count, setCount] = React.useState(10);
    const navigate = useNavigate();
    React.useEffect(() => {
        const timer = setInterval(() => {
            setCount((c) => {
                if (c <= 0) {
                    clearTimeout(timer);
                    navigate('/');
                    window.location.reload();
                }
                return c - 1;
            });
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <div className={s.wrap}>
            {/* <h2><img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" /></h2> */}
            <h2><img src={Logo} /></h2>
            <div className={s.imgWrap}>
                <img src={Img} />
            </div>
            <h1>Sorry we cannot find that page</h1>
            <p>Back home in {count} seconds.</p>
            <Button
                className={s.btn}
                size="small"
                onClick={() => {
                    navigate('/');
                    window.location.reload();
                }}
            >
                Back to home page
            </Button>
        </div>
    );
}
