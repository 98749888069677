import React, { useCallback } from 'react';
import s from './s.module.less';
import { Form, Radio, Space } from 'antd';
import { ISalesCreateNewAccount, ISalesProvider } from 'types/provider';

interface IProps {
    formId: string;
    salesDetail?: ISalesProvider;
    handleFormSubmit: (formData: ISalesCreateNewAccount) => void;
    emailList?: string[];
}

const ConfirmAccount = ({
    formId,
    handleFormSubmit,
    salesDetail,
    emailList,
}: IProps) => {
    const emails = emailList?.filter((e) => !!e) || [];
    const [formInstance] = Form.useForm<ISalesCreateNewAccount>();

    const handleFormFinish = useCallback(() => {
        if (salesDetail?.linkUuid) {
            formInstance.validateFields().then((formData: ISalesCreateNewAccount) => {
                handleFormSubmit(formData);
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [formInstance, handleFormSubmit, salesDetail]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Confirm your Kiwi Health</div>
            <div className={s.subTitle}>We found you have two account with us</div>
            <Form
                id={formId}
                form={formInstance}
                layout="vertical"
                onFinish={handleFormFinish}
            >
                <Form.Item
                    label="Please choose one as your permanent Kiwi Health account"
                    className={s.formItem}
                    name="email"
                    style={{ width: '450px' }}
                    rules={[
                        { required: true, message: 'Please choose one Kiwi Health account' },
                    ]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            {
                                emails.map((value, index) => {
                                    return <Radio key={index} value={value}>{value}</Radio>;
                                })
                            }
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ConfirmAccount;
