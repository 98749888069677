import React from 'react';
import { getKiwiPageList } from 'api/operation';
import { TPage } from 'types/kiwiHealth';

let globalData:TPage[] = [];

const useKiwiPage = (isOps?: boolean):[TPage[], boolean, ()=>void] => {
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<TPage[]>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const fn = isOps ? getKiwiPageList : getKiwiPageList;
            const result = await fn();
            if (!result.error) {
                const data:TPage[] = result?.data?.data || [];
                globalData = data;
                setItems(data);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!items || items.length === 0) {
            if (!loading) {
                fetchData();
            }
        }
    }, []);

    return [items, loading, fetchData];
};

export default useKiwiPage;
