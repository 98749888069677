import { submitRecommandChannels } from 'api/channel';
import { useCallback, useState } from 'react';
import { Service } from 'types/common';

interface IInput {
    channelIdList: Array<number>;
    multServiceList: Array<Service>;
}

const useSubmitRecommendChannels = (): [(props: IInput) => Record<string, any>, boolean] => {
    const [loading, setLoading] = useState(false);

    const submit = useCallback(async ({ channelIdList, multServiceList }: IInput) => {
        if (channelIdList.length > 0) {
            setLoading(true);
            const res = await submitRecommandChannels({
                channelIdList,
                multServiceList: multServiceList.map((service) => {
                    const { description, id, name, type } = service;

                    return {
                        description,
                        id,
                        name,
                        type,
                    };
                }),
            });
            setLoading(false);

            return res;
        }
    }, []);

    return [submit, loading];
};

export default useSubmitRecommendChannels;
