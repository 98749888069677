import { useCallback, useEffect, useState } from 'react';
import { getSurveyEmailTemplate } from 'api/survey';

const useGetSurveyEmailTemplate = ():[{ subject: string, content: string } | undefined, boolean | null, string, () => {}] => {
    const [loading, setLoading] = useState<boolean | null>(null);
    const [data, setData] = useState<{ subject: string, content: string } | undefined>();
    const [error, setError] = useState('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getSurveyEmailTemplate();

            if (res && !res.error) {
                const emialTemplateData = res.data?.data;

                setData(emialTemplateData);
            } else {
                setError(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [data, loading, error, fetch];
};

export default useGetSurveyEmailTemplate;
