import React from 'react';
import { Form, Spin, message, Modal, Radio, Space } from 'antd';
import { providerBasicInfoUpdate, providerFetch, providerLicenseFetch, providerPhysicianFetch } from 'api/provider';
import ProviderStore from 'store/Provider';
import { ProfileFormFieldEnum, ProfileFormInterface, FormMutipleQualificationFormFieldEnumInterface, PreviewFormInterface, ProviderBasicInfo, EProviderInfoStatus, Provider } from 'types/provider';
import { EStep } from './types';
import s from './s.module.less';
import Header from './components/Header';
import Footer from './components/Footer';
import ProfileForm from './components/Profile';
import { getNewEditableStatus, jumpToFormError } from 'utils/common';
import {
    useNavigate,
} from 'react-router-dom';
import QualificationForm from './components/Qualification';
import PreviewForm from 'components/onboarding/Preview';
import FinishPage from './components/Finish';
import { scrollTop } from 'utils/ui';
import { PATH } from 'constants/path';
import { transferProviderToFormData } from 'data/provider';

const Page = () => {
    const [getUser, setUser] = ProviderStore.useStore();
    const user = getUser('data');
    const [profileForm] = Form.useForm<ProfileFormInterface>();
    const [qualificationForm] = Form.useForm<FormMutipleQualificationFormFieldEnumInterface>();
    const [previewForm] = Form.useForm<PreviewFormInterface>();
    const [currentStep, setCurrentStep] = React.useState(EStep.PROFILE);
    const navigate = useNavigate();
    const [submiting, setSubmiting] = React.useState(false);
    const [loading, setLoading] = React.useState(false); //loading basic info
    const [showSubmitConfirm, setShowSubmitConfirm] = React.useState(false);
    const [allowEdit, setAllowEdit] = React.useState(true);
    const uid = user?.id;

    React.useEffect(() => {
        const fetchInfo = async () => {
            setLoading(true);
            const result = await providerFetch(uid!);
            const licenseListResult = await providerLicenseFetch(uid!);
            const physicianListResult = await providerPhysicianFetch(uid!);
            if (!result.error) {
                try {
                // eslint-disable-next-line prefer-destructuring
                    const data:Provider = result.data.data;
                    if (!licenseListResult.error) {
                        data.licenseList = licenseListResult.data?.data || [];
                    }
                    if (!physicianListResult.error) {
                        data.physicianData = physicianListResult.data?.data || [];
                    }
                    const formData = transferProviderToFormData(data);
                    profileForm.setFieldsValue(formData.basic);
                    const hasQualifications = formData.qualifications && formData.qualifications.length > 0;
                    const qFormValues = hasQualifications ? { qualifications: formData.qualifications } : { qualifications: [{}] };
                    qualificationForm.setFieldsValue(qFormValues);
                    previewForm.setFieldsValue({
                        ...formData.basic,
                        qualifications: formData.qualifications,
                    });
                    if (!data.status) {
                        //init
                        const basicData:Partial<ProviderBasicInfo> = {
                            npi: data.npi,
                            state: data.state,
                            city: data.city,
                            yearExp: data.yearExp,
                            zip: data.zip,
                            address: data.address,
                            specialityInHealth: data.specialityInHealth,
                            specialityInFamily: data.specialityInFamily,
                            status: EProviderInfoStatus.IN_PROGRESS,
                        };
                        await providerBasicInfoUpdate(basicData, uid!);
                    }
                    setUser('data', data);
                    if (data.status && [EProviderInfoStatus.SUBMITTED_APPLICATION, EProviderInfoStatus.APPLICATION_APPROVED, EProviderInfoStatus.BP_ACCOUNT_ENTERED].includes(data.status)) {
                        setCurrentStep(EStep.FINISH);
                    } else if (hasQualifications) {
                        setCurrentStep(EStep.QUALIFICATION);
                    }
                } catch (e) {
                    message.error('Data error');
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            }
        };
        if (uid) {
            fetchInfo();
        } else {
            navigate(PATH.HOME);
        }
    }, [uid]);

    const onSaveExit = async () => {
        navigate(PATH.SAVED_DRAFT);
    };

    const onNext = async () => {
        if (currentStep === EStep.PROFILE) {
            try {
                const profileFormValues = await profileForm.validateFields();
                setSubmiting(true);
                const basicData:Partial<ProviderBasicInfo> = {
                    address: profileFormValues[ProfileFormFieldEnum.MAILING_ADDRESS],
                    specialityInFamily: profileFormValues[ProfileFormFieldEnum.SPECIALITYINFAMILY]?.join(','),
                    specialityInHealth: profileFormValues[ProfileFormFieldEnum.SPECIALITYINHEALTH]?.join(','),
                    npi: profileFormValues[ProfileFormFieldEnum.NPI_NUMBER],
                    state: profileFormValues[ProfileFormFieldEnum.STATE],
                    yearExp: profileFormValues[ProfileFormFieldEnum.YEARS_OF_EXPERIENCE],
                    zip: profileFormValues[ProfileFormFieldEnum.ZIP_CODE],
                    status: EProviderInfoStatus.IN_PROGRESS,
                    city: profileFormValues[ProfileFormFieldEnum.CITY],
                };
                const result = await providerBasicInfoUpdate(basicData, uid!);
                if (!result.error) {
                    message.success('Basic profile info has updated');
                    setCurrentStep(EStep.QUALIFICATION);
                } else {
                    message.error(result.error);
                }
            } catch (e:any) {
                if (e.errorFields) {
                    //form error
                    jumpToFormError();
                    return;
                }
                const msg = e?.toString?.() || 'data error';
                message.error(msg);
            } finally {
                setSubmiting(false);
            }
        }
        if (currentStep === EStep.QUALIFICATION) {
            try {
                const qualificationFormValues = await qualificationForm.validateFields();
                message.success('Qualification profile info has updated');
                previewForm.setFieldsValue({
                    ...profileForm.getFieldsValue(true),
                    ...qualificationFormValues,
                });
                setSubmiting(true);
                const profileFormValues = await profileForm.getFieldsValue(true);
                const basicData:Partial<ProviderBasicInfo> = {
                    address: profileFormValues[ProfileFormFieldEnum.MAILING_ADDRESS],
                    specialityInFamily: profileFormValues[ProfileFormFieldEnum.SPECIALITYINFAMILY]?.join(','),
                    specialityInHealth: profileFormValues[ProfileFormFieldEnum.SPECIALITYINHEALTH]?.join(','),
                    npi: profileFormValues[ProfileFormFieldEnum.NPI_NUMBER],
                    city: profileFormValues[ProfileFormFieldEnum.CITY],
                    state: profileFormValues[ProfileFormFieldEnum.STATE],
                    yearExp: profileFormValues[ProfileFormFieldEnum.YEARS_OF_EXPERIENCE],
                    zip: profileFormValues[ProfileFormFieldEnum.ZIP_CODE],
                    status: EProviderInfoStatus.IN_PROGRESS,
                };
                await providerBasicInfoUpdate(basicData, uid!);
                setCurrentStep(EStep.PREVIEW);
            } catch (e:any) {
                if (e.errorFields) {
                    //form error
                    jumpToFormError();
                    return;
                }
                const msg = e?.toString?.() || 'data error';
                message.error(msg);
            } finally {
                setSubmiting(false);
            }
        }
        if (currentStep === EStep.PREVIEW) {
            setShowSubmitConfirm(true);
        }
    };
    const doSubmit = async () => {
        try {
            const profileFormValues = await profileForm.getFieldsValue(true);
            const newEditable:string | undefined = getNewEditableStatus({ currentEditable: user?.editable, allowEditOnBoarding: allowEdit });

            const basicData:Partial<ProviderBasicInfo> = {
                address: profileFormValues[ProfileFormFieldEnum.MAILING_ADDRESS],
                city: profileFormValues[ProfileFormFieldEnum.CITY],
                specialityInFamily: profileFormValues[ProfileFormFieldEnum.SPECIALITYINFAMILY]?.join(','),
                specialityInHealth: profileFormValues[ProfileFormFieldEnum.SPECIALITYINHEALTH]?.join(','),
                npi: profileFormValues[ProfileFormFieldEnum.NPI_NUMBER],
                state: profileFormValues[ProfileFormFieldEnum.STATE],
                yearExp: profileFormValues[ProfileFormFieldEnum.YEARS_OF_EXPERIENCE],
                zip: profileFormValues[ProfileFormFieldEnum.ZIP_CODE],
                status: EProviderInfoStatus.SUBMITTED_APPLICATION,
                editable: newEditable,
            };
            setSubmiting(true);
            const updateResult = await providerBasicInfoUpdate(basicData, uid!);
            if (!updateResult.error) {
                message.success('Your documents has submited to review!');
                setCurrentStep(EStep.FINISH);
                setShowSubmitConfirm(false);
            } else {
                message.error(updateResult.error);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setSubmiting(false);
        }
    };
    React.useEffect(() => {
        scrollTop();
    }, [currentStep]);

    return (
        <div className={s.wrap}>
            <div className={s.wrapInner}>
                <Header step={currentStep} />
                <Spin spinning={submiting || loading}>
                    <div className={s.content}>
                        {currentStep === EStep.PROFILE && <ProfileForm form={profileForm} />}
                        {currentStep === EStep.QUALIFICATION && <QualificationForm form={qualificationForm} />}
                        {currentStep === EStep.PREVIEW && <PreviewForm form={previewForm} providerId={user?.id!} />}
                        {currentStep === EStep.FINISH && <FinishPage />}
                    </div>
                </Spin>
                {currentStep !== EStep.FINISH && <Footer
                    step={currentStep}
                    submiting={submiting}
                    onClickSaveAndExit={onSaveExit}
                    onClickNext={onNext}
                    onClickBack={
                        () => {
                            if (currentStep === EStep.QUALIFICATION) {
                                setCurrentStep(EStep.PROFILE);
                            }
                            if (currentStep === EStep.PREVIEW) {
                                setCurrentStep(EStep.QUALIFICATION);
                            }
                        }
                    }
                />
                }
                <Modal
                    title="Confirm submission"
                    closable
                    width="600px"
                    destroyOnClose
                    onCancel={() => {
                        setShowSubmitConfirm(false);
                        setAllowEdit(true);
                    }}
                    okText="Submit"
                    open={showSubmitConfirm}
                    onOk={() => {
                        doSubmit();
                    }}
                    okButtonProps={{
                        loading: submiting,
                    }}
                >
                    <div className={s.modalWrap}>
                        <p>
                            After submitting, you will not be able to change all the files you&apos;ve inputted in this process. Make sure you have checked all the information before you submit your application.
                        </p>
                        <p>
                            During the review of your application, there may be missing or incorrect information. Would you like to give Kiwi Health&apos;s Provider Support team permission to edit or improve your profile? You can always edit your preferences at a later time.
                        </p>
                        <div className={s.selectWrap}>
                            <Radio.Group
                                onChange={(e) => {
                                    setAllowEdit(e.target.value);
                                }}
                                value={allowEdit}
                            >
                                <Space direction="vertical">
                                    <Radio value>Yes, I authorize the Provider Support team to edit my profile</Radio>
                                    <Radio value={false}>No, I prefer to edit my profile myself</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Page;
