import { useCallback, useEffect, useState } from 'react';
import { getProviderFormDetail } from 'api/applicationForm';
import channelProviderStore from 'store/ChannelApplicationProvider';
import { transferProviderToFormData } from 'utils/channel';

interface IParams {
    ignore?:boolean;
    channelId?: string | number;
    providerId?: string | number;
}

const useFetchProvider = ({ ignore }: IParams) => {
    const [, setProvider] = channelProviderStore.useStore();
    const [loading, setLoading] = useState(false);

    const fetchChannelProviderData = useCallback(async () => {
        setLoading(true);
        const res = await getProviderFormDetail({
            allowAuth: false,
            // channelId: cid,
            // providerId: pid,
            step: 'REVIEW',
        });
        if (!res.error) {
            setProvider('data', transferProviderToFormData(res.data?.data)!);
        } else {
            console.error(res);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!ignore) {
            fetchChannelProviderData();
        }
    }, [fetchChannelProviderData, ignore]);

    return { refetch: fetchChannelProviderData, loading };
};

export default useFetchProvider;
