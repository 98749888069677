import { useCallback, useEffect, useState } from 'react';
import { getKlarityRecommandChannels } from 'api/channel';
import { message } from 'antd';
import { Channel } from 'types/common';

const useGetKlarityRecommandChannels = ():[Channel[], boolean, string] => {
    const [channels, setChannels] = useState<Channel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getKlarityRecommandChannels();

            if (res && !res.error) {
                setChannels(res.data.data);
            } else {
                message.error(res?.error);
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return [channels, loading, error];
};
export default useGetKlarityRecommandChannels;
