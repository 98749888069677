// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--ZfgDE {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.s-module__wrap--ZfgDE h2 img {
  height: 44px;
}
.s-module__wrap--ZfgDE .s-module__imgWrap--mmW77 img {
  width: 300px;
  margin: 48px;
}
.s-module__wrap--ZfgDE h1 {
  color: var(--gray-900);
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 36px;
}
.s-module__wrap--ZfgDE p {
  max-width: 900px;
  width: 90%;
  color: var(--gray-500);
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}
.s-module__wrap--ZfgDE .s-module__btn--QxOWy {
  margin-top: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SomethingWentWrongPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AANA;EAOI,YAAA;AAEJ;AATA;EAUI,YAAA;EACA,YAAA;AAEJ;AAbA;EAcI,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAnBA;EAoBI,gBAAA;EACA,UAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AA3BA;EA4BI,gBAAA;AAEJ","sourcesContent":[".wrap{\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  h2 img {\n    height: 44px;\n  }\n  .imgWrap img {\n    width: 300px;\n    margin: 48px;\n  }\n  h1{\n    color: var(--gray-900);\n    font-size: 38px;\n    font-weight: bold;\n    margin-bottom: 36px;\n  }\n  p {\n    max-width: 900px;\n    width: 90%;\n    color: var(--gray-500);\n    font-size: 18px;\n    line-height: 20px;\n    text-align: center;\n  }\n  .btn{\n    margin-top: 36px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--ZfgDE`,
	"imgWrap": `s-module__imgWrap--mmW77`,
	"btn": `s-module__btn--QxOWy`
};
export default ___CSS_LOADER_EXPORT___;
