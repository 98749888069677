import { getCheckFieldsResult } from 'utils/uniprofile';
import { StepValue } from 'pages/UniprofileResume/types';
import { UniprofileFormData } from 'types/provider';
import stepPercentageStore from 'store/UniprofileResume/stepPercentage';
// import uniprofileResumeStore from 'store/UniprofileResume';
import { useCallback, useEffect } from 'react';
import { steps } from 'components/UniprofileResume/AnalysisResult';

const useSetPercentages = () => {
    // const [getResumeInfo] = uniprofileResumeStore.useStore();
    // const resumeData = getResumeInfo('data');
    const [,setPercentageStore] = stepPercentageStore.useStore();

    const setPercentage = useCallback((resumeData) => {
        const percentageArray: Record<string, {
            percent: number,
            unfinished: []
        }>[] = steps.map((fieldName) => {
            const res = getCheckFieldsResult(resumeData, fieldName);

            return {
                [fieldName]: {
                    percent: res.percent,
                    unfinished: res.unfinished,
                },
            };
        });

        const percenageStoreData = percentageArray.reduce((r, c) => {
            const [k, v] = Object.entries(c)[0];
            r[k] = v;
            return r;
        }, {});

        setPercentageStore('data', percenageStoreData);
    }, [setPercentageStore]);

    return [setPercentage];
};

export default useSetPercentages;
