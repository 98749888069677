import { Email } from 'constants/contact';
import React from 'react';

import DisplayImg from './display.png';
import s from './s.module.less';

const Page = () => {
    return (
        <div className={s.wrap}>
            <div>
                <img src={DisplayImg} />
            </div>
            <p className={s.p1}>Thank you for completing the application!</p>
            <p className={s.p2}>Kiwi Health service team will review your documents and contact you in 5 business days. </p>
            <p className={s.p3}>Have questions? <a href={`mailto:${Email.support}`}>Contact us</a></p>
        </div>
    );
};

export default Page;
