// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Ocbq1 {
  min-height: calc(100vh - 148px);
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  color: var(--gray-900);
  padding: 36px 0;
}
.s-module__wrap--Ocbq1 h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 36px;
}
.s-module__wrap--Ocbq1 .s-module__subTitle--vb2ow {
  color: #6B7280;
  font-size: 18px;
  line-height: 32px;
  max-width: 80%;
}
.s-module__wrap--Ocbq1 .s-module__inputWrap--EpYto {
  margin-top: 36px;
}
.s-module__wrap--Ocbq1 .s-module__content--MoumB {
  padding: 16px;
  background: #f9fafc;
  display: flex;
  flex-direction: row;
}
.s-module__wrap--Ocbq1 .s-module__content--MoumB .s-module__left--kva_T {
  width: 360px;
  flex-shrink: 0;
  padding: 16px;
}
.s-module__wrap--Ocbq1 .s-module__content--MoumB .s-module__right--hjcoz {
  flex-grow: 1;
  padding: 16px;
}
.s-module__wrap--Ocbq1 .s-module__item--ZNBvd {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}
.s-module__wrap--Ocbq1 .s-module__item--ZNBvd img {
  margin-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/InitUniProfilePage/components/Preview/s.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,eAAA;AACF;AAVA;EAYI,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAhBA;EAmBI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAtBA;EA0BI,gBAAA;AADJ;AAzBA;EA8BI,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AA/BA;EAmCM,YAAA;EACA,cAAA;EACA,aAAA;AADN;AApCA;EAwCM,YAAA;EACA,aAAA;AADN;AAxCA;EA6CI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AA9CA;EAkDM,kBAAA;AADN","sourcesContent":[".wrap {\n  min-height: calc(100vh - 148px);\n  display: flex;\n  justify-content: center;\n  width: 80%;\n  max-width: 900px;\n  margin: 0 auto;\n  flex-direction: column;\n  color: var(--gray-900);\n  padding: 36px 0;\n\n  h2 {\n    font-weight: bold;\n    font-size: 36px;\n    line-height: 40px;\n    margin-bottom: 36px;\n  }\n\n  .subTitle {\n    color: #6B7280;\n    font-size: 18px;\n    line-height: 32px;\n    max-width: 80%;\n  }\n\n  .inputWrap {\n    margin-top: 36px;\n  }\n  \n  .content {\n    padding: 16px;\n    background: #f9fafc;\n    display: flex;\n    flex-direction: row;\n    .left {\n      width: 360px;\n      flex-shrink: 0;\n      padding: 16px;\n    }\n    .right {\n      flex-grow: 1;\n      padding: 16px;\n    }\n  }\n  .item{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 32px;\n    img {\n      margin-right: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Ocbq1`,
	"subTitle": `s-module__subTitle--vb2ow`,
	"inputWrap": `s-module__inputWrap--EpYto`,
	"content": `s-module__content--MoumB`,
	"left": `s-module__left--kva_T`,
	"right": `s-module__right--hjcoz`,
	"item": `s-module__item--ZNBvd`
};
export default ___CSS_LOADER_EXPORT___;
