import { getRecommendChannelList } from 'api/channel';
import { useCallback, useEffect, useState } from 'react';
import { IRecommendChannel } from 'types/channel';

const useGetRecommendChannels = ():[Array<IRecommendChannel>, boolean | null, boolean | string, boolean] => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    // null 用于区分boolean，因为在recommend页面里，有个useEffect会判断是否没有推荐channel，如果没有就直接去成功页面
    // 所以这里null代表着api并没有返回过数据，isNonRecommand的值不能作为依据，否则一开始是false的话，就直接跳走了。
    const [isNonRecommand, setIsNonRecommand] = useState<boolean | null>(null);

    const fetchData = useCallback(async () => {
        setIsNonRecommand(null);
        setLoading(true);
        const res = await getRecommendChannelList();

        if (res.error) {
            setError(res.error);
            setData([]);
        } else {
            setError(false);
            setData(res?.data?.data || []);
            setIsNonRecommand(!res.data?.data || res.data?.data?.length === 0);
        }

        setLoading(false);
    }, []);
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [data, isNonRecommand, error, loading];
};

export default useGetRecommendChannels;
