import React from 'react';
import s from './s.module.less';
import Img from './SomethingWentWrong.svg';
import { Button } from 'antd';
import {
    useNavigate,
} from 'react-router-dom';
import { Email } from 'constants/contact';

export default function Page() {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <h2><img src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_kiwihealth_by_klarity_380x96.png" /></h2>
            <div className={s.imgWrap}>
                <img src={Img} />
            </div>
            <h1>Ops, something went wrong</h1>
            <p>Please refresh the page to try again.
                If the problem still persists, <a href={`mailto:${Email.support}`}> Contact us</a>
            </p>
            <Button
                className={s.btn}
                size="small"
                onClick={() => {
                    navigate('/');
                    window.location.reload();
                }}
            >
                Back to home page
            </Button>
        </div>
    );
}
