import React from 'react';
import s from './s.module.less';
import FullLayout from 'components/FullLayout';
import Init from './components/Init';
import Preview from './components/Preview';

enum EStep {
    INIT = 'init',
    PREVIEW = 'preview',
}

const InitUniProfilePage = () => {
    const [step, setStep] = React.useState<EStep>(EStep.PREVIEW);
    return (
        <FullLayout>
            <div className={s.wrap}>
                { step === EStep.INIT && <Init /> }
                { step === EStep.PREVIEW && <Preview /> }
            </div>
        </FullLayout>
    );
};

export default InitUniProfilePage;
