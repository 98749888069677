import TinyStore from 'lib/tinyStore';
import { Prospect } from 'types/common';

type Modal = {
    data: Prospect[];
    loading: boolean;
};

const Store = TinyStore.createStore<Modal>({
    nameSpace: 'klarity',
    key: 'patient-list-hook',
    crossBundle: false,
    runTime: true,
    initValue: { data: [], loading: false },
});

export default Store;
