// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__header--YmR5Y {
  box-shadow: 0px 2px 3px #999;
}
.s-module__header--YmR5Y .s-module__headerInner--TXMYp {
  width: 90%;
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 36px 0;
}
.s-module__header--YmR5Y .s-module__title--n8Wr5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.s-module__header--YmR5Y .s-module__title--n8Wr5 .s-module__left--I2peF {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}
.s-module__header--YmR5Y .s-module__title--n8Wr5 .s-module__left--I2peF img {
  margin-right: 8px;
}
.s-module__header--YmR5Y .s-module__title--n8Wr5 .s-module__left--I2peF .ant-btn {
  border-radius: 4px;
}
.s-module__header--YmR5Y .s-module__title--n8Wr5 .s-module__right--QuNAm .s-module__btn--SdMka {
  min-width: 120px;
}
.s-module__header--YmR5Y .s-module__stepWrap--Z1uEH {
  margin-top: 36px;
}
.s-module__header--YmR5Y .s-module__stepWrap--Z1uEH .ant-steps-item-description {
  max-width: 80% !important;
}
.s-module__logo--CUiIU {
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProviderOnboardingPage/components/Header/s.module.less"],"names":[],"mappings":"AAMA;EACE,4BAAA;AALF;AAIA;EAGI,UAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;AAJJ;AAFA;EAYI,aAAA;EACA,mBAAA;EACA,8BAAA;AAPJ;AAPA;EAiBM,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAPN;AAfA;EAyBQ,iBAAA;AAPR;AAlBA;EA6BU,kBAAA;AARV;AArBA;EAoCQ,gBAAA;AAZR;AAxBA;EAyCI,gBAAA;AAdJ;AA3BA;EA4CQ,yBAAA;AAdR;AAoBA;EACE,YAAA;AAlBF","sourcesContent":[".wrap {}\n\n.wrapInner {\n  \n}\n\n.header {\n  box-shadow: 0px 2px 3px #999;\n  .headerInner{\n    width: 90%;\n    max-width: var(--content-width);\n    margin: 0 auto;\n    padding: 36px 0;\n  }\n \n  .title {\n    display: flex;\n    flex-direction: row;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    .left {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      font-size: 24px;\n      font-weight: bold;\n      line-height: 32px;\n\n      img {\n        margin-right: 8px;\n      }\n      :global {\n        .ant-btn {\n          border-radius: 4px;\n        }\n      }\n    }\n\n    .right {\n      .btn {\n        min-width: 120px;\n      }\n    }\n  }\n  .stepWrap{\n    margin-top: 36px;\n    :global {\n      .ant-steps-item-description{\n        max-width: 80% !important;\n      }\n    }\n  }\n}\n\n.logo{\n  height: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `s-module__header--YmR5Y`,
	"headerInner": `s-module__headerInner--TXMYp`,
	"title": `s-module__title--n8Wr5`,
	"left": `s-module__left--I2peF`,
	"right": `s-module__right--QuNAm`,
	"btn": `s-module__btn--SdMka`,
	"stepWrap": `s-module__stepWrap--Z1uEH`,
	"logo": `s-module__logo--CUiIU`
};
export default ___CSS_LOADER_EXPORT___;
